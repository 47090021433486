import { SpaceWeapons, UnitDataRow, WeaponStats } from "../data/unit";
import { ModData } from "./ModData";
import { SerializableUnitStatus, UnitStatus } from "./UnitStatus";
import { CombatStats } from "./CombatStats";
import { Bonus } from "./Bonus";
import { Sect } from "./Sect";
import { PlainValueProxy, RecordMultiValue, RecordValue, Value } from "./Value";
import { MaxUnitsInStack, StackId } from "./Stack";
import { CombatSim } from "./CombatSim";

export type SerializableUnit = {
	magic: string;
	type: string;
	sect: string;
	status: SerializableUnitStatus;
}

export const orbitalUnitTypes = ['space', 'mech', 'lander', 'jump', 'gate'];
export const surrenderOnLandUnitTypes = ['jump', 'space'];

export class Unit
{
	readonly version = 1;
	readonly magic: string = `unit-${this.context.modId}-${this.version}`;

	public sect: Sect = new Sect(this.context);
	public combatStats: CombatStats = new CombatStats();
	readonly status: UnitStatus = new UnitStatus(this.context);

	private _data: UnitDataRow | undefined;
	public get data(): UnitDataRow | undefined
	{
		return this._data;
	}
	readonly type: RecordValue<Record<string, string>> = new RecordValue(this.unitTypes, "-1", 'Type');
	readonly id = `${this.stack}${this.stackPos}`;

	public get uiId(): string
	{
		return `${this.type.uiStringForValue} (${this.id})`
	}

	constructor(readonly combatSim: CombatSim, readonly stack: StackId, readonly stackPos: number, readonly context: ModData, readonly unitTypes: Record<string, string>)
	{
		this.cargo = new RecordMultiValue({}, [], 'Cargo');

		const updateCargo = () =>
		{
			if (this.isEmpty())
			{
				this.cargo.setAllValues({}, [], 'type.onChanged');
				return;
			}

			const stack = this.combatSim.getStack(this.stack);

			const startingPos = Math.min(this.stackPos + 1, MaxUnitsInStack - 1);
			const endingPos = Math.min(startingPos + this.data?.cargo! - 1, MaxUnitsInStack - 1);

			const cargoValues: Record<string, string> = {};
			const disabled: string[] = [];
			for (let i = startingPos; i <= endingPos; ++i)
			{
				const u = stack.getUnit(i);
				if (u)
				{
					u.type.onChanged[this.id] = updateCargo;
					if (u.data?.canBCargo !== 1)
					{
						disabled.push(i.toString());
					}
				}

				cargoValues[i] = `${this.stack}${i}`;
			}

			this.cargo.setAllValues(cargoValues, disabled, 'type.onChanged');
		};

		this.type.onChanged['parent'] = (value) =>
		{
			this._data = context.unit.getUnitById(parseInt(value));
			this.combatStats.data = this.data;

			updateCargo();
		}

		this.cargo.onChanged['type.onChanged'] = () =>
		{
			this.clearCargo();
			const stack = this.combatSim.getStack(this.stack);
			this.cargo.value.forEach((cargo) =>
			{
				const unit = stack.getUnit(parseInt(cargo));
				if (unit)
					this.addToCargo(unit);
			});
		};
	}

	public initialize()
	{
	}

	private _cargo: Unit[] = [];
	private _freighter: Unit | undefined = undefined;

	public get freighter(): Unit | undefined
	{
		return this._freighter;
	}

	public get unitsInCargo(): Unit[]
	{
		return this._cargo;
	}

	public set freighter(value: Unit | undefined)
	{
		if (this._freighter)
			this._freighter.removeFromCargo(this);

		this._freighter = value;

		const inCargo = this._freighter !== undefined && !this._freighter.isEmpty();
		const inCarrier = this._freighter !== undefined && !this._freighter.isEmpty() && this._freighter.data?.isSpaceCarrier()!;

		this.status.inCargo.initialize(inCargo, 'freighter', 'freighter');
		this.status.inCarrier.initialize(inCarrier, 'freighter', 'freighter');
	}

	readonly cargo: RecordMultiValue<Record<string, string>>;

	public clearCargo(): void
	{
		this._cargo.forEach(c =>
		{
			delete c.type.onChanged[this.id];
			c.freighter = undefined;
		});
		this._cargo = [];
	}

	public addToCargo(cargo: Unit | undefined): boolean
	{
		if (!cargo)
			return false;

		if (this.isEmpty())
			return false;

		if (this._cargo.includes(cargo))
			return false;

		if (this._cargo.length >= this.data!.cargo)
			return false;

		this._cargo.push(cargo);
		cargo.freighter = this;

		return true;
	}

	public removeFromCargo(cargo: Unit): void
	{
		if (this.isEmpty())
			return;

		if (!this._cargo.includes(cargo))
			return;

		this._cargo = this._cargo.filter(c => c !== cargo);
		cargo.freighter = undefined;
	}

	public toSerializableObject(): SerializableUnit
	{
		const out: SerializableUnit = {
			magic: this.magic,
			type: this.type.value,
			sect: this.sect.type.value,
			status: this.status.toSerializableObject(),
		};
		return out;
	}

	public fromSerializableObject(obj: SerializableUnit): void
	{
		if (obj.magic !== this.magic)
			throw new Error(`Invalid object type: ${obj.magic}`);

		this.type.set(obj.type.toString(), 'UI');
		if (obj.sect !== undefined)
			this.sect.type.set(obj.sect as any, 'UI');
		if (obj.status !== undefined)
			this.status.fromSerializableObject(obj.status);
	}

	public isEmpty(): boolean
	{
		return this.type.value === "-1";
	}

	public isRelic(): boolean
	{
		return this.type.value.startsWith('r_');
	}

	public getStackBonuses(): Bonus[]
	{
		const out: Bonus[] = [];

		if (this.isEmpty())
			return out;

		if (this.isRelic())
		{
			const relicId = parseInt(this.type.value.substring(2));
			const relic = this.context.relics.rows.find(r => r.id === relicId);

			if (!relic)
				return out;

			const stack = this.combatSim.getStack(this.stack);

			out.push(...relic.getBonuses(stack.player.knownTech));
		}

		return out;
	}

	private getOwnBonuses(): Bonus[]
	{
		const out: Bonus[] = [];

		if (this.isRelic())
			return out;

		out.push(...this.sect.getUnitBonuses());

		out.push(new Bonus(
			`Experience`,
			(status) => status,
			(stats, source) =>
			{
				if (this.status.experience.value === 0)
				{
					stats.armor.applyBonusPercent(source, -20, 'getOwnBonuses');
					Object.values(stats.attacks).forEach((attack) => attack.strength.applyBonusPercent(source, -20, 'getOwnBonuses'));
				}
				else if (this.status.experience.value === 2)
				{
					stats.armor.applyBonusPercent(source, 20, 'getOwnBonuses');
					Object.values(stats.attacks).forEach((attack) => attack.strength.applyBonusPercent(source, 20, 'getOwnBonuses'));
				}
				return stats;
			}
		));

		out.push(new Bonus(
			`Unspotted`,
			(status) => status,
			(stats, source) =>
			{
				if (!this.status.spotted.value)
					stats.agility.applyBonus(source, this.context.ini.data.Bonuses.agility_hidden_bonus, 'getOwnBonuses');
				return stats;
			},
		));

		return out;
	}

	public applyBonuses(bonuses: Bonus[]): void
	{
		if (this.isEmpty() || this.isRelic())
			return;

		bonuses.forEach(b =>
		{
			b.applyToStatus(this.status);
			b.applyToStats(this.combatStats!);
		});

		this.getOwnBonuses().forEach(b =>
		{
			b.applyToStatus(this.status);
			b.applyToStats(this.combatStats!);
		});
	}

	public reset(invokedBy: string, sourceFilter: (source: string) => boolean): void
	{
		if (this.isEmpty() || this.isRelic())
			return;

		this.status.reset(invokedBy, sourceFilter);
		this.combatStats!.reset(invokedBy, sourceFilter);
	}

	public isCombatUnit(): boolean
	{
		if (this.isEmpty() || this.isRelic())
			return false;
		return this.data!.participatesInCombat();
	}

	public isNoble(): boolean
	{
		if (this.isEmpty() || this.isRelic())
			return false;
		return this.data!.isNoble();
	}

	public isOfficer(): boolean
	{
		if (this.isEmpty() || this.isRelic())
			return false;
		return this.data!.isOfficer();
	}

	public isPTS(): boolean
	{
		if (this.isEmpty() || this.isRelic())
			return false;
		return this.combatStats.attacks.RangedSp.strength.value > 0;
	}

	public canBombard(): boolean
	{
		if (this.isEmpty() || this.isRelic())
			return false;
		return this.combatStats.attacks.RangedSp.strength.value > 0;
	}

	public canAttack(weapon: keyof WeaponStats): boolean
	{
		if (this.isEmpty() || this.isRelic())
			return false;
		return this.combatStats.attacks[weapon].strength.value > 0;
	}

	public participatesInSpaceCombat(): boolean
	{
		if (this.isEmpty() || this.isRelic())
			return false;
		return SpaceWeapons.some(weapon => this.combatStats.attacks[weapon].strength.value > 0);
	}

	public canExistInSpace(): boolean
	{
		if (this.isEmpty() || this.isRelic())
			return false;
		return orbitalUnitTypes.includes(this.data?.moveType!)
	}

	public willSurrenderInLandCombat(): boolean
	{
		if (this.isEmpty())
			return false;

		if (!this.isCombatUnit())
			return true;

		return surrenderOnLandUnitTypes.includes(this.data?.moveType!);
	}
}
