import { CombatSim } from "../sim/CombatSim";
import { Side, StanceType } from "../sim/Side";
import { createSelectpicker, createUIForRecordValue } from "./Value_ui";
import { createFormCard } from "../app/Utils";
import { generateUIForPlayer } from "./Player_ui";
import { generateUIForStack } from "./Stack_ui";

export function generateUIForSide(parentEl: HTMLElement, side: Side, combatSim: CombatSim, onChanged: (source: string)=>void)
{
	const { form } = createFormCard(parentEl, side.stance.value === 'Defending' ? 'Defender' : 'Attacker', false, null);

	const { select } = createUIForRecordValue(form, side.stance, () => onChanged('stance'));

	if (side.stance.value === 'Defending')
	{
		select.disabled = true;
		$(select).selectpicker('destroy');
		createSelectpicker(select);
	}
	else
	{
		const updateSelect = () =>
		{
			select.options.namedItem('Defending')!.disabled = true;
			select.options.namedItem('PTS')!.disabled = true;

			$(select).selectpicker('destroy');
			createSelectpicker(select);
		}

		updateSelect();
	}

	generateUIForPlayer(form, side.player, combatSim, onChanged);

	generateUIForStack(form, side.stack, combatSim, onChanged);
}
