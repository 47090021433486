import { TechTable } from "../data/tech";
import { Mod } from "./resourcemanager";
import { addCol, addLabel, addRow, createBasicCard, createFormCard, createInput, downloadJson, makeIconButton, makeId, makeModal, upload } from "./Utils";
import { adjectives, colors, uniqueNamesGenerator } from 'unique-names-generator';

export type TechState = {
	known: boolean;
	inProgress: boolean;
	proscribed: boolean;
	target: boolean;
}

type GameMetadata = {
	name: string;
	id: string;
	magic: string;
}

type Game = GameMetadata & { data: Record<string, TechState> }

type Games = {
	games: Record<string, Game>;
	currentGameId: string;
	version: number;
}

export type TableUpdate = Array<{ id: number, remainingCost?: number } & Partial<TechState>>

const gameMagic = `TechTracker`;

export class TechTracker
{
	private data: Games = {
		games: { default: { name: 'default', id: 'default', magic: gameMagic, data: {} }},
		currentGameId: 'default',
		version: 3,
	};

	private select: HTMLSelectElement;

	readonly onChange: Map<string, (update: TableUpdate) => void> = new Map();

	constructor(private gameSelectorId: string, private mod: Mod, private techTable: TechTable)
	{
		this.select = document.getElementById(this.gameSelectorId) as HTMLSelectElement;

		this.loadData();

		this.select.onchange = () =>
		{
			this.data.currentGameId = this.select.value;
			this.saveData();

			this.refreshCurrentGameState();
		};

		this.rebuildSelector();
	}

	private refreshCurrentGameState()
	{
		const update: TableUpdate = [];

		this.techTable.rows.forEach(row =>
		{
			update.push({ id: row.id, known: false, inProgress: false, target: false, proscribed: false, remainingCost: this.getRemainingCost(row.id) });
		});
		const data = this.data.games[this.data.currentGameId];
		Object.keys(data.data).forEach(key =>
		{
			const state = data.data[key];
			const id = parseInt(key);
			update.push({ id, known: state.known, inProgress: state.inProgress, target: state.target, proscribed: state.proscribed, remainingCost: this.getRemainingCost(id) });
		});
		this.onChange.forEach((callback) => callback(update));
	}

	private makeKey(): string
	{
		return `${this.mod.name}-techstate`;
	}

	private loadData()
	{
		const data = localStorage.getItem(this.makeKey());
		if (data)
		{
			const parsed = JSON.parse(data);

			if (parsed.version !== this.data.version)
			{
				console.log(`TechTracker: version mismatch`);
				return;
			}

			this.data = parsed;
		}

		if (!this.data.games[this.data.currentGameId])
			this.data.currentGameId = 'default';

		const values: Record<string, string> = {};
		Object.keys(this.data.games).forEach(key =>
		{
			values[key] = key;
		});

		this.rebuildSelector();
	}

	private saveData()
	{
		localStorage.setItem(this.makeKey(), JSON.stringify(this.data));
	}

	public getState(techId: number): TechState
	{
		// if (!this.data.games[this.data.currentGameId])
		// {
		// 	this.data.games[this.data.currentGameId] = { name: this.data.currentGameId, id: makeId(), magic: gameMagic, data: {} };
		// }
		if (!this.data.games[this.data.currentGameId].data[techId])
		{
			this.data.games[this.data.currentGameId].data[techId] = {
				known: false,
				inProgress: false,
				proscribed: false,
				target: false,
			};
		}
		return this.data.games[this.data.currentGameId].data[techId];
	}

	private getRemainingCostForTech(id: number): number
	{
		const tech = this.techTable.get(id);
		if (!tech)
			return 0;

		const state = this.getState(id);
		if (state.known)
			return 0;

		return tech.cost;
	};

	public getRemainingCost(techId: number): number
	{
		const tech = this.techTable.get(techId);
		if (!tech)
			return 0;

		return tech.allDependencyIds.map(id => this.getRemainingCostForTech(id)).reduce((a, b) => a + b, 0);
	}

	public getRemainingCostForList(techIds: number[]): number
	{
		const allIds = techIds.map((id) => this.techTable.get(id)).filter((tech) => tech).map((tech) => tech!.allDependencyIds).flat();
		const uniqueIds = [...new Set(allIds).values()];
		const cost = uniqueIds.map(id => this.getRemainingCostForTech(id)).reduce((a, b) => a + b, 0);
		return cost;
	}

	public stats(gameId: string)
	{
		const game = this.data.games[gameId];
		if (!game)
			return { known: 0, inProgress: 0, proscribed: 0, target: 0 };

		const data = this.data.games[gameId].data;
		const known = Object.keys(data).filter(key => data[key].known).length;
		const inProgress = Object.keys(data).filter(key => data[key].inProgress).length;
		const proscribed = Object.keys(data).filter(key => data[key].proscribed).length;
		const target = Object.keys(data).filter(key => data[key].target).length;

		return { known, inProgress, proscribed, target };
	}

	public toggleInProgress(techId: number): TableUpdate
	{
		const state = this.getState(techId);
		state.inProgress = !state.inProgress;
		state.known = false;

		const update: TableUpdate = [{ id: techId, remainingCost: this.getRemainingCost(techId), inProgress: state.inProgress, known: false }];

		const tech = this.techTable.get(techId);

		if (state.inProgress)
		{
		/*	tech?.allDependencyIds.forEach(id =>
			{
				this.getState(id).known = true;
				output.push({ id, inProgress: false, known: true });
			});*/
		}

		this.saveData();

		this.onChange.forEach((callback) => callback(update));

		return update;
	}

	public toggleKnown(techId: number): void
	{
		const state = this.getState(techId);
		state.known = !state.known;
		state.inProgress = false;

		const tech = this.techTable.get(techId);

		const update: TableUpdate = [{ id: techId, remainingCost: this.getRemainingCost(techId), known: state.known, inProgress: false, target: false }];

		if (state.known)
		{
			tech?.allDependencyIds.forEach(id =>
			{
				this.getState(id).known = true;
			});
			tech?.allDependencyIds.forEach(id =>
			{
				update.push({
					id,
					known: true,
					inProgress: false,
					target: false,
					remainingCost: this.getRemainingCost(id)
				});
			});
			tech?.allDependentIds.forEach(id =>
			{
				update.push({
					id,
					remainingCost: this.getRemainingCost(id)
				});
			});
		}
		else
		{
			tech?.allDependentIds.forEach(id =>
			{
				this.getState(id).known = false;
			});
			tech?.allDependentIds.forEach(id =>
			{
				update.push({ id, known: false, remainingCost: this.getRemainingCost(id) });
			});
		}

		this.saveData();

		this.onChange.forEach((callback) => callback(update));
	}

	public toggleProscribed(techId: number): void
	{
		const state = this.getState(techId);
		state.proscribed = !state.proscribed;
		this.saveData();

		const update: TableUpdate = [{ id: techId, proscribed: state.proscribed }];

		this.onChange.forEach((callback) => callback(update));
	}

	public toggleTarget(techId: number): void
	{
		const state = this.getState(techId);
		state.target = !state.target;

		const tech = this.techTable.get(techId);

		const update: TableUpdate = [{ id: techId, target: state.target }];

		if (state.target)
		{
			tech?.allDependencyIds.forEach(id =>
			{
				if (!this.getState(id).known)
				{
					this.getState(id).target = true;
					update.push({id, target: true });
				}
			});
		}
		else
		{
			tech?.allDependentIds.forEach(id =>
			{
				this.getState(id).target = false;
				update.push({ id, target: false });
			});
		}

		this.saveData();

		this.onChange.forEach((callback) => callback(update));
	}

	public onManageGames(): void
	{
		const makeName = () => uniqueNamesGenerator({ dictionaries: [adjectives, colors], length: 2, separator: ' ', style: 'capital' });

		const addGameCard = (parent: HTMLElement, gameId: string) =>
		{
			const data = this.data.games[gameId];

			const { form, card, header } = createFormCard(parent, data.name, false, null);
			card.classList.add('h-100');
			form.classList.add('container');

			const row = addRow(form);
			row.classList.add('row-cols-1');

			addLabel(addCol(row), `Id: ${data.id}`);

			const { form: statsForm } = createBasicCard(addCol(row), null);
			statsForm.classList.add('container');
			const statsRow = addRow(statsForm);
			statsRow.classList.add('row-cols-2');

			const refreshStats = () =>
			{
				const stats = this.stats(gameId);
				statsRow.innerHTML = '';
				addLabel(addCol(statsRow), `Known`);
				addLabel(addCol(statsRow), `${stats.known}`);
				addLabel(addCol(statsRow), `In progress`);
				addLabel(addCol(statsRow), `${stats.inProgress}`);
				addLabel(addCol(statsRow), `Proscribed`);
				addLabel(addCol(statsRow), `${stats.proscribed}`);
				addLabel(addCol(statsRow), `Target`);
				addLabel(addCol(statsRow), `${stats.target}`);
			};

			refreshStats();

			if (gameId !== 'default')
			{
				const del = makeIconButton(header, 'Delete', 'bi-trash', 'danger', () =>
				{
					this.deleteGame(gameId);
					card.remove();
				});
				del.classList.add('ms-2');
				del.classList.add('float-end');
			}

			const clear = makeIconButton(header, 'Clear', 'bi-x', 'danger', () =>
			{
				this.clearGame(gameId);
				this.saveData();

				refreshStats();

				if (gameId === this.data.currentGameId)
					this.refreshCurrentGameState();
			});
			clear.classList.add('float-end');

			const toJson = makeIconButton(header, 'To JSON', 'bi-file-earmark-arrow-down', 'primary', () =>
			{
				this.downloadGameAsJson(gameId);
			});
			toJson.classList.add('float-end');

			const fromJson = makeIconButton(header, 'From JSON', 'bi-file-earmark-arrow-up', 'primary', () =>
			{
				const meta = this.data.games[gameId];
				this.uploadGameFromJson(meta, (game) =>
				{
					refreshStats();

					if (gameId === this.data.currentGameId)
						this.refreshCurrentGameState();
				});
			});
			fromJson.classList.add('float-end');
		}

		const addNewGameCard = (parent: HTMLElement, cardParent: HTMLElement) =>
		{
			const { form, card, header } = createFormCard(parent, "New game", false, null);
			card.classList.add('h-100');
			form.classList.add('container');

			const row = addRow(form);
			row.classList.add('row-cols-1');

			const { input: nameInput } = createInput(addCol(row), 'Name', 'text', '', () => {});
			const { input: idInput } = createInput(addCol(row), 'Id', 'text', '', () => {});

			nameInput.value = makeName();
			idInput.value = makeId();
			idInput.disabled = true;

			const add = makeIconButton(header, 'Add', 'bi-plus', 'primary', () =>
			{
				const name = nameInput.value;
				if (!name)
					return;

				const meta = { name, id: idInput.value, magic: gameMagic };

				this.addNewGame(meta);

				addGameCard(addCol(cardParent), meta.id);

				nameInput.value = makeName();
				idInput.value = makeId();
			});
			add.classList.add('float-end');

			const loadJson = makeIconButton(header, 'From JSON', 'bi-file-earmark-arrow-up', 'primary', () =>
			{
				const meta = { name: nameInput.value, id: idInput.value, magic: gameMagic };

				this.uploadGameFromJson(meta, (game) =>
				{
					addGameCard(addCol(cardParent), game.id);
					nameInput.value = makeName();
					idInput.value = makeId();
				});
			});
			loadJson.classList.add('ms-2');
			loadJson.classList.add('float-end');
		};

		const { div: modalDiv, title, body: modalBody, footer, header, modal } = makeModal("Manage Games");


		const warningLabel = addLabel(footer, 'Warning: the data in tech tracker is stored in your browser\'s local cache. If you clear your browser\'s cache or if it overflows, you will lose all your data. Save often.');
		warningLabel.classList.add('text-danger');

		modalDiv.addEventListener('hidden.bs.modal', () =>
		{
			this.rebuildSelector();
		});

		modalDiv.addEventListener('shown.bs.modal', () =>
		{
			const { form: existingModsCard } = createBasicCard(modalBody, null);
			existingModsCard.classList.add('container');
			const row = addRow(existingModsCard);
			row.classList.add('row-cols-3');

			addNewGameCard(addCol(row), row);

			Object.keys(this.data.games).forEach(game =>
			{
				addGameCard(addCol(row), game);
			});
		});

		modal.show();
	}

	public deleteGame(gameId: string): void
	{
		delete this.data.games[gameId];
		this.saveData();

		this.rebuildSelector();
	}

	public clearGame(gameId: string): void
	{
		this.data.games[gameId].data = {};
		this.saveData();
	}

	public downloadGameAsJson(gameId: string): void
	{
		const data = this.data.games[gameId];
		downloadJson(data, `${data.name}(${data.id}).json`);
	}

	public addNewGame(meta: GameMetadata): void
	{
		const newGame: Game = {
			id: meta.id,
			name: meta.name,
			magic: gameMagic,
			data: {},
		};

		this.data.games[newGame.id] = newGame;
		this.saveData();
		this.rebuildSelector();
	}

	public uploadGameFromJson(meta: GameMetadata, onUploaded?: (game: Game) => void): void
	{
		upload('application/json', (reader: FileReader) =>
		{
			const data: Game = JSON.parse(reader.result as string);

			if (data.magic !== gameMagic)
			{
				alert('Invalid file');
				return;
			}

			let uploadedGame = data;

			uploadedGame.id = meta.id;
			uploadedGame.name = meta.name;

			this.data.games[uploadedGame.id] = uploadedGame;
			this.saveData();
			this.rebuildSelector();

			if (onUploaded)
				onUploaded(uploadedGame);
		});
	}

	private rebuildSelector()
	{
		Array.from(this.select.options).forEach((option) =>
		{
			this.select.remove(option.index);
		});

		Object.keys(this.data.games).forEach((gameId) =>
		{
			const meta: GameMetadata = this.data.games[gameId];
			const option = document.createElement('option');
			option.text = meta.name;
			option.value = meta.id;

			if (this.data.currentGameId === meta.id)
				option.selected = true;

			this.select.add(option);
		});
	}

	private updateSelector()
	{
		Array.from(this.select.options).forEach((option) =>
		{
			if (option.value === this.data.currentGameId)
				option.selected = true;
			else
				option.selected = false;
		});
	}
}