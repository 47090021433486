enum Ratio
{
    RATIO_1_5 = '1-5',
    RATIO_1_4 = '1-4',
    RATIO_1_3 = '1-3',
    RATIO_1_2 = '1-2',
    RATIO_1_1 = '1-1',
    RATIO_2_1 = '2-1',
    RATIO_3_1 = '3-1',
    RATIO_4_1 = '4-1',
    RATIO_5_1 = '5-1',
    RATIO_6_1 = '6-1',
    RATIO_7_1 = '7-1',
    RATIO_8_1 = '8-1'
}

function targetRatio(str: number, def: number): Ratio
{
    let ratio = 0;

    if (str < def)
	{
        if (str === 0) return Ratio.RATIO_1_5;

        ratio = (def + (str - 1)) / str;

        switch (Math.floor(ratio))
		{
            case 4:
                return Ratio.RATIO_1_4;
            case 3:
                return Ratio.RATIO_1_3;
            case 2:
                return Ratio.RATIO_1_2;
            case 5:
            default:
                return Ratio.RATIO_1_5;
        }
    }
	else
	{
        if (def === 0) return Ratio.RATIO_8_1;

        ratio = str / def;

        switch (Math.floor(ratio))
		{
            case 1:
                return Ratio.RATIO_1_1;
            case 2:
                return Ratio.RATIO_2_1;
            case 3:
                return Ratio.RATIO_3_1;
            case 4:
                return Ratio.RATIO_4_1;
            case 5:
                return Ratio.RATIO_5_1;
            case 6:
                return Ratio.RATIO_6_1;
            case 7:
                return Ratio.RATIO_7_1;
            case 8:
            default:
                return Ratio.RATIO_8_1;
        }
    }
}

export { Ratio, targetRatio };
