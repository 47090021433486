import { Notifier } from "./Value";
import { Unit } from "./Unit";

export class UnitLocator extends Notifier<Unit>
{
	private _value: Unit;

	constructor(value: Unit)
	{
		super();

		this.set(value, 'UnitLocator');
		this._value = value;
	}

	public get value(): Unit
	{
		return this._value;
	}

	public set(value: Unit, invokedBy: string)
	{
		if (this._value === value)
			return;

		this._value = value;
		this.notify(invokedBy);

		this._value.type.onChanged['UnitLocator'] = (value) => this.notify('UnitLocator');
	}
}
