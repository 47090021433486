
import { GraphBuilder} from './buildgraph';
import { LocalStorageCache } from './cache';
import { Archives } from './archives';
import { Data } from './resourcemanager';
import { ModManager } from "./ModManager";
import { UnitTableUI } from '../tables/unittable_ui';
import { TechTableUI } from '../tables/techtable_ui';
import { NodeGraph } from './NodeGraph';
import { GraphRenderer } from './GraphRenderer';
import { SectTableUI } from '../tables/sectstable_ui';
import { CityTableUI } from '../tables/citytable_ui';
import { TargetTableUI } from '../tables/targettable_ui';
import { AgilityTableUI } from '../tables/agility_ui';
import { DamageTableUI } from '../tables/damage_ui';
import { PageState } from './PageState';
import { CombatSim } from '../sim/CombatSim';
import { ModData } from '../sim/ModData';
import { CombatSimUI } from '../sim_ui/CombatSim_ui';
import { EfsIni } from '../data/ini';
import { TechTracker } from './TechTracker';
import { ResourceProduction } from '../data/basicresourcecost';
import { AgilityModTable } from '../data/agility';
import { CityTable } from '../data/city';
import { AttackDamageTable } from '../data/damage';
import { ResourceTable } from '../data/res';
import { SectTable } from '../data/sect';
import { TargetTable } from '../data/target';
import { TechTable } from '../data/tech';
import { UnitTable } from '../data/unit';
import { RelicsTable } from '../data/relic';
import { RelicsTableUI } from '../tables/relicstable';

declare global
{
	const bootstrap: typeof import("bootstrap");
}

export type Tables = {
	ini: EfsIni;
	res: ResourceTable;
	prod: ResourceProduction;
	tech: TechTable;
	city: CityTable;
	sect: SectTable;
	unit: UnitTable;
	target: TargetTable;
	agility: AgilityModTable;
	damage: AttackDamageTable;
	relics: RelicsTable;
};

class App
{
	private modManager: ModManager;
	private cache?: LocalStorageCache;
	private graphBuilder?: GraphBuilder;
	private archives?: Archives;
	private unitTableUI?: UnitTableUI;
	private techTableUI?: TechTableUI;
	private sectTableUI?: SectTableUI;
	private cityTableUI?: CityTableUI;
	private targetTableUI?: TargetTableUI;
	private agilityTableUI?: AgilityTableUI;
	private damageTableUI?: DamageTableUI;
	private combatSimUI?: CombatSimUI;
	private relicsTableUI?: RelicsTableUI;
	private fullGraph?: NodeGraph;
	private renderer?: GraphRenderer;
	private pageState = new PageState();
	private techTracker?: TechTracker;

	private data: Data = {};

	private tables: Tables = {
		ini: undefined!,
		res: undefined!,
		prod: undefined!,
		tech: undefined!,
		city: undefined!,
		sect: undefined!,
		unit: undefined!,
		target: undefined!,
		agility: undefined!,
		damage: undefined!,
		relics: undefined!,
	};

	private combatSim?: CombatSim;

	constructor(
		private unitsTableElementId: string,
		private techTableElementId: string,
		private sectTableElementId: string,
		private cityTableElementId: string,
		private targetsElementId: string,
		private agilityElementId: string,
		private damageElementId: string,
		private modsElementId: string,
		private relicsTableElementId: string,
	)
	{
		this.modManager = new ModManager(this.modsElementId, () => this.onModChanged(), this.pageState);
	}

	public async onModChanged()
	{
		this.clear();
		this.init();
	}

	public async onManageMods()
	{
		this.modManager.onManageMods();
	}

	public async onManageGames()
	{
		this.techTracker!.onManageGames();
	}

	public async onShowModData()
	{
		this.modManager.onShowModData();
	}

	private async init()
	{
		this.cache = new LocalStorageCache(this.modManager.selected.mod.root);
		this.archives = new Archives(this.modManager.selected.mod.root, "archives-modal");

		this.loadData();

		this.tables.ini = new EfsIni(await this.data.ini!);
		this.tables.res = new ResourceTable(await this.data.res!);
		this.tables.prod = new ResourceProduction(await this.data.prod!, this.tables.res);
		this.tables.tech = new TechTable(await this.data.tech!);
		this.tables.city = new CityTable(await this.data.city!);
		this.tables.sect = new SectTable(await this.data.sect!);
		this.tables.unit = new UnitTable(await this.data.unit!, this.tables.prod, this.tables.tech, this.tables.city);
		this.tables.target = new TargetTable(await this.data.target!);
		this.tables.agility = new AgilityModTable(await this.data.agility!);
		this.tables.damage = new AttackDamageTable(await this.data.damage!);
		this.tables.relics = new RelicsTable(await this.data.relics!);

		this.techTracker = new TechTracker('games', this.tables.tech.mod, this.tables.tech);
		this.graphBuilder = new GraphBuilder(this.tables, this.techTracker);
		this.fullGraph = new NodeGraph();

		await this.graphBuilder.build();


		this.fullGraph.onGraphChanged(this.graphBuilder.graph);

		this.unitTableUI = new UnitTableUI(`#${this.unitsTableElementId}`, this.techTracker, this.tables.unit, this.tables.city, this.tables.tech, (id: string) => this.onShowSubgraph(id), this.pageState);
		this.techTableUI = new TechTableUI(`#${this.techTableElementId}`, this.techTracker, this.tables.tech, this.archives!, (id: string) => this.onShowSubgraph(id), this.pageState);
		this.sectTableUI = new SectTableUI(`#${this.sectTableElementId}`, this.tables.sect, this.pageState);
		this.cityTableUI = new CityTableUI(`#${this.cityTableElementId}`, this.tables.city, this.tables.tech, (id: string) => this.onShowSubgraph(id), this.pageState);
		this.targetTableUI = new TargetTableUI(`#${this.targetsElementId}`, this.tables.target, this.pageState);
		this.agilityTableUI = new AgilityTableUI(`#${this.agilityElementId}`, this.tables.agility, this.pageState);
		this.damageTableUI = new DamageTableUI(`#${this.damageElementId}`, this.tables.damage, this.pageState);
		this.relicsTableUI = new RelicsTableUI(`#${this.relicsTableElementId}`, this.tables.relics, this.pageState);


		this.renderer = new GraphRenderer('selected-modal', this.archives, this.modManager.selected.mod.name, this.cache);

		const simContext = new ModData(
			this.modManager.selected.mod.name,
			this.tables.ini,
			this.tables.unit,
			this.tables.tech,
			this.tables.sect,
			this.tables.city,
			this.tables.target,
			this.tables.agility,
			this.tables.damage,
			this.tables.relics,
		);
		this.combatSim = new CombatSim(simContext);
		this.combatSimUI = new CombatSimUI('combatsim', this.combatSim, () =>
		{
		});

		// add upload button
		const uploadButton = document.getElementById('debug');
		uploadButton?.addEventListener('click', async (e) => await this.uploadAndProcessImages());
	}

	private loadData()
	{
		this.data.ini = this.modManager.resources.load('ini');
		this.data.res = this.modManager.resources.load('res');
		this.data.tech = this.modManager.resources.load('tech');
		this.data.unit = this.modManager.resources.load('unit');
		this.data.city = this.modManager.resources.load('city');
		this.data.prod = this.modManager.resources.load('prod');
		this.data.sect = this.modManager.resources.load('sect');
		this.data.target = this.modManager.resources.load('target');
		this.data.agility = this.modManager.resources.load('agility');
		this.data.damage = this.modManager.resources.load('damage');
		this.data.relics = this.modManager.resources.load('relics');
	}

	public async onShowMainGraph()
	{
		this.fullGraph?.refreshNodes();
		this.renderer?.selectNode(undefined);
		await this.renderer?.render(this.fullGraph!, undefined);

		const svg = this.renderer!.svg;

		svg.selectAll('g.node')
			.on("click", (event) =>
			{
				let node = this.fullGraph!.get(event.currentTarget.id);
				if (!node)
					return;
				node.selected = true;

				this.renderer?.selectNode(node.id);
			});
		;
	}

	private async onShowSubgraph(id: string)
	{
		const graph = this.fullGraph?.getSubgraph(id);

		if (!graph)
			return;

		let node = graph.get(id);
		node!.selected = true;

		await this.renderer?.render(graph, id);

		const svg = this.renderer!.svg;

		svg
			.select(`g.node#${id}`)
			.classed("selected", true)
		;

		svg.selectAll('g.node')
			.on("click", (event) =>
			{
				let node = graph.get(event.currentTarget.id);
				if (!node)
					return;
				node.selected = true;

				this.onShowSubgraph(event.currentTarget.id);
			});
		;
	}

	private async uploadAndProcessImages()
	{
		// const uploadInput = document.createElement('input');
		// uploadInput.type = 'file';
		// uploadInput.accept = 'image/*';
		// uploadInput.multiple = true;
		// uploadInput.style.display = 'none';
		// uploadInput.click();

		// uploadInput.addEventListener('change', async (e) =>
		// {
		// 	const files = uploadInput.files;
		// 	if (!files)
		// 		return;

		// 	for (let i = 0; i < files.length; ++i)
		// 	{
		// 		const file = files[i];
		// 		const blob = this.combatSim!.ocr.imageBlobFromFile(file);
		// 		const { out: convertedBlob } = await this.combatSim!.ocr.convertForOCR(blob);

		// 		const a = document.createElement('a');
		// 		a.href = URL.createObjectURL(convertedBlob);
		// 		a.download = 'test.png';
		// 		document.body.appendChild(a);
		// 		a.click();
		// 		document.body.removeChild(a);
		// 	}
		// });
	}

	private clear()
	{
		this.graphBuilder = undefined;
		this.cache = undefined;
		this.archives = undefined;

		if (this.unitTableUI)
		{
			this.unitTableUI.destroy();
			this.unitTableUI = undefined;
		}

		if (this.techTableUI)
		{
			this.techTableUI.destroy();
			this.techTableUI = undefined;
		}

		if (this.sectTableUI)
		{
			this.sectTableUI.destroy();
			this.sectTableUI = undefined;
		}

		if (this.cityTableUI)
		{
			this.cityTableUI.destroy();
			this.cityTableUI = undefined;
		}

		if (this.targetTableUI)
		{
			this.targetTableUI.destroy();
			this.targetTableUI = undefined;
		}

		if (this.agilityTableUI)
		{
			this.agilityTableUI.destroy();
			this.agilityTableUI = undefined;
		}

		if (this.damageTableUI)
		{
			this.damageTableUI.destroy();
			this.damageTableUI = undefined;
		}

		if (this.combatSimUI)
		{
			this.combatSimUI.destroy();
			this.combatSimUI = undefined;
		}


	}

	public async onUnitTableShown()
	{
		if (!this.unitTableUI)
			return;

		this.unitTableUI.onShown();
	}

	public async onTechTableShown()
	{
		if (!this.techTableUI)
			return;

		this.techTableUI.onShown();
	}

	public async onSectTableShown()
	{
		if (!this.sectTableUI)
			return;

		this.sectTableUI.onShown();
	}

	public async onCityTableShown()
	{
		if (!this.cityTableUI)
			return;

		this.cityTableUI.onShown();
	}

	public async onTargetTableShown()
	{
		if (!this.targetTableUI)
			return;

		this.targetTableUI.onShown();
	}

	public async onAgilityTableShown()
	{
		if (!this.agilityTableUI)
			return;

		this.agilityTableUI.onShown();
	}

	public async onDamageTableShown()
	{
		if (!this.damageTableUI)
			return;

		this.damageTableUI.onShown();
	}

	public async onCombatSimShown()
	{
		if (!this.combatSimUI)
			return;

		this.combatSimUI.onShown();
	}
}

function selectTheme()
{
	const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
	if (darkMode)
	{
		// set data-bs-theme="dark" on the html tag
		document.documentElement.setAttribute('data-bs-theme', 'dark');

		const link = document.createElement('link');
		link.rel = 'stylesheet';
		link.href = 'https://unpkg.com/tabulator-tables@5.5.0/dist/css/tabulator_midnight.min.css';
		document.head.appendChild(link);
	}
	else
	{
		const link = document.createElement('link');
		link.rel = 'stylesheet';
		link.href = 'https://unpkg.com/tabulator-tables@5.5.0/dist/css/tabulator.min.css';
		document.head.appendChild(link);
	}
}

export async function onPageLoad()
{
	selectTheme();

	const app = new App("unittable", "techtable", "secttable", "citytable", "targettable", "agilitytable", "damagetable", "mods", "relictable");

	document.querySelector('button[data-bs-target="#pills-unittable"]')!.addEventListener('shown.bs.tab', (e) => app.onUnitTableShown());
	document.querySelector('button[data-bs-target="#pills-techtable"]')!.addEventListener('shown.bs.tab', (e) => app.onTechTableShown());
	document.querySelector('button[data-bs-target="#pills-secttable"]')!.addEventListener('shown.bs.tab', (e) => app.onSectTableShown());
	document.querySelector('button[data-bs-target="#pills-citytable"]')!.addEventListener('shown.bs.tab', (e) => app.onCityTableShown());
	document.querySelector('button[data-bs-target="#pills-targettable"]')!.addEventListener('shown.bs.tab', (e) => app.onTargetTableShown());
	document.querySelector('button[data-bs-target="#pills-agilitytable"]')!.addEventListener('shown.bs.tab', (e) => app.onAgilityTableShown());
	document.querySelector('button[data-bs-target="#pills-damagetable"]')!.addEventListener('shown.bs.tab', (e) => app.onDamageTableShown());
	document.querySelector('button[data-bs-target="#pills-combatsim"]')!.addEventListener('shown.bs.tab', (e) => app.onCombatSimShown());

	const manageMods = document.getElementById('manage-mods') as HTMLButtonElement;
	manageMods.addEventListener('click', (e) => app.onManageMods());

	const modData = document.getElementById('mod-data') as HTMLButtonElement;
	modData.addEventListener('click', (e) => app.onShowModData());

	const magageGames = document.getElementById('manage-games') as HTMLButtonElement;
	magageGames.addEventListener('click', (e) => app.onManageGames());

	const mainGraph = document.getElementById("show-main-graph") as HTMLButtonElement;
	mainGraph.addEventListener('click', (e) => app.onShowMainGraph());

	app.onModChanged();
}