import { DataPoint } from "../app/resourcemanager";
import { ResourceNames } from "./basicresourcecost";

/*{
"name" "Food"           "sing" "Food"          "price" "10"   "restock" "500" "desc" "Most units consume 1 and cities 10 per turn."
"name" "Energy"         "sing" "Energy"        "price" "10"   "restock" "200" "desc" "Used to produce other resources."
"name" "Metal"          "sing" "Metal"         "price" "10"   "restock" "200" "desc" "Construction material for building Chemicals and Electronics."
"name" "Rare Earths"    "sing" "Rare Earth"    "price" "200"  "restock" "25"  "desc" "Used in production of Hi-Techs."
"name" "Exotica"        "sing" "Exotica"       "price" "20"   "restock" "100" "desc" "Used in production of Biochems."
"name" "Chemicals"      "sing" "Chemical"      "price" "35"   "restock" "50"  "desc" "Used to create other resources."
"name" "Biochems"       "sing" "Biochem"       "price" "60"   "restock" "50"  "desc" "Used to genetically alter and enhance units."
"name" "Electronics"    "sing" "Electronic"    "price" "35"   "restock" "50"  "desc" "Used in servos, silicon wafers, think machines, and Fusoriums."
"name" "Ceramsteel"     "sing" "Ceramsteel"    "price" "60"   "restock" "50"  "desc" "Required for large space craft and powered body armor."
"name" "Wetware"        "sing" "Wetware"       "price" "130"  "restock" "25"  "desc" "Meshes man and machine."
"name" "Fusion Cells"   "sing" "Fusion Cell"   "price" "480"  "restock" "25"  "desc" "Needed for energy weapons and systems."
"name" "Gems"           "sing" "Gem"           "price" "300"  "restock" "10"  "desc" "A cash commodity and needed for singularity production."
"name" "Jump Drives"    "sing" "Jump Drive"    "price" "6000" "restock" "2"   "desc" "Spaceship engines needed for travel through jump gates."
}*/

type ResourceDataRow =
{
	name: string;
	key: string;
	sing: string;
	price: number;
	restock: number;
	desc: string;
	oldKey: keyof ResourceNames
};

const resourceKeysInOrder: (keyof ResourceNames)[] = [
	'food',
	'energy',
	'metal',
	'trace',
	'exotica',
	'chemicals',
	'biochems',
	'electronics',
	'ceramsteel',
	'wetware',
	'monopols',
	'gems',
	'singularities'
];

export class ResourceTable
{
	readonly rows: ResourceDataRow[] = [];

	constructor(data: DataPoint)
	{
		const lines = data.data
			.trim()
			.split('\n')
			.map(line => line.trim())
			.filter(line => !line.startsWith('//'))
			.filter(line => !line.startsWith('{'))
			.filter(line => !line.startsWith('}'))
			.filter(line => line.length > 0);
		;

		for (let i = 0; i < lines.length; i++)
		{
			const rowMatches = lines[i].match(/"name"\s*"([^"]+)"\s*"sing"\s*"([^"]+)"\s*"price"\s*"([^"]+)"\s*"restock"\s*"([^"]+)"\s*"desc"\s*"([^"]+)"/);

			if (!rowMatches)
				continue;

			const values = rowMatches.map(value => value.trim());	;

			const row: ResourceDataRow = {
				name: values[1],
				key: values[1].toLowerCase(),
				sing: values[2],
				price: parseInt(values[3]),
				restock: parseInt(values[4]),
				desc: values[5],
				oldKey: resourceKeysInOrder[i]
			};

			this.rows.push(row);
		}
	}

	public get(key: string)
	{
		return this.rows.find(row => row.key === key);
	}
}