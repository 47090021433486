import { makeId } from "./Utils";

export interface DataFiles {
	ini: string;
	tech: string;
	unit: string;
	res: string;
	city: string;
	prod: string;
	agility: string;
	damage: string;
	target: string;
	sect: string;
	relics: string;
}

export type DataPoint = { mod: Mod, data: string, file: keyof DataFiles };

export type Data = {
	[key in keyof DataFiles]?: Promise<DataPoint>;
};

export type Mod = { name: string; root: string };

export type ModChain = { mod: Mod; fallback: ModChain | undefined };

export const lsPrefix = 'localstorage:';

export function makeLSKey(entry: keyof DataFiles, mod: Mod)
{
	return `${mod.name}-dat-${entry}`;
}

type ModData = { [key: string]: DataPoint };

export class ResourceManager
{
	readonly datFiles: DataFiles = {
		ini: "EFS.INI",
		tech: "TECH.DAT",
		unit: "UNIT.DAT",
		res: "RES.DAT",
		city: "STRBUILD.DAT",
		prod: "PROD.DAT",
		agility: "AGILITY.DAT",
		damage: "DAMAGE.DAT",
		target: "TARGET.DAT",
		sect: "SECT.DAT",
		relics: "RELICS.DAT",
	};

	private cache: ModData = {};

	constructor(private modChain: ModChain)
	{
	}

	public entryForName(name: string): keyof DataFiles | undefined
	{
		for (const [key, value] of Object.entries(this.datFiles))
		{
			if (value.toLowerCase() === name.toLowerCase())
				return key as keyof DataFiles;
		}

		return undefined;
	}

	public async load(entry: keyof DataFiles)
	{
		if (this.cache[entry])
			return this.cache[entry];

		let modChain: ModChain | undefined = this.modChain;

		while (modChain)
		{
			const mod = modChain.mod;
			const data = await this.loadMod(entry, mod);

			if (data !== undefined)
			{
				this.cache[entry] = data;
				return data;
			}

			modChain = modChain.fallback;
		}
		throw new Error("No data found");
	}

	public async loadMod(entry: keyof DataFiles, mod: Mod)
	{
		if (mod.root.startsWith(lsPrefix))
		{
			const key = makeLSKey(entry, mod);
			const stored = localStorage.getItem(key);

			if (stored)
				return {mod: mod, data: stored, file: entry};
			else
				return undefined;
		}

		const response = await fetch(this.makeDatPath(this.datFiles[entry], mod));
		if (response.status === 404)
			return undefined;

		const data = await response.text();

		return {mod: mod, data, file: entry};
	}

	protected makeDatPath(file: string, mod: Mod)
	{
		return `${mod.root}/DAT/${file}`.toLowerCase();
	}
}

