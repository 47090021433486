import { DataPoint, Mod } from "../app/resourcemanager";

export type TechDataRow =
{
	raw: string;
	id: number;
	graph_id: string;
	name: string;
	dependencies: number[];
	allDependencies: string[];
	allDependencyIds: number[];
	allDependentIds: number[];
	cost: number;
	totalCost: number;
	like: number;
	description: string;
	volume: number;
	chapter: number;
	owner: number;
	root: boolean;
	hidden: boolean;
	cluster?: string;
};

export class TechTable
{
	rows: TechDataRow[] = [];
	clusters: string[] = [];
	private currentCluster: string | undefined;
	readonly mod: Mod;

	constructor(tableString: DataPoint)
	{
		this.mod = tableString.mod;

		const lines = tableString.data.trim().split('\n').filter(line => !line.startsWith('//'));

		lines.forEach((line) =>
		{
			const row = this.parseRow(this.rows.length, line);
			if (row)
				this.rows.push(row);
		});

		this.rows.forEach((row) =>
		{
			row.totalCost = this.getTotalCost([row.id]);
			row.allDependencies = [...new Set(this.allDependencies(row.id)).values()];
			row.allDependencyIds = this.getAllTechDependencies([row.id]);

			row.allDependencyIds.forEach((id) =>
			{
				const dependencyRow = this.rows[id];
				if (!dependencyRow)
					return;
				dependencyRow.allDependentIds.push(row.id);
			});
		});
	}

	private parseRow(id: number, rowLine: string): TechDataRow | undefined
	{
		const rowMatches = rowLine.match(/"name"\s*"([^"]+)"\s*"stats"\s*"\s*([^"]+)\s*"\s*"extra"\s*"([^"]*)"/);
		if (!rowMatches)
			return undefined;

		const [, name, stats, description] = rowMatches;

		const allStats = stats.split(/\s/).filter((x) => x).map(Number);
		const dependencies = allStats.slice(0, 9);
		const cost = allStats[10];
		const like = allStats[11];
		const volume = allStats[12];
		const chapter = allStats[13];
		const owner = allStats[14];

		if (dependencies[0] >= 990)
		{
			this.clusters.push(name);
			this.currentCluster = name;
		}

		return { id, graph_id: `tech_${id}`, raw: rowLine, name, owner, dependencies, allDependencies: [], allDependencyIds: [], allDependentIds: [], cost, totalCost: 0, like, volume, chapter, description, root: dependencies[0] >= 800, hidden: cost === 0, cluster: this.currentCluster};
	}

	public get(id: number): TechDataRow | undefined
	{
		if (id === 0)
			return undefined;
		if (id === 800)
			return undefined;
		return this.rows[id];
	}

	public getAllTechDependencies(ids: number[]): number[]
	{
		const output: number[] = [];

		ids.forEach((id) =>
		{
			if (id === 0)
				return;
			const row = this.rows[id];
			if (!row)
				return;

			output.push(id);
			output.push(...this.getAllTechDependencies(row.dependencies));
		});

		return [...new Set(output).values()];
	}

	public getTotalCost(ids: number[]): number
	{
		const techDeps = this.getAllTechDependencies(ids);
		return techDeps.reduce((total, id) =>
		{
			return total + this.rows[id].cost;
		}, 0);
	}

	private allDependencies(id: number): string[]
	{
		if (id === 0)
			return [];

		const row = this.rows[id];
		if (!row)
			return [];

		return row.dependencies.reduce((total, id) =>
		{
			if (id === 0)
				return total;
			const row = this.rows[id];
			if (!row)
				return total;
			return total.concat(row.name).concat(this.allDependencies(id))
		}, [] as string[]);
	}
};