import { TableBase, TableStateStorage, bool, common, genericString, minMaxFilterEditor, minMaxFilterFunction, numerical } from "./tabulator";
import { Tabulator, ColumnDefinition } from "tabulator-tables";
import { TechTable } from "../data/tech";
import { Archives } from "../app/archives";
import { getIndividualOwners, getOwnerUIString } from "../data/unit";
import { TableUpdate, TechTracker } from "../app/TechTracker";
import { downloadJson } from "../app/Utils";

export class TechTableUI extends TableBase
{
	constructor(elementSelector: string, private techTracker: TechTracker, private tech: TechTable, private archives: Archives, private showSubGraph: (id: string) => void, stateStorage: TableStateStorage)
	{
		super(elementSelector, "Tech", stateStorage);

		this.init();

		this.techTracker.onChange.set("TechTableUI", (update: TableUpdate) =>
		{
			if (!this.table)
				return;
			this.table.updateData(update);
			this.table.refreshFilter();
		});
	}

	protected override async downloadForLLM()
	{
		const columns = this.table?.getColumnDefinitions();
		const rows = await Promise.all(this.table!.getData().map(async (row) =>
		{
			const newRow: any = {};

			const setField = (column: ColumnDefinition, parentPath: string) =>
			{
				column.columns?.forEach((subColumn) =>
				{
					setField(subColumn, parentPath + column.title + " ");
				});
				if (!column.field || column.download === false)
					return;
				newRow[column.field] = row[column.field];
			};

			columns?.forEach((column) =>
			{
				setField(column, "");
			});

			if (row.volume !== 0 && row.chapter !== 0)
			{
				try
				{
					newRow["short description"] = row["description"];
					newRow["description"] = await this.archives.getChapter(row.volume, row.chapter);
				} catch (error)
				{

				}
			}

			return newRow;
		}));

		const data =
		{
			rows: rows,
			mod: this.tech.mod.name,
			schema: this.schema,
		};

		downloadJson(data, `tech.json`);
	}

	buildColumns(): ColumnDefinition[]
	{
		return [
			{ title: 'Id', field: 'id', sorter: 'number', frozen: true, ...common },
			{ title: 'Name', field: 'name', sorter: 'string', frozen: true, ...common, ...genericString },
			{
				title: 'Graph',
				download: false,
				field: 'opengraph',
				formatter: "html",
				headerSort: true,
				headerFilter: false,
				frozen: true,
				cellClick: (e, cell) =>
				{
					this.showSubGraph(cell.getRow().getData().graph_id);
				}
			},
			{
				title: 'Cluster',
				field: 'cluster',
				sorter: 'string',
				headerFilter: "list",
				headerFilterParams: { valuesLookup:true, clearable: true },
				headerFilterPlaceholder: "All",
				...common
			},
			{
				title: 'Current game',
				download: false,
				columns: [
				{
					title: 'Known',
					field: 'known',
					cellClick: (e, cell) =>
					{
						this.techTracker.toggleKnown(cell.getRow().getData().id);
					},
					...common,
					...bool,
				},
				{
					title: 'Target',
					field: 'target',
					cellClick: (e, cell) =>
					{
						this.techTracker.toggleTarget(cell.getRow().getData().id);
					},
					...common,
					...bool,
				},
				{
					title: 'In Progress',
					field: 'inProgress',
					cellClick: (e, cell) =>
					{
						this.techTracker.toggleInProgress(cell.getRow().getData().id);
					},
					...common,
					...bool,
				},
				{
					title: 'Proscribed',
					field: 'proscribed',
					cellClick: (e, cell) =>
					{
						this.techTracker.toggleProscribed(cell.getRow().getData().id);
					},
					...common,
					...bool,
				},
				{
					title: 'Remaining Tech Cost',
					field: 'remainingCost',
					...common,
					...numerical,
				},
			]},
			{ title: 'Cost', field: 'cost', ...common, ...numerical },
			{ title: 'Total Cost', field: 'totalcost',  ...common, ...numerical },
			{ title: 'Like', field: 'like', ...common, ...numerical },
			{ title: 'Description', field: 'description', ...common, ...genericString },
			{ title: 'Volume', visible: false, field: 'volume', ...common, ...numerical },
			{ title: 'Chapter', visible: false, field: 'chapter', ...common, ...numerical },
			{
				title: 'Archive',
				download: false,
				field: 'openarchive',
				formatter: "html",
				headerSort: true,
				headerFilter: false,
				cellClick: (e, cell) =>
				{
					this.archives.displayChapter(cell.getRow().getData().volume, cell.getRow().getData().chapter);
				}
			},
			{
				title: 'Owner',
				columns: [
					{ title: 'Combined', field: 'owner', sorter: 'string', ...common },
					{
						title: 'All',
						field: 'owner_list',
						...common,
						...genericString,
					},
				],
			},
			{ title: 'Dependencies', field: 'dependencies', sorter: 'string', ...common, ...genericString },
			{ title: 'All Dependencies', field: 'allDependencies', sorter: 'string', ...common, ...genericString },
			{ title: 'Raw', download: true, field: 'raw', sorter: 'string', ...common, ...genericString },
		];
	}

	buildData()
	{
		return this.tech.rows.filter((row) => row.name !== 'unused').map((row) =>
		{
			return {
				id: row.id,
				name: row.name,
				graph_id: row.graph_id,
				known: this.techTracker.getState(row.id).known,
				target: this.techTracker.getState(row.id).target,
				inProgress: this.techTracker.getState(row.id).inProgress,
				proscribed: this.techTracker.getState(row.id).proscribed,
				remainingCost: this.techTracker.getRemainingCost(row.id),
				cost: row.cost,
				totalcost: row.totalCost,
				like: row.like,
				description: row.description,
				volume: row.volume,
				chapter: row.chapter,
				owner: getOwnerUIString(row.owner),
				owner_list: getIndividualOwners(row.owner).join(", "),
				dependencies: row.dependencies
					.filter((dep) => dep != 0)
					.map((dep) => this.tech.rows[dep] ? this.tech.rows[dep].name : dep)
					.join(', '),
				allDependencies: row.allDependencies.join(', '),
				raw: row.raw,
				cluster: row.cluster,
				openarchive: row.volume > 0 ? `<button type="button" class="btn btn-info btn-sm">Vol ${row.volume} Chapter ${row.chapter}</button>` : '',
				opengraph: `<button type="button" class="btn btn-info btn-sm">Graph</button>`,
			}
		});
	}
}