import { DataPoint } from "../app/resourcemanager";

export type SectNames = 'Ort' | 'SAe' | 'Esk' | 'Inc' | 'Ave' | 'Bro' | 'Men' | 'Sym' | 'Vau';

const SectNamesInOrder = [ 'Ort', 'SAe', 'Esk', 'Inc', 'Ave', 'Bro', 'Men', 'Sym', 'Vau' ];

type BonusSection = 'G1' | 'P1' | 'P2' | 'P3' | 'I1' | 'I2' | 'I3';
type BonusType = '+' | '*';

const sectionDescription = {
	G1: ['Galaxy (G1)'],
	P1: ['Planetary', 'Production (P1)'],
	P2: ['Planetary', 'Structure and Research (P2)'],
	P3: ['Planetary', 'Unit (P3)'],
	I1: ['Unit', 'Statistics (I1)'],
	I2: ['Unit', 'Combat Stats (I2)'],
	I3: ['Unit', 'Misc (I3)'],
}

export class SectBonus
{
	constructor(
		readonly id: string,
		readonly name: string,
		readonly group: string[],
		readonly currentSection: BonusSection,
		readonly operation: BonusType,
		readonly value: number
	)
	{}
}

export class SectRow
{
	readonly bonuses: { [index: string ]: SectBonus } = {};

	constructor(readonly id: SectNames)
	{
	}

	getId(): SectNames
	{
		return this.id;
	}

	public addBonus(bonus: SectBonus)
	{
		this.bonuses[bonus.id.toLowerCase()] = bonus;
	}

	public getBonus(bonusId: string): SectBonus | undefined
	{
		return this.bonuses[bonusId.toLowerCase()];
	}

	public applyBonus(bonusId: string, baseValue: number)
	{
		const bonus = this.bonuses[bonusId.toLowerCase()];
		if (bonus)
		{
			if (bonus.operation === '+')
			{
				return baseValue + bonus.value;
			}
			else if (bonus.operation === '*')
			{
				return baseValue * (1 + bonus.value / 100);
			}
		}
		return baseValue;
	}
}

export class SectTable
{
	readonly sects: Record<SectNames, SectRow> = {
		Ort: new SectRow('Ort'),
		SAe: new SectRow('SAe'),
		Esk: new SectRow('Esk'),
		Inc: new SectRow('Inc'),
		Ave: new SectRow('Ave'),
		Bro: new SectRow('Bro'),
		Men: new SectRow('Men'),
		Sym: new SectRow('Sym'),
		Vau: new SectRow('Vau'),
	};

	constructor(tableData: DataPoint)
	{
		this.parseTableData(tableData.data);
	}

	public getSect(sectName: SectNames): SectRow | undefined
	{
		return this.sects[sectName];
	}

	private parseTableData(input: string): void
	{
		const lines = input
			.trim()
			.split('\n')
			.map(line => line.trim())
			.filter(line => !line.startsWith('//'))
			.filter(line => !line.startsWith('}'))
		;

		let currentSection: BonusSection;
		lines.forEach(line =>
		{
			if (line.startsWith('{'))
			{
				const rowMatches = line.match(/{\s*\"(.*)\"/);
				if (rowMatches)
					currentSection = rowMatches[1] as BonusSection;
				return;
			}

			const rowMatches = line.match(/\"([^"]*)\"\s*\"([^"]*)\".*/);
			if (!rowMatches)
				return;

			const bonusOperation = rowMatches[1].trim()[0];
			const bonusValues = rowMatches[2].trim().split(/\s+/).map(Number);

			if (bonusValues.length === SectNamesInOrder.length)
			{
				const bonusId = rowMatches[1].trim().slice(1);
				bonusValues.forEach((bonusValue, index) =>
				{
					const sect = this.getOrCreateSect(index);
					sect.addBonus(new SectBonus(`${currentSection}_${bonusId.replace(/\s+/, '_')}`, `${bonusOperation} ${bonusId}`, [...sectionDescription[currentSection]], currentSection as BonusSection, bonusOperation as BonusType, bonusValue));
				});
			}
			else if (currentSection === "I2" && bonusValues.length === 2*SectNamesInOrder.length) // I2
			{
				const bonusId = rowMatches[1].trim();
				for (let i = 0; i < SectNamesInOrder.length; i++)
				{
					const sect = this.getOrCreateSect(i);

					sect.addBonus(new SectBonus(`${currentSection}_${bonusId.replace(/\s+/, '_')}_Acc`, `+ Acc`, [...sectionDescription[currentSection], bonusId], currentSection, "+", bonusValues[i * 2]));
					sect.addBonus(new SectBonus(`${currentSection}_${bonusId.replace(/\s+/, '_')}_AS`, `* Dmg`, [...sectionDescription[currentSection], bonusId], currentSection, "*", bonusValues[1 + i * 2]));
				}
			}
		});
	}

	private getOrCreateSect(index: number): SectRow
	{
		const sectId = SectNamesInOrder[index] as SectNames;

		if (!this.sects[sectId])
			this.sects[sectId] = new SectRow(sectId);

		return this.sects[sectId]!;
	}
}
