import { CombatSim } from "../sim/CombatSim";
import { generateUIForCombatHistory } from "./CombatHistory_ui";
import { makeAccordion, makeIconButton } from "../app/Utils";
import { createFlagButtonUI } from "./Value_ui";
import { createFormCard } from "../app/Utils";

export function generateUIForResults(parentEl: HTMLElement, combatSim: CombatSim, onChanged: (source: string)=>void)
{
	let combat = 0;

	const form = createFormCard(parentEl, 'Results', false, null);

	const run = makeIconButton(form.header, 'New combat', 'bi-play-fill', 'primary', () =>
	{
		combat++;
		combatSim.reset('UI', () => true);
		combatSim.run();
	});
	run.classList.add('ms-2');

	const runAgain = makeIconButton(form.header, 'Attack Again', 'bi-play', 'primary', () =>
	{
		combatSim.combatHistory.reset();
		combatSim.run();
	});

	const reset = makeIconButton(form.header, 'Reset', 'bi-arrow-counterclockwise', 'secondary', () =>
	{
		combat = 0;
		combatSim.reset('UI', () => true);
		accordion.innerHTML = '';
	});

	const save = makeIconButton(form.header, 'Save', 'bi-save', 'secondary', async () =>
	{
		const data = combatSim.toSerializableObject();
		// save to file
		const dataStr = JSON.stringify(data, undefined, 4);
		const hashString = await window.crypto.subtle.digest('SHA-1', new TextEncoder().encode(dataStr)).then(hashBuffer =>
		{
			const hashArray = Array.from(new Uint8Array(hashBuffer));
			const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
			return hashHex;
		});

		const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);
		const exportFileDefaultName = `combatSim_${combatSim.version}_${combatSim.context.modId}_${hashString}.json`;
		const linkElement = document.createElement('a');
		linkElement.setAttribute('href', dataUri);
		linkElement.setAttribute('download', exportFileDefaultName);
		linkElement.click();
	});

	const load = makeIconButton(form.header, 'Load', 'bi-folder2-open', 'secondary', () =>
	{
		// load from file
		const input = document.createElement('input');
		input.type = 'file';
		input.accept = '.json';
		input.onchange = () =>
		{
			const file = input.files?.[0];
			if (!file)
				return;
			const reader = new FileReader();
			reader.onload = () =>
			{
				const dataStr = reader.result as string;
				const data = JSON.parse(dataStr);

				try
				{
					combatSim.fromSerializableObject(data);
				}
				catch (e)
				{
					alert(e);
				}
			};
			reader.readAsText(file);
		};
		input.click();
	});

	const combatType = createFlagButtonUI(form.header, combatSim.useExpectedValues, "success", () => {});
	combatType.classList.add('ms-4');

	// results
	const results = document.createElement('div');
	results.classList.add('results');
	results.classList.add('card');
	results.classList.add('card-body');
	results.classList.add('overflow-auto');
	form.form.appendChild(results);

	const { addItem, accordion } = makeAccordion(results, true, false, undefined);

	combatSim.onCombatDone = () =>
	{
		addItem((itemBody, itemHeader) =>
		{
			itemHeader.textContent = `Combat ${combat}/${combatSim.fightCounter} (${combatSim.combatHistory.attackType}, ${combatSim.useExpectedValues.value ? 'expected' : 'random'} values)`;
			generateUIForCombatHistory(itemBody, combatSim);
		});
	}
}