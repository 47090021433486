import { SectNames, SectRow } from "../data/sect";
import { KnownTech } from "./KnownTech";
import { ModData } from "./ModData";
import { Bonus } from "./Bonus";
import { RecordValue } from "./Value";
import { Sect } from "./Sect";
import { SerializableTile, Tile } from "./Tile";

export const PlanetTypeValues =
{
	normal: "Normal",
	barren: "Barren",
	jungle: "Jungle",
	frozen: "Frozen",
}

export type SerializableLocation =
{
	planetType: string;
	sect: string;
	tile: SerializableTile;
}

export class Location
{
	readonly planetType = new RecordValue(PlanetTypeValues, "normal", "Planet Type");
	public sect: Sect = new Sect(this.context);
	public tile: Tile = new Tile(this.context);

	constructor(readonly context: ModData)
	{
	}

	public initialize()
	{
		this.planetType.set('normal', 'UI');
		this.sect.type.set('Ort', 'UI');
		this.tile.initialize();
	}

	public toSerializableObject(): SerializableLocation
	{
		const out: SerializableLocation = {
			planetType: this.planetType.value,
			sect: this.sect.type.value,
			tile: this.tile.toSerializableObject(),
		};
		return out;
	}

	public fromSerializableObject(obj: SerializableLocation): void
	{
		this.planetType.set((obj?.planetType??"normal") as any, 'UI');
		this.sect.type.set((obj?.sect??"Ort") as SectNames, 'UI');

		this.tile.initialize();
		if (obj.tile !== undefined)
			this.tile.fromSerializableObject(obj.tile);
	}

	public getBonuses(knownTech: KnownTech): Bonus[]
	{
		const out: Bonus[] = [];

		out.push(...this.sect.getPlanetaryBonuses());

		// environment tech bonus
		if (this.planetType.value === "barren" && knownTech.barrenEnvironment.value)
		{
			out.push(new Bonus(
				`${knownTech.barrenEnvironment.ui.label}`,
				(status) => status,
				(stats, source) =>
				{
					stats.agility.applyBonus(source, this.context.ini.data.Bonuses.agility_barren_tech, 'getBonuses');
					return stats;
				})
			);
		}
		if (this.planetType.value === "jungle" && knownTech.jungleEnvironment.value)
		{
			out.push(new Bonus(
				`${knownTech.jungleEnvironment.ui.label}`,
				(status) => status,
				(stats, source) =>
				{
					stats.agility.applyBonus(source, this.context.ini.data.Bonuses.agility_jungle_tech, 'getBonuses');
					return stats;
				})
			);
		}
		if (this.planetType.value === "frozen" && knownTech.frozenEnvironment.value)
		{
			out.push(new Bonus(
				`${knownTech.frozenEnvironment.ui.label}`,
				(status) => status,
				(stats, source) =>
				{
					stats.agility.applyBonus(source, this.context.ini.data.Bonuses.agility_frozen_tech, 'getBonuses');
					return stats;
				})
			);
		}

		return out;
	}
}
