import { DataPoint } from "../app/resourcemanager";
import { Bonus } from "../sim/Bonus";
import { KnownTech } from "../sim/KnownTech";
import { Traits } from "../sim/Traits";

type Scope = "galaxy" | "planet" | "stack";

const RelicTypeById: { [id: number] : RelicType } =
{
	0: "Tax Collecting",
	1: "Church Diplomacy",
	2: "Planet Food",
	3: "Planet Plague",
	4: "Stack Defense",
	5: "Stack Accuracy",
	6: "Stack Strength",
	7: "Stack Research",
	8: "Stack Production",
	9: "AI House",
	10: "Stack Plague",
	11: "Stack Spot",
	12: "Stack Camouflage",
	13: "Stack Psy Defense",
	14: "League Diplomacy",
	15: "Unit Pay",
	16: "Tithe Collecting",
	17: "Planet Healing",
	18: "Stack Movement",
	19: "Stack Agility",
	20: "Agora Bonus",
}

type RelicType = "Tax Collecting" | "Church Diplomacy" | "Planet Food" | "Planet Plague" | "Stack Defense" | "Stack Accuracy" | "Stack Strength" | "Stack Research" | "Stack Production" | "AI House" | "Stack Plague" | "Stack Spot" | "Stack Camouflage" | "Stack Psy Defense" | "League Diplomacy" | "Unit Pay" | "Tithe Collecting" | "Planet Healing" | "Stack Movement" | "Stack Agility" | "Agora Bonus";

export class RelicRow
{
	readonly type: typeof RelicTypeById[number];

	constructor(readonly id: number, readonly scope: Scope, readonly bonusValue: number, readonly name: string, readonly description: string)
	{
		this.type = RelicTypeById[id];
	}

	public getBonuses(knownTech: KnownTech): Bonus[]
	{
		const out: Bonus[] = [];

		if (!knownTech.theurgy.value)
			return out;

		switch (this.type)
		{
			case "Stack Defense":
				out.push(new Bonus(
					`Relic ${this.type}`,
					(status) => status,
					(stats, source) =>
					{
						stats.armor.applyBonusPercent(source, this.bonusValue, source);
						return stats;
					}
				));
				break;
			case "Stack Accuracy":
				out.push(new Bonus(
					`Relic ${this.type}`,
					(status) => status,
					(stats, source) =>
					{
						Object.values(stats.attacks).forEach((attack) => attack.accuracy.applyBonus(source, this.bonusValue, source));
						return stats;
					}
				));
				break;
			case "Stack Strength":
				out.push(new Bonus(
					`Relic ${this.type}`,
					(status) => status,
					(stats, source) =>
					{
						Object.values(stats.attacks).forEach((attack) => attack.strength.applyBonusPercent(source, this.bonusValue, source));
						return stats;
					}
				));
				break;
			case "Stack Spot":
				out.push(new Bonus(
					`Relic ${this.type}`,
					(status) => status,
					(stats, source) =>
					{
						stats.spot.applyBonus(source, this.bonusValue, 'Stack Spot');
						return stats;
					}
				));
				break;
			case "Stack Camouflage":
				out.push(new Bonus(
					`Relic ${this.type}`,
					(status) => status,
					(stats, source) =>
					{
						stats.camo.applyBonus(source, this.bonusValue, source);
						return stats;
					}
				));
				break;
			case "Stack Psy Defense":
				out.push(new Bonus(
					`Relic ${this.type}`,
					(status) => status,
					(stats, source) =>
					{
						stats.psyDefense.applyBonusPercent(source, this.bonusValue, source);
						return stats;
					}
				));
				break;
			case "Stack Agility":
				out.push(new Bonus(
					`Relic ${this.type}`,
					(status) => status,
					(stats, source) =>
					{
						stats.agility.applyBonus(source, this.bonusValue, source);
						return stats;
					}
				));
				break;
		}

		return out;
	}
}

export class RelicsTable
{
	readonly rows: RelicRow[] = [];

	constructor(tableData: DataPoint)
	{
		this.parseTableData(tableData.data);
	}

	private parseTableData(input: string): void
	{
		const lines = input
			.trim()
			.split('\n')
			.map(line => line.trim())
			.filter(line => !line.startsWith('//'))
			.filter(line => !line.startsWith('}'))
			.filter(line => !line.startsWith('{'))
		;

		lines.forEach((line, id) =>
		{
			const rowMatches = line.match(/\"([^"]*)\"\s*\"([^"]*)\"\s*\"([^"]*)\"\s*\"([^"]*)\"/);
			if (!rowMatches)
				return;

			const scope = rowMatches[1] as Scope;
			const bonus = parseInt(rowMatches[2]);
			const name = rowMatches[3];
			const description = rowMatches[4];

			this.rows.push(new RelicRow(id, scope, bonus, name, description));
		});
	}

	public getCombatRelics(): RelicRow[]
	{
		return this.rows.filter(r => r.scope === "stack");
	}
}
