import { Tabulator, ColumnDefinition } from "tabulator-tables";
import { CityTable } from "../data/city";
import { TableBase, TableStateStorage, bool, common, genericString, numerical } from "./tabulator";
import { TechTable } from "../data/tech";

export class CityTableUI extends TableBase
{
	constructor(elementSelector: string, private cities: CityTable, private tech: TechTable, private showSubGraph: (id: string) => void, stateStorage: TableStateStorage)
	{
		super(elementSelector, "Cities", stateStorage);
		this.init();
	}

// Columns:
//   water - can be placed on an ocean hex
//   land - can be placed on a land hex
//   road - 1 = builds road, 2 = builds road except on ocean
//   barren - can be placed on a barren hex
//   neutral - default owner is neutral
//   build - can be built by a player
//   area - resource collection area
//   Crd/Trn - city upkeep
//   Credits - cost to build city
//   Turns2Bld - construction phase # of turns (city health increases more slowly during the construction phase)
//   BuiltUnits - can the structure build units
//   Tech - tech required to build city
//   Value - asset value of city (used to compute player's worth)
// New columns:
//   Rate - the healing rate bonus / this structure on the planet
//   Limit - the healing rate maximum this structure can add to the planetary healing rate
//   Bonus - the healing rate bonus for units in this structure of the specific type
//   Agility - the agility of the structure
//   Armor - the armor of the structure
//   Unit Armor Factor (UAF) - the armor factor to apply to units defending in the structure
//   SW - Sect Weight, how much does this structure add to the sect value for that structure on the planet


	protected buildColumns(): ColumnDefinition[]
	{
		return [
			{ title: "ID", field: "id", visible: false },
			{ title: "Name", field: "name", frozen: true, ...common, ...genericString },
			{
				title: 'Graph',
				field: 'opengraph',
				formatter: "html",
				headerSort: true,
				headerFilter: false,
				frozen: true,
				cellClick: (e, cell) =>
				{
					this.showSubGraph(cell.getRow().getData().graph_id);
				}
			},
			{ title: "Tech", field: "tech", ...common, ...genericString },
			{ title: 'Total Cost', field: 'totalTechCost', ...common, ...numerical },
			{ title: "Water", field: "water", ...common, ...bool },
			{ title: "Land", field: "land", ...common, ...bool },
			{ title: "Road", field: "road", ...common, ...bool },
			{ title: "Barren", field: "barren", ...common, ...bool },
			{ title: "Neutral", field: "neutral", ...common, ...bool },
			{ title: "Build", field: "build", ...common, ...bool },
			{ title: "Area", field: "area", ...common, ...numerical },
			{ title: "Credits/Turn", field: "crd_trn", ...common, ...numerical },
			{ title: "Credits", field: "credits", ...common, ...numerical },
			{ title: "Turns to Build", field: "turns_to_build", ...common, ...numerical },
			{ title: "Can Build Units", field: "build_units", ...common, ...bool },
			{ title: "Value", field: "value", ...common, ...numerical },
			{ title: "Rate", field: "rate", ...common, ...numerical },
			{ title: "Limit", field: "limit", ...common, ...numerical },
			{ title: "Bonus", field: "bonus", ...common, ...numerical },
			{ title: "Agility", field: "agility", ...common, ...numerical },
			{ title: "Armor", field: "armor", ...common, ...numerical },
			{ title: "Unit Armor Factor", field: "uaf", ...common, ...numerical },
			{ title: "SW", field: "sw", ...common, ...numerical },
			{ title: 'All Dependencies', field: 'allDependencies', sorter: 'string', ...common, ...genericString },
			{ title: 'Raw', field: 'raw', sorter: 'string', ...common, ...genericString },
		];
	}

	/*export type CityDataRow = {
		id: number;
		graph_id: string;
		raw: string;
		name: string;
		tech: number;
		water: boolean;
		land: boolean;
		road: boolean;
		barren: boolean;
		neutral: boolean;
		build: boolean;
		area: number;
		crd_trn: number;
		credits: number;
		turns_to_build: number;
		build_units: boolean;
		value: number;
		rate: number;
		limit: number;
		bonus: number;
		agility: number;
		armor: number;
		uaf: number;
		sw: number;
	};*/

	protected buildData()
	{
		const data = [];
		return this.cities.rows.map((row) =>
		{
			const tech = this.tech.get(row.tech);
			const techName = tech ? tech.name : "";

			const allDeps = tech ? [techName, ...tech.allDependencies].join(', ') : "";

			return {
				id: row.id,
				name: row.name,
				tech: techName,
				totalTechCost: tech ? tech.totalCost : 0,
				allDependencies: allDeps,
				graph_id: row.graph_id,
				water: row.water.toString(),
				land: row.land.toString(),
				road: row.road.toString(),
				barren: row.barren.toString(),
				neutral: row.neutral.toString(),
				build: row.build.toString(),
				area: row.area,
				crd_trn: row.crd_trn,
				credits: row.credits,
				turns_to_build: row.turns_to_build,
				build_units: row.build_units.toString(),
				value: row.value,
				rate: row.rate,
				limit: row.limit,
				bonus: row.bonus,
				agility: row.agility,
				armor: row.armor,
				uaf: row.uaf,
				sw: row.sw,
				opengraph: tech ? `<button type="button" class="btn btn-info btn-sm">Graph</button>` : "",
				raw: row.raw,
			};
		});
	}
}