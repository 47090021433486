import { SectTable } from "../data/sect";
import { TableBase, TableStateStorage, bool, common, genericString, minMaxFilterEditor, minMaxFilterFunction, numerical } from "./tabulator";
import { Tabulator, ColumnDefinition } from "tabulator-tables";

export class SectTableUI extends TableBase
{
	constructor(elementSelector: string, private sects: SectTable, stateStorage: TableStateStorage)
	{
		super(elementSelector, "Sects", stateStorage);
		this.init();
	}

	protected buildData(): any[]
	{
		return Object.values(this.sects.sects).map(sect =>
		{
			const bonuses: { [key: string]: number } = {};
			Object.values(sect.bonuses).map(bonus =>
			{
				bonuses[bonus.id] = bonus.value;
			});
			return {
				id: sect.id,
				...bonuses,
			};
		});
	}

	protected buildColumns(): ColumnDefinition[]
	{
		const defaultFormat = { ...numerical, ...common };

		const out: ColumnDefinition[] =
		[
			{title: 'Sect', field: 'id', frozen: true, ...genericString },
		];

		for (let sect of Object.values(this.sects.sects))
		{
			const bonuses = sect.bonuses;

			for (let bonus of Object.values(bonuses))
			{
				const bonusColumn = {
					title: bonus.name,
					field: bonus.id,
					...defaultFormat,
				};

				let dest = out;

				bonus.group.forEach(group =>
				{
					let g = dest.find(column => column.title === group);
					if (!g)
					{
						g = {
							title: group,
							columns: [],
						};
						dest.push(g);
					}
					dest = g.columns!;
				});

				if (!dest.find(column => column.field === bonus.id))
				{
					dest.push(bonusColumn);
				}
			}
		}

		return out;
	}
}
