import { makeId } from "../app/Utils";

export abstract class Notifier<T>
{
	readonly onChanged: { [key: string] : ((value: T) => void) } = {};

	public notify(invokedBy: string): void
	{
		const value = this.value;
		for (const key in this.onChanged)
		{
			if (key === invokedBy)
				continue;
			this.onChanged[key](value);
		}
	}

	public abstract get value(): T;
}

export class PlainValueProxy extends Notifier<string>
{
	readonly id = `PlainValueProxy${makeId()}`;
	private p: string;

	constructor(getValue: () => string, notifier: Notifier<any>)
	{
		super();

		this.p = getValue();

		notifier.onChanged[this.id] = () =>
		{
			this.p = getValue();
			this.notify(this.id);
		}
	}

	public override get value(): string
	{
		return this.p;
	}
}

export class Value extends Notifier<number>
{
	private _value: number = this.opts.initial;
	private _initial: number = this.opts.initial;

	private _sources: { source: string; d: number; }[] = [];
	public get sources()
	{
		return this._sources;
	}
	public set sources(s: { source: string; d: number; }[])
	{
		this._sources = s;
	}

	constructor(protected opts: {
		readonly initial: number,
		readonly uiString: string,
		readonly modifiable: boolean, // can be set by user in UI
		readonly min?: number,
		readonly max?: number
	} )
	{
		super();
		this._sources = [{ source: 'Base', d: this.opts.initial }];
	}

	public get initial(): number
	{
		return this._initial;
	}

	public get uiString(): string
	{
		return this.opts.uiString;
	}

	public get modifiable(): boolean
	{
		return this.opts.modifiable;
	}

	public get min(): number | undefined
	{
		return this.opts.min;
	}

	public get max(): number | undefined
	{
		return this.opts.max;
	}

	public get value(): number
	{
		return this._value;
	}
	public set value(d: number)
	{
		d = +d;
		this._value = d;
	}

	public applyBonus(source: string, d: number, invokedBy: string)
	{
		console.log(`applyBonus ${source} ${d} ${invokedBy}`);

		d = +d;
		if (d === 0)
			return;
		if (this.sources.find(s => s.source === source))
			return;

		this.value += d;
		this.sources.push({ source, d });

		this.notify(invokedBy);

		return this;
	}

	public applyBonusPercent(source: string, d: number, invokedBy: string)
	{
		d = +d;
		this.applyBonus(source, this.initial * d / 100, invokedBy);
		return this;
	}

	public removeBonus(source: string, invokedBy: string)
	{
		const index = this.sources.findIndex(s => s.source === source);
		if (index >= 0)
		{
			this.value -= this.sources[index].d;
			this.sources.splice(index, 1);
		}

		this.notify(invokedBy);

		return this;
	}

	public reset(invokedBy: string, sourceFilter: (source: string) => boolean)
	{
		//const sourcesToKeep = this.sources.filter(s => sourceFilter(s.source));

		return this.initialize(this.initial, 'Base', invokedBy);
	}

	public initialize(d: number, source: string, invokedBy: string)
	{
		d = +d;
		this._initial = d;
		this.value = d;
		this.sources = [{ source, d }];

		this.notify(invokedBy);

		return this;
	}
}

export class ValueProxy extends Value
{
	readonly id = `ValueProxy${makeId()}`;
	private p: Value;

	constructor(getValue: () => Value, notifier: Notifier<any>)
	{
		const value = getValue();
		super({	initial: value.initial, uiString: value.uiString, modifiable: value.modifiable, min: value.min, max: value.max });

		this.p = value;
		this.p.onChanged[this.id] = () => this.notify(this.id);

		notifier.onChanged[this.id] = () =>
		{
			delete this.p.onChanged[this.id];
			this.p = getValue();
			this.p.onChanged[this.id] = () => this.notify(this.id);
			this.notify(this.id);
		}
	}

	public override get initial(): number
	{
		return this.p.initial;
	}

	public override get sources()
	{
		return this.p.sources;
	}
	public override set sources(s: { source: string; d: number; }[])
	{
		this.p.sources = s;
	}

	public override get value(): number
	{
		return this.p.value;
	}
	protected override set value(d: number)
	{
		this.p.value = d;
	}

	public override initialize(d: number, source: string, invokedBy: string)
	{
		this.p.initialize(d, source, invokedBy);

		this.notify(invokedBy);

		return this;
	}
}

export class ReadonlyValue
{
	constructor(readonly value: number, readonly uiString: string)
	{
	}
}

export class RecordValue<T extends Record<keyof T, string | undefined>> extends Notifier<keyof T>
{
	constructor(private _values: T, private _value: keyof T, readonly uiString: string)
	{
		super();
	}

	public get values(): T
	{
		return this._values;
	}

	public setValues(values: T, invokedBy: string)
	{
		this._values = values;

		this.notify(invokedBy);
	}

	public get value(): keyof T
	{
		return this._value;
	}

	public get uiStringForValue(): string
	{
		return this.values[this.value]!;
	}

	public set(value: keyof T, invokedBy: string)
	{
		if (this._value === value)
			return;

		this._value = value;

		this.notify(invokedBy);
	}
}

export class RecordValueProxy<T extends Record<keyof T, string | undefined>> extends RecordValue<T>
{
	readonly id = `RecordValueProxy${makeId()}`;
	private p: RecordValue<T>;

	constructor(getRecord: () => RecordValue<T>, notifier: Notifier<any>)
	{
		const record = getRecord();
		super(record.values, record.value, record.uiString);

		this.p = record;
		this.p.onChanged[this.id] = () => this.notify(this.id);

		notifier.onChanged[this.id] = () =>
		{
			delete this.p.onChanged[this.id];
			this.p = getRecord();
			this.p.onChanged[this.id] = () => this.notify(this.id);
			this.notify(this.id);
		}
	}

	public override get values(): T
	{
		return this.p.values;
	}

	public override setValues(values: T, invokedBy: string)
	{
		this.p.setValues(values, invokedBy);

		this.notify(invokedBy);
	}

	public override get value(): keyof T
	{
		return this.p.value;
	}

	public override set(value: keyof T, invokedBy: string)
	{
		this.p.set(value, invokedBy);

		this.notify(invokedBy);
	}
}

export class RecordMultiValue<T extends Record<keyof T, string | undefined>> extends Notifier<(keyof T)[]>
{
	private _disabledValue: (keyof T)[] = [];
	constructor(private _allValues: T, private _value: (keyof T)[], readonly uiString: string)
	{
		super();
	}

	public get allValues(): T
	{
		return this._allValues;
	}

	public setAllValues(values: T, disabled: (keyof T)[], invokedBy: string)
	{
		this._allValues = values;
		this._disabledValue = disabled;

		this.notify(invokedBy);
	}

	public get disabledValues(): (keyof T)[]
	{
		return this._disabledValue;
	}

	public get value(): (keyof T)[]
	{
		return this._value;
	}

	public set(value: (keyof T)[], invokedBy: string)
	{
		if (this._value === value)
			return;

		this._value = value;

		this.notify(invokedBy);
	}
}

export class RecordMultiValueProxy<T extends Record<keyof T, string | undefined>> extends RecordMultiValue<T>
{
	readonly id = `RecordMultiValueProxy${makeId()}`;
	private p: RecordMultiValue<T>;

	constructor(getRecord: () => RecordMultiValue<T>, notifier: Notifier<any>)
	{
		const record = getRecord();
		super(record.allValues, record.value, record.uiString);

		this.p = record;
		this.p.onChanged[this.id] = () => this.notify(this.id);

		notifier.onChanged[this.id] = () =>
		{
			delete this.p.onChanged[this.id];
			this.p = getRecord();
			this.p.onChanged[this.id] = () => this.notify(this.id);
			this.notify(this.id);
		}
	}

	public override get allValues(): T
	{
		return this.p.allValues;
	}

	public override setAllValues(values: T, disabled: (keyof T)[], invokedBy: string)
	{
		this.p.setAllValues(values, disabled, invokedBy);

		this.notify(invokedBy);
	}

	public override get disabledValues(): (keyof T)[]
	{
		return this.p.disabledValues;
	}

	public override get value(): (keyof T)[]
	{
		return this.p.value;
	}

	public override set(value: (keyof T)[], invokedBy: string)
	{
		this.p.set(value, invokedBy);

		this.notify(invokedBy);
	}
}

export class FlagUI
{
	readonly label: string = '';
	readonly trueText?: string;
	readonly falseText?: string;
	readonly trueIcon?: string;
	readonly falseIcon?: string;
	readonly disabled?: boolean;

	public toString(): string
	{
		return this.label;
	}
}

export class Flag extends Notifier<boolean>
{
	private _initial: boolean;
	private _value: boolean;
	private _sources: string[] = [];

	public get sources() { return this._sources; }
	public get initial() { return this._initial; }

	constructor(initial: boolean, readonly ui: FlagUI, private _disabled: boolean = false)
	{
		super();

		this._initial = initial;
		this._value = initial;
		this._sources = [ `Base (${this._initial})` ];
	}

	public get value(): boolean
	{
		return this._value;
	}

	public get disabled(): boolean
	{
		return this._disabled;
	}

	public setDisabled(value: boolean, invokedBy: string)
	{
		this._disabled = value;
		this.notify(invokedBy);
	}

	public set(d: boolean, source: string, invokedBy: string)
	{
		this._value = d;
		this.sources.push(`${source} (${d})`);

		this.notify(invokedBy);

		return this;
	}

	public initialize(d: boolean, source: string, invokedBy: string)
	{
		this._initial = d;
		this._value = d;
		this._sources = [`${source} (${d})`];

		this.notify(invokedBy);

		return this;
	}

	public reset(invokedBy: string, sourceFilter: (source: string) => boolean)
	{
		this._value = this.initial;
		this._sources = [this._sources[0]];

		this.notify(invokedBy);

		return this;
	}
}

export class FlagProxy extends Flag
{
	readonly id = `FlagProxy${makeId()}`;
	private p: Flag;

	constructor(getFlag: () => Flag, notifier: Notifier<any>)
	{
		const flag = getFlag();

		super(flag.initial, flag.ui);

		this.p = flag;
		this.p.onChanged[this.id] = () => this.notify(this.id);

		notifier.onChanged[this.id] = () =>
		{
			delete this.p.onChanged[this.id];
			this.p = getFlag();
			this.p.onChanged[this.id] = () => this.notify(this.id);
			this.notify(this.id);
		}
	}

	public override get value(): boolean
	{
		return this.p.value;
	}

	public override get disabled(): boolean
	{
		return this.p.disabled;
	}

	public override get sources()
	{
		return this.p.sources;
	}

	public override get initial()
	{
		return this.p.initial;
	}

	public override setDisabled(value: boolean, invokedBy: string)
	{
		this.p.setDisabled(value, invokedBy);

		this.notify(invokedBy);

		return this;
	}

	public override set(value: boolean, source: string, invokedBy: string)
	{
		this.p.set(value, source, invokedBy);

		this.notify(invokedBy);

		return this;
	}

	public override initialize(d: boolean, source: string, invokedBy: string)
	{
		this.p.initialize(d, source, invokedBy);

		this.notify(invokedBy);

		return this;
	}

	public override reset(invokedBy: string, sourceFilter: (source: string) => boolean)
	{
		this.p.reset(invokedBy, sourceFilter);

		this.notify(invokedBy);

		return this;
	}
}
