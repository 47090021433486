import { ColumnDefinition, Tabulator } from "tabulator-tables";
import { TargetTable } from "../data/target";
import { TableBase, TableStateStorage, bool, common, genericString, numerical } from "./tabulator";

export class TargetTableUI extends TableBase
{
	constructor(elementSelector: string, private targets: TargetTable, stateStorage: TableStateStorage)
	{
		super(elementSelector, "Targets", stateStorage);
		this.init();
	}

	protected buildColumns(): ColumnDefinition[]
	{
		const out = [
			{ title: "Name", field: "name", frozen: true, ...common, ...genericString },
			{ title: "Order", field: "order", ...common, ...numerical },
			{ title: "Phases", field: "phases", ...common, ...numerical },
		];

		this.targets.moveTypes.forEach(moveType =>
		{
			out.push({ title: moveType, field: moveType, ...common, ...bool });
		});

		return out;
	}

	protected buildData()
	{
		return this.targets.getWeaponsByOrder(undefined).map(weaponType =>
		{
			const out: { [key: string]: string | number} = {
				name: weaponType.name,
				order: weaponType.order,
				phases: weaponType.phases,
			};

			this.targets.moveTypes.forEach(moveType =>
			{
				out[moveType] = weaponType.canTargetMoveType(moveType).toString();
			});

			return out;
		});
	}
}