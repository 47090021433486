import { UnitDataRow, WeaponStats } from "../data/unit";
import { Value } from "./Value";
import { Attack } from "./Attack";
import { makeId } from "../app/Utils";

export class CombatStats
{
	readonly id = `CombatStats${makeId()}`;
	readonly rank = new Value({ initial: 0, uiString: 'Rank', modifiable: false });
	readonly armor = new Value({ initial: 0, uiString: 'Armor', modifiable: false });
	readonly agility = new Value({ initial: 0, uiString: 'Agility', modifiable: false });
	readonly psyDefense = new Value({ initial: 0, uiString: 'Psy Defense', modifiable: false });
	readonly spot = new Value({ initial: 0, uiString: 'Spot', modifiable: false });
	readonly camo = new Value({ initial: 0, uiString: 'Camo', modifiable: false });
	readonly attacks: { [Property in keyof WeaponStats]: Attack; } = {
		Water: new Attack("Water"),
		Air: new Attack("Air"),
		Indirect: new Attack("Indirect"),
		Direct: new Attack("Direct"),
		Close: new Attack("Close"),
		Psy: new Attack("Psy"),
		RangedSp: new Attack("Ranged Sp"),
		DirectSp: new Attack("Direct Sp"),
		CloseSp: new Attack("Close Sp"),
	};

	private _data: UnitDataRow | undefined;
	public get data(): UnitDataRow | undefined
	{
		return this._data;
	}
	public set data(value: UnitDataRow | undefined)
	{
		this._data = value;

		if (value === undefined)
		{
			this.reset(this.id, () => true);
			return;
		}

		this.rank.initialize(value.misc.rank, 'Base', this.id);
		this.armor.initialize(value.armor, 'Base', this.id);
		this.agility.initialize(value.ag, 'Base', this.id);
		this.psyDefense.initialize(value.psyDef, 'Base', this.id);
		this.spot.initialize(value.spot, 'Base', this.id);
		this.camo.initialize(value.camo, 'Base', this.id);

		this.attacks.Water.strength.initialize(value.combatStats.Water.Dmg, 'Base', this.id);
		this.attacks.Water.accuracy.initialize(value.combatStats.Water.Acc, 'Base', this.id);
		this.attacks.Air.strength.initialize(value.combatStats.Air.Dmg, 'Base', this.id);
		this.attacks.Air.accuracy.initialize(value.combatStats.Air.Acc, 'Base', this.id);
		this.attacks.Indirect.strength.initialize(value.combatStats.Indirect.Dmg, 'Base', this.id);
		this.attacks.Indirect.accuracy.initialize(value.combatStats.Indirect.Acc, 'Base', this.id);
		this.attacks.Direct.strength.initialize(value.combatStats.Direct.Dmg, 'Base', this.id);
		this.attacks.Direct.accuracy.initialize(value.combatStats.Direct.Acc, 'Base', this.id);
		this.attacks.Close.strength.initialize(value.combatStats.Close.Dmg, 'Base', this.id);
		this.attacks.Close.accuracy.initialize(value.combatStats.Close.Acc, 'Base', this.id);
		this.attacks.Psy.strength.initialize(value.combatStats.Psy.Dmg, 'Base', this.id);
		this.attacks.Psy.accuracy.initialize(value.combatStats.Psy.Acc, 'Base', this.id);
		this.attacks.RangedSp.strength.initialize(value.combatStats.RangedSp.Dmg, 'Base', this.id);
		this.attacks.RangedSp.accuracy.initialize(value.combatStats.RangedSp.Acc, 'Base', this.id);
		this.attacks.DirectSp.strength.initialize(value.combatStats.DirectSp.Dmg, 'Base', this.id);
		this.attacks.DirectSp.accuracy.initialize(value.combatStats.DirectSp.Acc, 'Base', this.id);
		this.attacks.CloseSp.strength.initialize(value.combatStats.CloseSp.Dmg, 'Base', this.id);
		this.attacks.CloseSp.accuracy.initialize(value.combatStats.CloseSp.Acc, 'Base', this.id);
	}

	constructor()
	{
	}

	public reset(invokedBy: string, sourceFilter: (source: string) => boolean): void
	{
		console.log(`CombatStats.reset(${invokedBy})`);

		this.armor.reset(invokedBy, sourceFilter);
		this.agility.reset(invokedBy, sourceFilter);
		this.psyDefense.reset(invokedBy, sourceFilter);
		this.spot.reset(invokedBy, sourceFilter);
		this.camo.reset(invokedBy, sourceFilter);
		Object.values(this.attacks).forEach((attack) => attack.reset(invokedBy, sourceFilter));
	}
}
