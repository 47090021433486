import { Value } from "./Value";

export class Attack
{
	readonly strength: Value = new Value({ initial: 0, uiString: `${this.uiString} strength`, modifiable: false });
	readonly accuracy: Value = new Value({ initial: 0, uiString: `${this.uiString} accuracy`, modifiable: false });

	constructor(readonly uiString: string)
	{
	}

	public reset(invokedBy: string, sourceFilter: (source: string) => boolean): void
	{
		this.strength.reset(invokedBy, sourceFilter);
		this.accuracy.reset(invokedBy, sourceFilter);
	}
}
