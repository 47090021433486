import { Flag, PlainValueProxy, RecordValue, Value, ValueProxy, FlagUI, RecordMultiValue } from "../sim/Value";
import { addCol, addColAuto, addColX, addRow, format, makeIconButton, makeId } from "../app/Utils";

function makeTooltipForFlag(flag: Flag)
{
	return `
	<div class="container g-0 m-0 flag-tooltip">
		<div class="row row-cols-1 m-0">
			<div class="col">${flag.ui.label}</div>
		</div>
		<hr class="hr" />
		${
			flag.sources.map(s => `
				<div class="row row-cols-1 m-0">
					<div class="col-auto">${s}</div>
				</div>
			`).join('')
		}
	<div>`;
}

export function createFlagUI(parentEl: HTMLElement, flag: Flag, updateValueDetails: ((htmlString: string) => void) | undefined, onChanged: (source: string) => void): void
{
	const div = document.createElement('div');
	div.classList.add('form-check');
	parentEl.appendChild(div);

	const randomId = makeId();

	const input = document.createElement('input');
	div.appendChild(input);
	input.id = `input-${randomId}`;
	input.type = 'checkbox';
	input.checked = flag.value;
	input.disabled = flag.disabled;
	input.classList.add('form-check-input');

	input.addEventListener('change', (e) =>
	{
		flag.initialize((e.target as HTMLInputElement).checked, 'UI', input.id);
		onChanged(input.id);
	});

	const label = document.createElement('label');
	div.appendChild(label);

	label.classList.add('form-label');

	label.textContent = flag.ui.label;

	flag.onChanged[input.id] = (value: boolean) =>
	{
		input.checked = value;
		input.disabled = flag.disabled;
	};

	if (!updateValueDetails)
		label.setAttribute('for', `input-${randomId}`);
	else
	{
		label.onclick = () =>
		{
			if (!updateValueDetails)
				return;

			lastSelectedValue?.classList.remove("selected");
			label.classList.add("selected");
			lastSelectedValue = label;

			updateValueDetails(makeTooltipForFlag(flag));
		}
	}
}

export function createFlagButtonUI(parentEl: HTMLElement, flag: Flag, color: string, onChanged: (source: string) => void)
{
	const update = () =>
	{
	//	button.classList.remove('btn-outline-success');
	//	button.classList.remove('btn-outline-secondary');
		const i = $(`#${button.id} > i`)
			.removeClass(flag.ui.trueIcon!)
			.removeClass(flag.ui.falseIcon!)
		;

		if (flag.value)
		{
	//		button.classList.add('btn-outline-success');
			i.addClass(flag.ui.trueIcon!);
			button.title = flag.ui.trueText!;
		}
		else
		{
	//		button.classList.add('btn-outline-secondary');
			i.addClass(flag.ui.falseIcon!);
			button.title = flag.ui.falseText!;
		}
	};

	const button = makeIconButton(parentEl, flag.ui.label, flag.value ? flag.ui.trueIcon! : flag.ui.falseIcon!, color, () =>
	{
		flag.initialize(!flag.value, 'UI', button.id);
	});

	flag.onChanged['updateState'] = update;

	update();

	return button;
}

function makeTooltipForValue(value: Value)
{
	return `
	<div class="container g-0 m-0 value-tooltip">
		<div class="row row-cols-1 m-0">
			<div class="col">${value.uiString}</div>
		</div>
		<hr class="hr" />
		${
			value.sources.map(s => `
				<div class="row row-cols-2 m-0">
					<div class="col-auto ${s.d > 0 ? "value-increased" : "value-decreased"}">${s.source}</div>
					<div class="col-auto ${s.d > 0 ? "value-increased" : "value-decreased"}">${s.d}</div>
				</div>
			`).join('')
		}
		<hr class="hr" />
		<div class="row row-cols-2 m-0">
			<div class="col">Total</div>
			<div class="col">${format(value.value)}</div>
		</div>
	<div>`;
}

let lastSelectedValue: HTMLElement | undefined = undefined;
export function createValueUI(parentEl: HTMLElement, value: Value, onChanged: (source: string) => void, updateValueDetails: (htmlString: string) => void, showName: boolean = true)
{
	const row = addRow(parentEl);
	row.classList.add('justify-content-md-left');

	const randomId = makeId();

	if (value.uiString !== '' && showName)
	{
		const label = document.createElement('label');
		addCol(row).appendChild(label);
		label.setAttribute('for', `input-${randomId}`);
		label.classList.add('form-label');
		label.classList.add('value-label');
		label.classList.add('me-2');
		label.textContent = value.uiString;
	}

	const inputId = `input-${randomId}`;
	let input: HTMLInputElement | null = null;
	if (value.modifiable)
	{
		input = document.createElement('input');
		addCol(row).appendChild(input);
		input.id = inputId;

		if (value.min !== undefined && value.max !== undefined)
		{
			input.type = 'range';
			input.classList.add('form-range');
			input.classList.add('me-2');
			input.classList.add('value-range');
			input.min = value.min?.toString() ?? '0';
			input.max = value.max?.toString() ?? '100';
		}
		else
		{
			input.type = 'number';
			input.classList.add('form-control');
			input.classList.add('me-2');
			input.classList.add('value-number');
		}

		input.addEventListener('change', (e) =>
		{
			value.initialize(parseInt((e.target as HTMLInputElement).value), 'UI', inputId);
			onChanged(inputId);
		});
	}

	const finalValue = document.createElement('span');
	addCol(row).appendChild(finalValue);
	finalValue.id = `span-${randomId}`;
	finalValue.classList.add('form-label');
	finalValue.classList.add('me-2');
	finalValue.classList.add('value-span');
	finalValue.setAttribute('data-bs-toggle', 'tooltip');
	finalValue.setAttribute('data-bs-title', '--');
	finalValue.setAttribute('data-bs-html', 'true');
	finalValue.onclick = () =>
	{
		lastSelectedValue?.classList.remove("selected");
		finalValue.classList.add("selected");
		lastSelectedValue = finalValue;

		updateValueDetails(makeTooltipForValue(value));
	};

	const tooltip = new bootstrap.Tooltip(finalValue);

	const setValue = () =>
	{
		if (input)
		{
			input.value = value.initial.toString();
		}

		const tolltipValue = makeTooltipForValue(value);
		finalValue.textContent = (value.initial === value.value) ? `${value.value}` : `${format(value.value)}  [${format(value.initial)}]`;
		finalValue.setAttribute('data-bs-title', tolltipValue);
		tooltip.setContent({ '.tooltip-inner': tolltipValue });
		tooltip.enable();

		finalValue.classList.remove('value-increased');
		finalValue.classList.remove('value-decreased');
		finalValue.classList.remove('value-none');
		if (value.initial < value.value)
		{
			finalValue.classList.add('value-increased');
		}
		else if (value.initial > value.value)
		{
			finalValue.classList.add('value-decreased');
		}
		if (value.initial === 0 && value.value === 0)
		{
			finalValue.classList.add('value-none');
		}

		if (finalValue.classList.contains("selected"))
			updateValueDetails(makeTooltipForValue(value));
	}

	value.onChanged[inputId] = setValue;

	setValue();
}

export function createPlainValueProxyUI(parentEl: HTMLElement, value: PlainValueProxy)
{
	const id = `label_${makeId()}`;
	const label = document.createElement('label');
	parentEl.appendChild(label);
	label.classList.add('form-label');
	label.classList.add('me-2');
	label.id = id;
	label.textContent = value.value;

	value.onChanged[id] = () =>
	{
		label.textContent = value.value;
	}
}

function createEmptyUIForRecordValue<T extends Record<keyof T, string | undefined>>(parentEl: HTMLElement, values: T, uiString: string, selectedValue: (keyof T)[], multi: boolean)
{
	const form = document.createElement('div');
//	form.classList.add('input-group');
	form.classList.add('container');
	form.classList.add('p-0');
	parentEl.appendChild(form);

	const randomId = makeId();

	const row = addRow(form);
	row.classList.add('row-cols-2');

	const label = document.createElement('label');
	addColAuto(row).appendChild(label);
	label.id = `label-${randomId}`;
	label.setAttribute('for', `select-${randomId}`);
	label.classList.add('form-label');
	label.classList.add('m-2');
	label.textContent = uiString;

	const select = document.createElement('select');
	select.id = `select-${randomId}`;
	select.multiple = multi;
	select.classList.add('selectpicker');
	select.setAttribute('data-live-search', 'true');
	addColAuto(row).appendChild(select);

	//if (Object.keys(values).length < 2)
	//	select.disabled = true;

	for (const key in values)
	{
		if (values[key] === undefined)
			continue;
		const option = document.createElement('option');
		option.value = key;
		option.id = key;
		option.textContent = values[key]!;
		selectedValue.forEach(v => { if (v.toString() === key.toString()) option.selected = true; });
		select.appendChild(option);
	}

	createSelectpicker(select);

	return { select, label };
}

export function createMultiValueUI(parentEl: HTMLElement, value: RecordMultiValue<Record<string, string>>, onChanged: (source: string) => void)
{
	const { select, label } = createEmptyUIForRecordValue(parentEl, value.allValues, value.uiString, value.value, true);

	const allValues = Object.keys(value.allValues);
	if (allValues.length === 0)
	{
		$(select).selectpicker('hide');
		label.classList.add('d-none');
	}

	select.addEventListener('change', (e) =>
	{
		value.set(Array.from(select.selectedOptions).map((v) => v.value), select.id);
		onChanged(select.id);
	});

	value.onChanged[select.id] = () =>
	{
		const allValuesUI = Array.from(select.options).map((v) => v.value);
		const allDisabledValuesUI = Array.from(select.options).filter((v) => v.disabled).map((v) => v.value);
		const allValues = Object.keys(value.allValues);

		if (allValues.length === 0)
		{
			$(select).selectpicker('hide');
			label.classList.add('d-none');
			return;
		}
		else
		{
			$(select).selectpicker('show');
			label.classList.remove('d-none');

			if (
				   allValuesUI.length !== allValues.length
				|| allDisabledValuesUI.length !== value.disabledValues.length
				|| allValues.some(v => !allValuesUI.includes(v))
				|| value.disabledValues.some(v => !allDisabledValuesUI.includes(v))
			)
			{
				// remove options
				allValuesUI.forEach(v =>
				{
					select.options.namedItem(v)!.remove();
				});
				// update options
				allValues.forEach(v =>
				{
					const option = document.createElement('option');
					option.value = v;
					option.id = v;
					option.disabled = value.disabledValues.includes(v);
					option.selected = value.value.includes(v);
					option.textContent = value.allValues[v]!;
					select.appendChild(option);
				});

				$(select).selectpicker('destroy');
				createSelectpicker(select);
			}
			else
			{
				$(select).selectpicker('val', value.value);
			}
		}
	};

	return { select, label };
}

export function createSelectpicker(element: HTMLSelectElement)
{
	$(element).selectpicker({
		style: "g-0",
		styleBase: "form-control",
		liveSearch: true,
		actionsBox: false,
		width: '100%',
	});
}

export function createUIForRecordValue<T extends Record<keyof T, string | undefined>>(parentEl: HTMLElement, record: RecordValue<T>, onChanged: (source: string) => void)
{
	const {select, label} = createEmptyUIForRecordValue(parentEl, record.values, record.uiString, [record.value.toString() as keyof T], false)

	select.addEventListener('change', (e) =>
	{
		record.set((e.target as HTMLInputElement).value as keyof T, select.id);
		onChanged(select.id);
	});

	record.onChanged[select.id] = (value: keyof T) =>
	{
		$(select).selectpicker('val', value.toString());
	};

	return { select, label };
}
