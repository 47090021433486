import { ModData } from "./ModData";
import { Tile } from "./Tile";
import { Location, SerializableLocation } from "./Location";
import { SerializableSide, Side, StanceType } from "./Side";
import { Unit } from "./Unit";
import { AttackType, WeaponType } from "../data/target";
import { StackId } from "./Stack";
import { Flag } from "./Value";
import { CombatEventDeath, CombatEventHit, CombatEventMiss, CombatHistory } from "./CombatHistory";
import { UnitLocator } from "./UnitLocator";
import { targetRatio } from "./CombatUtils";
//import { OCR } from "../app/ocr";

export type SerializableCombatSim =
{
	mod: string;
	version: string;
	location: SerializableLocation;
	attacker: SerializableSide;
	defender: SerializableSide;
}

export class CombatSim
{
	//readonly ocr = new OCR();

	public version = '4';
	public location: Location = new Location(this.context);
	public attacker: Side = new Side(this, this.context, 'Neutral', this.location, true);
	public defender: Side = new Side(this, this.context, 'Defending', this.location, false);
	readonly useExpectedValues = new Flag(false,
		{
			label: "Expected Values",
			trueText: "Use expected damage values",
			falseText: "Roll the dice",
			trueIcon: "bi-percent",
			falseIcon: "bi-dice-6-fill",
		}
	);

	public selectedUnit: UnitLocator = new UnitLocator(this.attacker.stack.units[0]);

	public combatHistory: CombatHistory = new CombatHistory();

	public onCombatDone: () => void = () => {};

	public fightCounter = 0;

	constructor(readonly context: ModData)
	{
		this.attacker.stance.onChanged["CombatSim"] = () =>
		{
			if (this.attacker.stance.value === 'Space')
			{
				this.defender.stance.set('Space', 'attacker.stance.onChanged');
				return;
			}

			if (this.attacker.stance.value === 'Landing')
			{
				this.defender.stance.set('PTS', 'attacker.stance.onChanged');
				return;
			}

			this.defender.stance.set('Defending', 'attacker.stance.onChanged');
		};
	}

	public selectUnit(stackId: StackId, unitId: number): void
	{
		const stack = stackId === 'A' ? this.attacker.stack : this.defender.stack;
		const unit = stack.units[unitId];
		this.selectedUnit.set(unit, 'selectUnit');
	}

	public getStack(stackId: StackId)
	{
		return stackId === 'A' ? this.attacker.stack : this.defender.stack;
	}

	public initialize()
	{
		this.location.initialize();
		this.attacker.initialize();
		this.defender.initialize();
		this.combatHistory.reset();
	}

	public toSerializableObject(): SerializableCombatSim
	{
		const out: SerializableCombatSim = {
			mod: this.context.modId,
			version: this.version,
			location: this.location.toSerializableObject(),
			attacker: this.attacker.toSerializableObject(),
			defender: this.defender.toSerializableObject(),
		};
		return out;
	}

	public fromSerializableObject(obj: SerializableCombatSim): void
	{
		this.initialize();

		if (obj.mod !== this.context.modId)
			throw new Error(`Cannot load combat sim from ${obj.mod} into ${this.context.modId}`);
		if (obj.version !== this.version)
			throw new Error(`Cannot load combat sim version ${obj.version} into ${this.version}`);

		this.location.initialize();
		if (obj.location !== undefined)
			this.location.fromSerializableObject(obj.location);

		this.attacker.fromSerializableObject(obj.attacker);
		this.defender.fromSerializableObject(obj.defender);

		this.onSomethingChangedFromUI('fromSerializableObject');
	}

	public reset(invokedBy: string, sourceFilter: (source: string) => boolean): void
	{
		console.log(`reset(${invokedBy})`);

		this.fightCounter = 0;
		this.combatHistory.reset();
		this.attacker.reset(invokedBy, sourceFilter);
		this.defender.reset(invokedBy, sourceFilter);
		this.applyBonuses();
	}

	public runSpotCheck(): void
	{
		// TODO
	}

	public onSomethingChangedFromUI(invokedBy: string): void
	{
		console.log(`onSomethingChangedFromUI(${invokedBy})`);

		const sourceFilter = (source: string) => true;
		this.reset(invokedBy, sourceFilter);
	}

	public applyBonuses(): void
	{
		console.log(`applyBonuses()`);

		const attackerBonuses = this.attacker.getBonuses(this.defender.player);
		const defenderBonuses = this.defender.getBonuses(this.attacker.player);

		this.attacker.stack.applyBonuses(attackerBonuses);
		this.defender.stack.applyBonuses(defenderBonuses);
	}

	/*
	Combat happens in phases (target.dat). On each phase (for example indirect), attacking unit is checked to see if it has that kind of attack.
	Then the code looks for a possible target, starting from rank 1 upwards, and based on which movement type that attack type can hit (target.dat).
	Once a target is chosen, damage is dealt.

	First, comparison between attack strength * attacker's health (1-100%) vs defender's armor (damage.dat). In damage.dat you see % of health that is subtracted by a single hit.
	Second, chance to hit. Roll 1d10, plus accuracy. If total value is equal/greater than agility, you hit.

	This is reiterated n-times for each phase as written in target.dat. Once all phases are complete, code starts from the beginning and keeps doing so until all units from one side are killed or surrendered.

	Something special for naval combat (limited phases)
	*/

/*
for each weapon
{
    for each subphase
    {
        for each attacker that has weapon
        {
            select first target from defenders sorted by target counter and subsorted by rank
            increment target's targetcounter
        }
        for each defender that has weapon
        {
            select first target from attackers sorted by target counter and subsorted by rank
            increment target's targetcounter
        }

        resolve damage
        reset target counters
    }
}
*/

	private selectTargets(attackers?: Unit[], defenders?: Unit[])
	{
		const pairs: [Unit, Unit][] = [];

		if (!attackers || !defenders || attackers.length === 0 || defenders.length === 0)
			return pairs;

		const attackersByRank = attackers.sort((a, b) => a.data!.misc.rank - b.data!.misc.rank);

		const rankAssassination = this.context.ini.data.Combat.rank_assassination;
		const maxRank = this.context.ini.data.Combat.rank_max;

		const getDefendersByIncomingHitsAndRank = (forAssassin: boolean) =>
		{
			const out = [...defenders];
			const getRank = (u: Unit) =>
			{
				if (forAssassin)
					return u.data!.misc.rank >= rankAssassination ? u.data!.misc.rank : maxRank + u.data!.misc.rank;
				return u.data!.misc.rank;
			};
			return out.sort((a, b) =>
			{
				if (a.status.incomingHitCounter === b.status.incomingHitCounter)
					return getRank(a) - getRank(b);
				return a.status.incomingHitCounter - b.status.incomingHitCounter;
			});
		};

		attackersByRank.forEach(attacker =>
		{
			const targets = getDefendersByIncomingHitsAndRank(attacker.data!.isAssassin())
			const target = targets[0];
			target.status.incomingHitCounter++;
			pairs.push([attacker, target]);
		});

		return pairs;
	}

	public run(): void
	{
		this.fightCounter++;

		this.attacker.stack.saveStateSnapshot('beforeCombat');
		this.defender.stack.saveStateSnapshot('beforeCombat');

		let attackType: AttackType = AttackType.Space;

		switch (this.attacker.stance.value)
		{
			case 'Defensive':
			case 'Offensive':
			case 'Neutral':
				attackType = AttackType.Land;
				if (this.attacker.stack.isAirOrNavalOnly() && this.defender.stack.isAirOrNavalOnly())
					attackType = AttackType.NavalRaid;
				break;
			case 'OrbitalBombardment':
				attackType = AttackType.OrbitalBombardment;
				break;
			case 'Landing':
				attackType = AttackType.PTS;
				break;
			case 'Space':
				attackType = AttackType.Space;
				break;
		}

		this.combatHistory.attackType = attackType;

		const weapons = this.context.target.getWeaponsByOrder(attackType);

		let maxCombatRounds = 999;

		if (attackType == AttackType.OrbitalBombardment || attackType == AttackType.PTS || attackType == AttackType.NavalRaid)
			maxCombatRounds = 1;

		for (let round = 0; round < maxCombatRounds; round++)
		{
			let shotsFired = false;
			weapons.forEach(weapon =>
			{
				const attackFilter = (u: Unit) => u.canAttack(weapon.name); // unit has right weapon for the phase
				const cargoCanAttackFilter = (u: Unit) =>
				{
					if (attackType === AttackType.Space)
					{
						if (u.status.inCarrier.value)
							return u.participatesInSpaceCombat(); // only units that can attack in space can attack from carriers
					}
					return !u.status.inCargo.value;
				}; // unit is not in cargo
				const attackerCanParticipateInAttackType = (u: Unit) =>
				{
					switch (attackType)
					{
						case AttackType.Space:
							return u.canExistInSpace() || u.status.inCarrier.value && u.participatesInSpaceCombat();;
						case AttackType.PTS: // anything with right weapon can return PTS fire
						case AttackType.OrbitalBombardment: // anything with right weapon can bombard
							return true;
						default:
							return !u.willSurrenderInLandCombat();
					}
				}; // unit can participate in combat

				const canBeTargetedByWeaponFilter = (u: Unit) => weapon.canTargetMoveType(u.data?.moveType!); // unit can be targeted at all
				const cargoTargetableFilter = (u: Unit) =>
				{
					if (!u.status.inCargo.value)
						return true; // unit is not in cargo
					if (!u.status.inCarrier.value)
						return false; // units in cargo but not in carrier don't get targeted
					if (attackType === AttackType.Space)
						return u.participatesInSpaceCombat(); // units that can attack in space can be targeted in carriers
					if (attackType === AttackType.PTS)
						return u.canBombard(); // units that can bombard can be targeted in PTS combat when they are in carriers
					return false;
				}; // unit is not in cargo
				const attackerCanBeTargetedInAttackType = (u: Unit) =>
				{
					switch (attackType)
					{
						case AttackType.OrbitalBombardment: // only bombarding units can receive return fire during orbital bombardment
							return u.canBombard();
						case AttackType.PTS:
							return true; // all units can receive fire in PTS combat
						case AttackType.Space:
							return u.canExistInSpace() || u.status.inCarrier.value;
						default:
							return true
					}
				};
				const defenderCanBeTargetedInAttackType = (u: Unit) =>
				{
					switch (attackType)
					{
						case AttackType.PTS:
							return u.isPTS(); // only PTS units can receive fire in PTS combat
						case AttackType.Space:
							return u.canExistInSpace() || u.status.inCarrier.value;
						default:
							return true
					}
				};

				const maxPhases = weapon.getPhases();
				for (let phase = 0; phase < maxPhases; phase++)
				{
					const attackers1 = this.attacker.stack.activeUnits()
						.filter(attackFilter)
						.filter(cargoCanAttackFilter)
						.filter(attackerCanParticipateInAttackType)
					;
					const targets1 = this.attacker.stack.activeUnits()
						.filter(canBeTargetedByWeaponFilter)
						.filter(cargoTargetableFilter)
						.filter(attackerCanBeTargetedInAttackType)
					;

					const attackers2 = this.defender.stack.activeUnits()
						.filter(attackFilter)
						.filter(cargoCanAttackFilter)
						.filter(attackerCanParticipateInAttackType)
					;
					const targets2 = this.defender.stack.activeUnits()
						.filter(canBeTargetedByWeaponFilter)
						.filter(cargoTargetableFilter)
						.filter(defenderCanBeTargetedInAttackType)
					;

					const pairs = [...this.selectTargets(attackers1, targets2), ...this.selectTargets(attackers2, targets1)];

					if (pairs.length > 0)
						shotsFired = true;

					const resolve: (() => void)[] = [];
					pairs.forEach(([attacker, defender]) =>
					{
						defender.status.incomingHitCounter = 0;
						resolve.push(...this.runWeaponPhase(weapon, attacker, defender, round, phase));
					});
					const defenders = new Set(pairs.map(([attacker, defender]) => defender));

					for (const defender of defenders.values())
					{
						resolve.push(() =>
						{
							if (defender.status.health.value <= 0)
								this.combatHistory.add(new CombatEventDeath(defender, weapon), round, phase);
						});
					};

					resolve.forEach(r => r());
				}
			});

			if (!shotsFired)
				break; // nobody can shoot, end of combat
		}

		// TODO resolve experience gain

		this.attacker.stack.saveStateSnapshot('afterCombat');
		this.defender.stack.saveStateSnapshot('afterCombat');

		this.onCombatDone();
	}

	private runWeaponPhase(weapon: WeaponType, attacker: Unit, defender: Unit, round: number, phase: number)
	{
		const w = attacker.combatStats!.attacks[weapon.name];
		const accuracy = w.accuracy.value;
		let strength = w.strength.value;

		// adjust damage for attacker's health
		strength = strength * attacker.status.health.value / 100;

		const armor = defender.combatStats!.armor.value;
		const psyDefense = defender.combatStats!.psyDefense.value;
		const agility = defender.combatStats!.agility.value;

		const defense = weapon.name === 'Psy' ? psyDefense : armor;

		// roll accuracy vs agility
		let chance = 0.5 + 0.05 * (accuracy - agility);
		if (chance < 0)
			chance = 0;
		if (chance > 1)
			chance = 1;

		const roll = Math.random();
		const hit = this.useExpectedValues.value ? true : roll <= chance;

		const damageSourceString = `${attacker.type.uiStringForValue}[${attacker.stack}${attacker.stackPos}]: ${weapon.name} c${this.fightCounter} r${round + 1} p${phase + 1}`;

		const resolve: (() => void)[] = [];

		if (!hit)
		{
			this.combatHistory.add(new CombatEventMiss(attacker, defender, weapon, chance, roll), round, phase);
			return resolve;
		}

		// calculate damage to armor ratio
		const damageRatio = targetRatio(strength, defense);
		const damageTable = this.context.damage.getAllDamagesForRatio(damageRatio);
		const averageDamage = chance * damageTable!.reduce((a, b) => a + b, 0) / damageTable!.length;

		// roll for damage
		const damageRoll = Math.floor(Math.random() * damageTable!.length);
		const damageDealt = this.useExpectedValues.value ? averageDamage : damageTable![damageRoll];

		// apply damage to health
		this.combatHistory.add(new CombatEventHit(attacker, defender, weapon, chance, roll, damageDealt, damageRatio), round, phase);
		resolve.push(() =>
		{
			defender.status.health.applyBonus(damageSourceString, -damageDealt, 'runWeaponPhase');

			if (!defender.status.spotted.value)
			{
				// units keep their camouflage bonus throughout combat
				defender.status.spotted.set(true, 'incoming hit', 'runWeaponPhase');
				// defender.combatStats.agility.applyBonus('revealed in combat', this.context.ini.data.Bonuses.agility_hidden_bonus, 'runWeaponPhase');
			}

			if (defender.status.health.value <= 0)
			{
				defender.unitsInCargo.forEach(u =>
				{
					if (u.canExistInSpace())
						return;
					u.status.health.applyBonus(`Carrier ${defender.id} destroyed`, -u.status.health.value, 'runWeaponPhase');
				});
			}
		});

		// TODO: roll for rout

		// TODO: roll for plague

		return resolve;
	}
}

