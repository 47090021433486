import { WeaponStats } from "../data/unit";
import { CombatSim } from "../sim/CombatSim";
import { Unit } from "../sim/Unit";
import { FlagProxy, PlainValueProxy, RecordMultiValue, RecordMultiValueProxy, RecordValueProxy, ValueProxy } from "../sim/Value";
import { createFlagUI, createMultiValueUI, createPlainValueProxyUI, createUIForRecordValue, createValueUI } from "./Value_ui";
import { createBasicCard, createFormCard } from "../app/Utils";
import { addCol, addColAuto, addColX, addLabel, addRow } from "../app/Utils";

export function generateUIForUnitStatus(parentEl: HTMLElement, combatSim: CombatSim, onChanged: (source: string) => void, updateValueDetails: (htmlString: string) => void)
{
	const { form: form1, card: card1 } = createBasicCard(addCol(parentEl), null);
	card1.classList.add('h-100');

	createValueUI(form1, new ValueProxy(() => combatSim.selectedUnit.value.status.health, combatSim.selectedUnit), onChanged, updateValueDetails);
	createValueUI(form1, new ValueProxy(() => combatSim.selectedUnit.value.status.loyalty, combatSim.selectedUnit), onChanged, updateValueDetails);
	createValueUI(form1, new ValueProxy(() => combatSim.selectedUnit.value.status.experience, combatSim.selectedUnit), onChanged, updateValueDetails);

	const cargoRow = addRow(form1);
	const {select: cargoSelect } = createMultiValueUI(cargoRow, new RecordMultiValueProxy(() => combatSim.selectedUnit.value.cargo, combatSim.selectedUnit), onChanged);

	const rebuildCargoSelector = () =>
	{

	}

	combatSim.selectedUnit.onChanged[`generateUIForUnitStatus`] = () =>
	{
		rebuildCargoSelector();
	};

	const { form: form2, card: card2 } = createBasicCard(addCol(parentEl), null);
	card1.classList.add('h-100');
	createFlagUI(form2, new FlagProxy(() => combatSim.selectedUnit.value.status.plague, combatSim.selectedUnit), undefined, onChanged);
	createFlagUI(form2, new FlagProxy(() => combatSim.selectedUnit.value.status.routed, combatSim.selectedUnit), undefined, onChanged);
	createFlagUI(form2, new FlagProxy(() => combatSim.selectedUnit.value.status.spotted, combatSim.selectedUnit), updateValueDetails, onChanged);
	createFlagUI(form2, new FlagProxy(() => combatSim.selectedUnit.value.status.inCargo, combatSim.selectedUnit), updateValueDetails, onChanged);
	createFlagUI(form2, new FlagProxy(() => combatSim.selectedUnit.value.status.inCarrier, combatSim.selectedUnit), updateValueDetails, onChanged);
}

function generateUIForAttack(parentEl: HTMLElement, combatSim: CombatSim, attackName: keyof WeaponStats, onChanged: (source: string) => void, updateValueDetails: (htmlString: string) => void)
{
	const row1 = addRow(parentEl);
	row1.classList.add('justify-content-md-left');

	addLabel(addCol(row1), combatSim.selectedUnit.value.combatStats.attacks[attackName].uiString);
	createValueUI(addCol(row1), new ValueProxy(() => combatSim.selectedUnit.value.combatStats.attacks[attackName].accuracy, combatSim.selectedUnit), onChanged, updateValueDetails, false);
	createValueUI(addCol(row1), new ValueProxy(() => combatSim.selectedUnit.value.combatStats.attacks[attackName].strength, combatSim.selectedUnit), onChanged, updateValueDetails, false);
}

export function generateUIForCombatStats(parentEl: HTMLElement, combatSim: CombatSim, onChanged: (source: string) => void, updateValueDetails: (htmlString: string) => void)
{
	{
		const { form: statsSubCard, card: card1 } = createBasicCard(addCol(parentEl), null);
		card1.classList.add('h-100');

		const row1 = addRow(statsSubCard);
		row1.classList.add('row-cols-1');
		row1.classList.add('justify-content-md-left');
		createValueUI(addCol(row1), new ValueProxy(() => combatSim.selectedUnit.value.combatStats.rank, combatSim.selectedUnit), onChanged, updateValueDetails);
		createValueUI(addCol(row1), new ValueProxy(() => combatSim.selectedUnit.value.combatStats.armor, combatSim.selectedUnit), onChanged, updateValueDetails);
		createValueUI(addCol(row1), new ValueProxy(() => combatSim.selectedUnit.value.combatStats.agility, combatSim.selectedUnit), onChanged, updateValueDetails);
		createValueUI(addCol(row1), new ValueProxy(() => combatSim.selectedUnit.value.combatStats.camo, combatSim.selectedUnit), onChanged, updateValueDetails);
		createValueUI(addCol(row1), new ValueProxy(() => combatSim.selectedUnit.value.combatStats.spot, combatSim.selectedUnit), onChanged, updateValueDetails);
		createValueUI(addCol(row1), new ValueProxy(() => combatSim.selectedUnit.value.combatStats.psyDefense, combatSim.selectedUnit), onChanged, updateValueDetails);
	}

	const { form: attacksSubCard, card: card2 } = createBasicCard(addCol(parentEl), null);
	card2.classList.add('h-100');

	const row2 = addRow(attacksSubCard);
	row2.classList.add('row-cols-1');
	const row3 = addRow(addCol(row2))
	row3.classList.add('justify-content-md-left');
	addCol(row3);
	addCol(row3).textContent = "Acc";
	addCol(row3).textContent = "Str";
	generateUIForAttack(addCol(row2), combatSim, 'Water', onChanged, updateValueDetails);
	generateUIForAttack(addCol(row2), combatSim, 'Air', onChanged, updateValueDetails);
	generateUIForAttack(addCol(row2), combatSim, 'Indirect', onChanged, updateValueDetails);
	generateUIForAttack(addCol(row2), combatSim, 'Direct', onChanged, updateValueDetails);
	generateUIForAttack(addCol(row2), combatSim, 'Close', onChanged, updateValueDetails);
	generateUIForAttack(addCol(row2), combatSim, 'Psy', onChanged, updateValueDetails);
	generateUIForAttack(addCol(row2), combatSim, 'RangedSp', onChanged, updateValueDetails);
	generateUIForAttack(addCol(row2), combatSim, 'DirectSp', onChanged, updateValueDetails);
	generateUIForAttack(addCol(row2), combatSim, 'CloseSp', onChanged, updateValueDetails);
}

export function generateUIForUnitStats(parentEl: HTMLElement, combatSim: CombatSim, onChanged: (source: string) => void)
{
	const { form, header } = createFormCard(parentEl, '', false, null);

	const updatesubCard = (htmlString: string) =>
	{
		if (subCard)
		{
			subCard.innerHTML = htmlString;
		}
	};

	const row0 = addRow(form);
	row0.classList.add('justify-content-md-left');
	row0.classList.add('g-1')
	row0.classList.add('row-cols-3');

	const { form : unitSubCard, card: card1 } = createBasicCard(addCol(row0), null);
	card1.classList.add('h-100');

	const row1 = addRow(unitSubCard);
	row1.classList.add('row-cols-1');

	createUIForRecordValue(addCol(row1), new RecordValueProxy(() => combatSim.selectedUnit.value?.type, combatSim.selectedUnit), onChanged);
	createUIForRecordValue(addCol(row1), new RecordValueProxy(() => combatSim.selectedUnit.value?.sect.type, combatSim.selectedUnit), onChanged);

	createPlainValueProxyUI(addCol(row1), new PlainValueProxy(() => `Move Type: ${combatSim.selectedUnit.value?.data?.moveType ?? ''}`, combatSim.selectedUnit));
	createPlainValueProxyUI(addCol(row1), new PlainValueProxy(() => `Freighter: ${combatSim.selectedUnit.value?.freighter?.uiId ?? ''}`, combatSim.selectedUnit));
	createPlainValueProxyUI(header, new PlainValueProxy(() => `${combatSim.selectedUnit.value?.uiId}`, combatSim.selectedUnit));

	generateUIForUnitStatus(row0, combatSim, onChanged, updatesubCard);
	generateUIForCombatStats(row0, combatSim, onChanged, updatesubCard);

	const { form: subCard, card: card2 } = createBasicCard(addCol(row0), null);
	card2.classList.add('h-100');
}