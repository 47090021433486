import { CombatSim } from "../sim/CombatSim";
import { Unit } from "../sim/Unit";
import { addColAuto, addColMd, addColX, addContainer, addRow, makeIconButton, makeId } from "../app/Utils";
import { createUIForRecordValue } from "./Value_ui";
import { createBasicCard } from "../app/Utils";

let lastSelectedCard: HTMLElement | null = null;

export function generateUIForUnit(parentEl: HTMLElement, unit: Unit, combatSim: CombatSim, onChanged: (source: string)=>void)
{
	const id = makeId();
	const { form, card } = createBasicCard(parentEl, (event) =>
	{
		(event.currentTarget as HTMLElement).classList.add('selected');
		(event.currentTarget as HTMLElement).classList.remove('empty');
		if (lastSelectedCard && lastSelectedCard !== event.currentTarget)
			lastSelectedCard.classList.remove('selected');

		lastSelectedCard = event.currentTarget as HTMLElement;

		combatSim.selectedUnit.set(unit, `generateUIForUnit${id}`);

		if (unit.isEmpty())
		{
			(event.currentTarget as HTMLElement).classList.add('empty');
		}
		else
		{
			(event.currentTarget as HTMLElement).classList.remove('empty');
		}
	});

	const container = addContainer(form);
	const row = addRow(container);

	const stackLabel = document.createElement('label');
	stackLabel.classList.add('form-label');
	stackLabel.classList.add('badge');
	stackLabel.classList.add('me-2');
	stackLabel.textContent = `${unit.stack}${unit.stackPos}`;
	addColAuto(row).appendChild(stackLabel);

	if (unit.isEmpty())
	{
		stackLabel.classList.add('empty-unit');
		stackLabel.classList.add('bg-secondary');
	}
	else
	{
		stackLabel.classList.add('has-unit');
		stackLabel.classList.add('bg-success');
	}

	const copy = makeIconButton(addColAuto(row), 'Copy', 'bi-clipboard', 'secondary', () =>
	{
		const json = JSON.stringify(unit.toSerializableObject(), null, 2);
		navigator.clipboard.writeText(json);
	});
	copy.disabled = true;
	unit.type.onChanged[`generateUIForUnit${id}_states`] = () =>
	{
		if (unit.isEmpty())
		{
			copy.disabled = true;
			stackLabel.classList.add('empty-unit');
			stackLabel.classList.add('bg-secondary');
			stackLabel.classList.remove('has-unit');
			stackLabel.classList.remove('bg-success');
		}
		else
		{
			copy.disabled = false;
			stackLabel.classList.remove('empty-unit');
			stackLabel.classList.remove('bg-secondary');
			stackLabel.classList.add('has-unit');
			stackLabel.classList.add('bg-success');
		}
	};

	const paste = makeIconButton(addColAuto(row), 'Paste', 'bi-clipboard-data', 'secondary', () =>
	{
		navigator.clipboard.readText().then((text) =>
		{
			try
			{
				unit.fromSerializableObject(JSON.parse(text));
				onChanged('unit');
			} catch (error)
			{
				alert(`Failed to paste: ${error}`);
			}
		});
	});

	navigator.clipboard.addEventListener('clipboardchange', (e: Event | ClipboardEvent) =>
	{
		if (e instanceof ClipboardEvent)
		{
			if (e.clipboardData?.types.includes('text/plain'))
				paste.disabled = false;
			else
				paste.disabled = true;
		}
	});

	addColMd(row);

	const clear = makeIconButton(addColAuto(row), 'Clear', 'bi-x', 'danger', () =>
	{
		unit.type.set('-1', `generateUIForUnit${id}_clear`);
		unit.sect.type.set('Ort', `generateUIForUnit${id}_clear`);
		unit.reset('UI', () => true);
		onChanged('unit');
	});

	const { select, label } = createUIForRecordValue(addColX(row, 12), unit.type, onChanged);
	label.classList.add('d-none');

	if (combatSim.selectedUnit.value === unit)
	{
		card.classList.add('selected');
		lastSelectedCard = card;
	}

	combatSim.selectedUnit.onChanged[`generateUIForUnit${id}`] = () =>
	{
		if (combatSim.selectedUnit.value === unit)
		{
			card.classList.add('selected');
			lastSelectedCard = card;
		}
		else
		{
			card.classList.remove('selected');
		}
	}
}