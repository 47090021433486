import { ColumnDefinition, Tabulator } from "tabulator-tables";
import { AgilityModTable } from "../data/agility";
import { TableBase, TableStateStorage, common, genericString } from "./tabulator";

export class AgilityTableUI extends TableBase
{
	constructor(elementSelector: string, private agility: AgilityModTable, stateStorage: TableStateStorage)
	{
		super(elementSelector, "Agility", stateStorage);
		this.init();
	}

	protected buildColumns(): ColumnDefinition[]
	{
		const out = [
			{ title: "Move Type", field: "moveType", frozen: true, ...common, ...genericString },
		];

		this.agility.terrainTypes.forEach((terrainType) =>
		{
			out.push({ title: terrainType, field: terrainType, ...common, ...genericString });
		});

		return out;
	}

	protected buildData()
	{
		return this.agility.moveTypesInOrder.map((moveType) =>
		{
			const mods = this.agility.getAllModsForMoveType(moveType);

			return {
				moveType: moveType,
				...mods,
			};
		});
	}
}