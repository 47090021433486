import { KnownTech } from "../sim/KnownTech";
import { Flag } from "../sim/Value";
import { createFlagUI } from "./Value_ui";
import { createFormCard } from "../app/Utils";

export function generateUIForKnownTech(parentEl: HTMLElement, knownTech: KnownTech, onChanged: (source: string)=>void)
{
	const { form, card } = createFormCard(parentEl, 'Known Tech', false, null);
	card.classList.add('h-100');

	for (const key in knownTech)
	{
		if (knownTech[key as keyof KnownTech] instanceof Flag)
		{
			createFlagUI(form, knownTech[key as keyof KnownTech] as Flag, undefined, onChanged);
		}
	}
}