import * as dagreD3 from 'dagre-d3';
import { NodeGraph } from './NodeGraph';
import { LocalStorageCache } from './cache';
import * as d3 from 'd3';
import { NodeData } from './buildgraph';
import { PopOverContent } from './PopOverContent';
import { Archives } from './archives';

export class GraphRenderer
{
	private svgG?: d3.Selection<SVGGElement, any, any, any>;
	public get svg() { return this.svgG!; }
	private onZoomCallback?: () => void;
	private controller: bootstrap.Modal;
	private nodeGraph?: NodeGraph;
	private node?: NodeData;
	private graphElementId = "selected-modal-graph";
	private infoElementId = "selected-info";
	private modal: HTMLElement | null;
	private cacheRoot: string = "graph-renderer";

	constructor(private modalId: string, private archives: Archives, private modName: string, private cache?: LocalStorageCache)
	{
		this.clear();

		this.controller = new bootstrap.Modal(`#${this.modalId}`, { keyboard: true})
		this.modal = document.getElementById(this.modalId);
	}

	private makeCacheKey()
	{
		if (!this.node)
			return `${this.cacheRoot}/${this.modName}/__main`;

		return `${this.cacheRoot}/${this.modName}/${this.node.id}`;
	}

	public selectNode(nodeId?: string)
	{
		this.node = nodeId === undefined ? undefined : this.nodeGraph?.get(nodeId);
		this.updateInfo();
	}

	public async render(nodeGraph: NodeGraph, selectedNodeId?: string)
	{
		this.nodeGraph = nodeGraph;

		if (selectedNodeId)
			this.node = nodeGraph.get(selectedNodeId);

		return new Promise<void>((resolve, reject) =>
		{
			const render = () =>
			{
				this.clear();
				this.renderGraph();
				this.updateInfo();
				this.showSpinner("selected-modal-spinner", false);
				resolve();
			};

			if (this.modal!.classList.contains("show"))
			{
				render();
			}
			else
			{
				this.modal!.addEventListener('shown.bs.modal', (e) =>
				{
					render();
				}, { once: true });

				this.showSpinner("selected-modal-spinner", true);
				this.controller.show();
			}
		});
	}

	private async showSpinner(elementId: string, state: boolean)
	{
		const spinner = document.getElementById(elementId)!;
		if (state)
			spinner.classList.remove("d-none");
		else
			spinner.classList.add("d-none");
	}

	private clear()
	{
		d3
			.select<SVGSVGElement, any>(`#${this.graphElementId}`)
			.on("zoom", null)
			.selectAll("g")
			.remove()
		;
	}

	public onZoom(callback: () => void)
	{
		this.onZoomCallback = callback;
	}

	private updateInfo()
	{
		const info = document.getElementById(this.infoElementId)!;
		if (!this.node)
		{
			info.innerHTML = "";
			return;
		}

		const content = new PopOverContent(this.nodeGraph!, this.node!, this.archives, this.modName);

		info.innerHTML = content.content();

		const modalHeader = this.modal!.querySelector('.modal-title');
		if (modalHeader)
			modalHeader.innerHTML = content.title();
	}

	private renderGraph()
	{
		const buildDom = () =>
		{
			const svg = d3.select<SVGElement, any>(`#${this.graphElementId}`)
				.append('g')
				.attr('transform', null)
			;

			const render = new dagreD3.render();
			render(svg as any, this.nodeGraph!.g as any);

			// Arrowhead marker
			svg.append('defs')
				.append('marker')
				.attr('id', `arrowhead_${this.graphElementId}`)
				.attr('viewBox', '-0 -5 10 10')
				.attr('refX', 13)
				.attr('refY', 0)
				.attr('orient', 'auto')
				.attr('markerWidth', 13)
				.attr('markerHeight', 13)
				.attr('xoverflow', 'visible')
				.append('svg:path')
				.attr('d', 'M 0,-5 L 10 ,0 L 0,5')
				.attr('fill', '#999')
				.style('stroke', 'none')
			;

			svg.selectAll('.edgePath').attr("style", null);
			svg.selectAll('g.edgePath path.path').attr('marker-end', `url(#arrowhead_${this.graphElementId})`);

			return {str: svg.node()!.innerHTML, hydrated: svg};
		};

		const hydrateContent = (content: string) =>
		{
			const svg = d3.select<SVGElement, any>(`#${this.graphElementId}`).append('g');
			svg.node()!.innerHTML = content;

			this.nodeGraph?.nodes.forEach((node) =>
			{
				if (node.class.includes("node "))
				{
					const element = svg.select<SVGGElement>(`#${node.id}`);
					element.attr("class", node.class);
				}
			});

			return svg;
		};

		if (this.cache)
			this.svgG = this.cache.get(this.makeCacheKey(), buildDom, hydrateContent);
		else
		{
			let { hydrated } = buildDom();
			this.svgG = hydrated;
		}

		const zoom = d3
			.zoom<SVGGElement, NodeData>()
			.scaleExtent([0.05, 4])
			.on('zoom', (event) =>
			{
				this.svgG!.attr("transform", event.transform);
				if (this.onZoomCallback)
					this.onZoomCallback();
			})
		;

		zoom.transform(this.svgG, d3.zoomIdentity);

		d3.select<SVGElement, any>(`#${this.graphElementId}`).call(zoom as any).on('dblclick.zoom', null); // Disable double-click zooming

		this.centerGraph(this.graphElementId, zoom);

		this.svgG.selectAll('g.node')
			.attr('cursor', 'pointer')
			.attr('style', '')
		;
	}

	private centerGraph(parentId: string, zoom: d3.ZoomBehavior<SVGGElement, NodeData>)
	{
		const viewport = document.getElementById(`${parentId}`)!;
		const viewportWidth = viewport.clientWidth;
		const viewportHeight = viewport.clientHeight;

		const graph = this.svgG!.select("g.output");

		const bbox = (graph.node() as SVGGElement).getBoundingClientRect();

		const translateX = (viewportWidth / 2) - (bbox.x + bbox.width / 2);
		const translateY = (viewportHeight / 2) - (bbox.y + bbox.height / 2);
		const scale = 1;

		const transform = d3.zoomIdentity
			.translate(translateX, translateY)
			.scale(scale);
		d3
			.select<SVGGElement, NodeData>(`#${parentId}`)
			.call(zoom.transform, transform)
		;
	}
}