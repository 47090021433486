import { ColumnDefinition, Tabulator } from "tabulator-tables";
import { AttackDamageTable } from "../data/damage";
import { TableBase, TableStateStorage, common, genericString, numerical } from "./tabulator";

export class DamageTableUI extends TableBase
{
	constructor(elementSelector: string, private damage: AttackDamageTable, stateStorage: TableStateStorage)
	{
		super(elementSelector, "Damage", stateStorage);
		this.init();
	}

	private makeTitleField(title: string)
	{
		return `ratio_${title.replace(/-/g, "_")}`;
	}

	buildColumns(): ColumnDefinition[]
	{
		const out: ColumnDefinition[] = [{ title: "Level", field: "level", ...common, ...numerical }];

		this.damage.ratioTitles.forEach((title) =>
		{
			out.push({ title, field: this.makeTitleField(title), ...common, ...numerical });
		});

		return out;
	}

	buildData()
	{
		let out: {}[] = [];
		for (let l = 0; l < this.damage.maxLevel; l++)
		{
			const row: {[key: string]: number} = {};
			this.damage.getAllDamagesForLevel(l)?.map((d, i) =>
			{
				row[this.makeTitleField(this.damage.ratioTitles[i])] = d;
			});
			out.push({ level: l, ...row });
		}

		return out;
	}
}