import { NodeData } from './buildgraph';
import { Costs, WeaponStats } from '../data/unit';
import { Archives } from './archives';
import { Resources } from '../data/basicresourcecost';
import { NodeGraph } from './NodeGraph';

export class PopOverContent
{
	private parents: NodeData[] = [];
	private totalCost: number = 0;

	private techs: NodeData[] = [];
	private cities: NodeData[] = [];
	private units: NodeData[] = [];

	constructor(private graph: NodeGraph, private node: NodeData, private archives: Archives, private modName: string)
	{
		bootstrap.Tooltip.Default.allowList['*'].push(/^data-.*/i);
		bootstrap.Tooltip.Default.allowList.button = [];

		this.parents = graph.getAllParents(node).parentIds.map((nodeId) => graph.get(nodeId)!);

		this.parents.forEach((parent) =>
		{
			if (parent && parent.techData)
				this.totalCost += parent.techData.cost;
		});
		if (node.techData)
			this.totalCost += node.techData.cost;

		this.techs = graph.removeDuplicates(this.parents.filter((n) => n.techData));
		this.cities = graph.removeDuplicates(this.parents.filter((n) => n.cityData));
		this.units = graph.removeDuplicates(this.parents.filter((n) => n.unitData));
	}

	public title()
	{
		const closeButton = '';//`<button type="button" class="btn-close" aria-label="Close"></button>`;
		if (this.node.techData)
			return `<div>${this.node.techData.name} (${this.modName})${closeButton}</div>`;

		if (this.node.cityData)
			return `<div>${this.node.cityData.name} (${this.modName})${closeButton}</div>`;

		if (this.node.unitData)
			return `<div>${this.node.unitData.name} (${this.modName})${closeButton}</div>`;

		return `<div>${this.node.name} (${this.modName})${closeButton}</div>`;
	}

	private techListLine()
	{
		if (this.techs.length === 0)
			return '';
		return `<div><strong>Required Tech:</strong> ${this.techs.map((n) => n.techData!.name).join(', ')}</div>`;
	}

	private citiesListLine()
	{
		if (this.cities.length === 0)
			return '';
		return `<div><strong>City:</strong> ${this.cities.map((n) => n.cityData!.name).join(', ')}</div>`;
	}

	private inputUnitsLine()
	{
		if (this.units.length === 0)
			return '';
		return `<div><strong>Input Units:</strong> ${this.units.map((n) => n.unitData!.name).join(', ')}</div>`;
	}

	private techDescriptionLine()
	{
		if (!this.node.techData)
			return '';
		return `<div>${this.node.techData.description}</div><hr>`;
	}

	private makeAccordionItem(title: string, content: string, id: string, parentId: string, cssClass: string)
	{
		return `
			<div class="accordion-item ${cssClass}">
				<div class="accordion-header">
					<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#${id}" aria-expanded="false" aria-controls="${id}">
						${title}
					</button>
				</div>
				<div class="accordion-collapse collapse show" id="${id}">
					<div class="card card-body">
						${content}
					</div>
				</div>
			</div>
		`;
	}

	private getTechItemClass()
	{
		return '';
	}

	private getBuildItemClass()
	{
		if (this.node.unitData)
			return '';
		return 'd-none';
	}

	private getUnitItemClass()
	{
		if (this.node.unitData)
			return '';
		return 'd-none';
	}

	public content()
	{
		return `
			<div class='popovercontent'>
				<div class="accordion accordion-flush" id="popover_accordion">
					${this.makeAccordionItem("Total research cost: " + this.totalCost, this.techDescriptionLine() + this.techListLine(), "collapseTech", "popover_accordion", this.getTechItemClass())}
					${this.archiveLine()}
					${this.makeAccordionItem("Build needs", this.getOwnerLine() + this.citiesListLine() + this.inputUnitsLine() + this.unitCost(), "collapseCosts", "popover_accordion", this.getBuildItemClass())}
					${this.makeAccordionItem("Unit", this.unitKind() + this.unitMoveType() + this.unitFunction() + this.unitStats(), "collapseUnit", "popover_accordion", this.getUnitItemClass())}
				</div>
			</div>
		`;
	}

	private archiveLine()
	{
		if (!this.node.techData)
			return '';

		const content = this.makeAccordionItem("Archive", "", "collapseArchive", "popover_accordion", "");

		this.archives.getChapter(this.node.techData.volume, this.node.techData.chapter).then((chapter) =>
		{
			document.querySelector("#collapseArchive>.card-body")!.innerHTML = chapter;
		});

		return content;
	}

	private resourceCostLine(name: keyof Resources)
	{
		const cost = this.node.unitData!.costs.resources[name];
		const basicCost = this.node.unitData!.costs.basicResources.r100[name];
		const totalCost = cost + this.units.reduce((acc: any, n) => acc + n.unitData!.costs.resources[name], 0);
		const totalBasicCost = basicCost + this.units.reduce((acc: any, n) => acc + n.unitData!.costs.basicResources.r100[name], 0);
		if (totalCost === 0 && totalBasicCost === 0)
			return ``;

		if (totalBasicCost === 0)
			return `
					<div class='col-4'>${name}</div>
					<div class='col-2 text-center'>${cost}</div>
					<div class='col-2 text-center'}'>${totalCost}</div>
					<div class="w-100"></div>
				`;

		return `
					<div class='col-4'>${name}</div>
					<div class='col-2 text-center'>${cost}</div>
					<div class='col-2 text-center'>${totalCost}</div>
					<div class='col-2 text-center'>${basicCost}</div>
					<div class='col-2 text-center'>${totalBasicCost}</div>
					<div class="w-100"></div>
				`;
	}

	private moneyCostLine(name: keyof Costs, total: boolean)
	{
		const cost = this.node.unitData!.costs[name];
		if (total)
		{
			const totalCost = cost + this.units.reduce((acc: any, n) => acc + n.unitData!.costs[name], 0);
			if (totalCost === 0)
				return ``;

			return `
						<div class='col-4'>${name}</div>
						<div class='col-2 text-center'>${cost}</div>
						<div class='col-2 text-center'>${totalCost}</div>
						<div class="w-100"></div>
					`;
		}

		if (cost === 0)
			return ``;

		return `
					<div class='col-4'>${name}</div>
					<div class='col-2 text-center'>${cost}</div>
					<div class="w-100"></div>
				`;
	}

	private timeCostLine()
	{
		const cost = this.node.unitData!.buildNeeds.turns2Bld;
		const totalCost = cost + this.units.reduce((acc: any, n) => acc + n.unitData!.buildNeeds.turns2Bld, 0);

		if (totalCost === 0)
			return ``;
		return `
					<div class='col-4'>Turns</div>
					<div class='col-2 text-center'>${cost}</div>
					<div class='col-2 text-center'>${totalCost}</div>
					<div class="w-100"></div>
		`;
	}

	private foodCostLine()
	{
		if (this.node.unitData!.misc.eat === 0)
			return ``;
		return `
					<div class='col-4'>Eats</div>
					<div class='col-2 text-center'>${this.node.unitData!.misc.eat === 1 ? "Y" : "N"}</div>
					<div class="w-100"></div>
		`;
	}

	private unitCost()
	{
		if (!this.node.unitData)
			return '';

		return `
			<div class='container m-0'>
				<hr>
				<div class='row'>
					<div class='col-4 text-bg-info border'></div>
					<div class='col-4 text-center text-bg-info border'>Cost</div>
					<div class='col-4 text-center text-bg-info border'>Basic resources</div>
					<div class="w-100"></div>
					<div class='col-4 text-bg-info border'>Resource</div>
					<div class='col-2 text-center text-bg-info border'></div>
					<div class='col-2 text-center text-bg-info border'>Total</div>
					<div class='col-2 text-center text-bg-info border'></div>
					<div class='col-2 text-center text-bg-info border'>Total</div>
					<div class="w-100"></div>
					${this.timeCostLine()}
					${this.moneyCostLine('Credits', true)}
					${this.resourceCostLine('food')}
					${this.resourceCostLine('energy')}
					${this.resourceCostLine('metal')}
					${this.resourceCostLine('trace')}
					${this.resourceCostLine('exotica')}
					${this.resourceCostLine('gems')}
					${this.resourceCostLine('chemicals')}
					${this.resourceCostLine('biochems')}
					${this.resourceCostLine('electronics')}
					${this.resourceCostLine('ceramsteel')}
					${this.resourceCostLine('wetware')}
					${this.resourceCostLine('monopols')}
					${this.resourceCostLine('singularities')}
					<div class='col-12 text-bg-info border'>Upkeep</div>
					<div class="w-100"></div>
					${this.moneyCostLine('CreditsPerTurn', false)}
					${this.foodCostLine()}
				</div>
			</div>
		`;
	}

	private unitKind()
	{
		if (!this.node.unitData || this.node.unitData.unitType.length === 0)
			return '';
		return `<div><strong>Type:</strong> ${this.node.unitData.unitType.join(", ")}</div>`;
	}

	private getCombatEntry(entry: keyof WeaponStats)
	{
		if (this.node.unitData!.combatStats[entry].Acc === 0 && this.node.unitData!.combatStats[entry].Dmg === 0)
			return '';
		return `<div class="col-3 text-end">${entry}:</div><div class="col-2">${this.node.unitData!.combatStats[entry].Acc} / ${this.node.unitData!.combatStats[entry].Dmg}</div>`;
	};

	private unitMoveType()
	{
		if (!this.node.unitData || this.node.unitData.moveType.length === 0)
			return '';
		return `<div><strong>Move Type:</strong> ${this.node.unitData.moveType}</div>`;
	}

	private getOwnerLine()
	{
		if (!this.node.unitData || this.node.unitData.buildNeeds.owner === -1)
			return '';
		return `<div><strong>Owner:</strong> ${this.node.unitData.getOwnerUIString()}</div>`;
	}

	private unitStats()
	{
		if (!this.node.unitData)
			return '';
		return `
			<div class="container">
				<div class="row text-nowrap g-1">
					<div class="col-2 text-end">MP:</div><div class="col-1">${this.node.unitData.move}</div>
					<div class="col-2 text-end">Spot:</div><div class="col-1">${this.node.unitData.spot}</div>
					<div class="col-2 text-end">Camo:</div><div class="col-1">${this.node.unitData.camo}</div>
					<div class="col-2 text-end">AG:</div><div class="col-1">${this.node.unitData.ag}</div>
					<div class="col-2 text-end">Armor:</div><div class="col-1">${this.node.unitData.armor}</div>
					<div class="col-2 text-end">PsyDef:</div><div class="col-1">${this.node.unitData.psyDef}</div>
					<div class="col-2 text-end">Cargo:</div><div class="col-1">${this.node.unitData.cargo}</div>
					<div class="col-2 text-end">Portable:</div><div class="col-1">${this.node.unitData.canBCargo === 1 ? "Y" : "N"}</div>
					<div class="col-2 text-end">Disband:</div><div class="col-1">${this.node.unitData.misc.disband === 1 ? "Y" : "N"}</div>
					<div class="col-2 text-end">Rank:</div><div class="col-1">${this.node.unitData.misc.rank}</div>
				</div>
				<hr>
				<div class="row text-nowrap g-1">
					<div class="col">
						<div class="row">
							<div class="col-3">&nbsp;</div><div class="col-2">Acc / Dmg</div><div class="w-100"></div>
							${this.getCombatEntry('Water')}
							${this.getCombatEntry('Indirect')}
							${this.getCombatEntry('Air')}
							${this.getCombatEntry('Direct')}
							${this.getCombatEntry('Close')}
							${this.getCombatEntry('Psy')}
							${this.getCombatEntry('RangedSp')}
							${this.getCombatEntry('DirectSp')}
							${this.getCombatEntry('CloseSp')}
						</div>
					</div>
				</div>
			</div>
		`;
	}

	private unitFunction()
	{
		if (!this.node.unitData || this.node.unitData.unitFunction.length === 0)
			return '';

		return `
			<div><strong>Function:</strong> ${this.node.unitData.unitFunction.join(", ")}</div><hr>
		`;
	}
}
