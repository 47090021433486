import { Player } from "../sim/Player";
import { createFlagUI } from "./Value_ui";
import { createFormCard } from "../app/Utils";
import { generateUIForKnownTech } from "./KnownTech_ui";
import { addCol, addRow, makeIconButton } from "../app/Utils";
import { CombatSim } from "../sim/CombatSim";

export function generateUIForPlayer(parentEl: HTMLElement, player: Player, combatSim: CombatSim, onChanged: (source: string)=>void)
{
	const { form, card, header } = createFormCard(parentEl, 'Player', false, null);

	const swap = makeIconButton(header, 'Swap', 'bi-arrow-left-right', 'secondary', () =>
	{
		const a = combatSim.attacker.player.toSerializableObject();
		const d = combatSim.defender.player.toSerializableObject();

		combatSim.attacker.player.fromSerializableObject(d);
		combatSim.defender.player.fromSerializableObject(a);

		onChanged('player');
	});
	swap.classList.add('ms-2');

	const row = addRow(form);
	row.classList.add('row-cols-2');

	generateUIForKnownTech(addCol(row), player.knownTech, onChanged);

	const { form: traits, card: traitsCard } = createFormCard(addCol(row), 'Traits', false, null);
	traitsCard.classList.add('h-100');
	createFlagUI(traits, player.traits.battlemaster, undefined, onChanged);
	createFlagUI(traits, player.traits.inept_military, undefined, onChanged);
	createFlagUI(traits, player.traits.symbiot, undefined, onChanged);
	createFlagUI(traits, player.traits.vau, undefined, onChanged);
}