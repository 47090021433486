import { ResourceProduction, Resources } from "./basicresourcecost";
import { CityTable } from "./city";
import { DataPoint } from "../app/resourcemanager";
import { TechTable } from "./tech";
import { MoveTypes } from "./agility";

export type CombatStat = {
	Acc: number;
	Dmg: number;
};

export type WeaponStats = {
	Water: CombatStat;
	Indirect: CombatStat;
	Air: CombatStat;
	Direct: CombatStat;
	Close: CombatStat;
	Psy: CombatStat;
	RangedSp: CombatStat;
	DirectSp: CombatStat;
	CloseSp: CombatStat;
}

export const SpaceWeapons: (keyof WeaponStats)[] = ['Psy', 'RangedSp', 'DirectSp', 'CloseSp'];

export type BuildNeeds = {
	unit: number;
	tLvl: number;
	bldgs: number;
	owner: number;
	turns2Bld: number;
};

export type BasicResources = {
	r100: Resources,
	r90: Resources,
	r80: Resources,
	r70: Resources,
	r60: Resources,
	r50: Resources,
	r40: Resources,
}

export type Costs = {
	CreditsPerTurn: number;
	Credits: number;
	resources: Resources;
	basicResources: BasicResources;
};

export function getOwnerUIString(owner: number): string
{
	const houses = ['Li Halan', 'Hazat', 'Decados', 'Hawkwood', 'al-Malik'];
	switch(owner) {
		case -1: return 'Any owner';
		case 0:
		case 1:
		case 2:
		case 3:
		case 4: return houses[owner];
		case 5: return 'League';
		case 6: return 'The Church';
		case 7: return 'Symbiot';
		case 8: return 'Vau';
		case 9: return 'Imperial Guard';
		case 10: return 'Imperial Fleet';
		case 11: return 'Stigmata Garrison';
		case 12: return 'Imperial Eye';
		case 13: return 'Rebels';
		case 22: return 'The League or Church';
		case 33: return 'Ministries (excluding the Imperial Guard)';
		case 44: return 'Any Ministry (includes Imperial Guard)';
		case 55: return 'Any House';
		case 77: return 'Non-House Humans (The League, Church, or Rebels)';
		case 88: return 'All Alien (Symbiot or Vau)';
		case 99: return 'All Human (all but Symbiot / Vau)';
		default:
			if (owner >= 1000 && owner <= 1004) {
				return `${houses[owner - 1000]} (Human-Controlled only)`;
			} else if (owner == 1055) {
				return 'Human-Controlled Houses only';
			} else if (owner >= 2000 && owner <= 2004) {
				return `${houses[owner - 2000]} (AI-Controlled only)`;
			} else if (owner == 2055) {
				return 'AI-Controlled Houses only';
			} else {
				return 'Invalid ID';
			}
	}
}

export function getIndividualOwners(owner: number): string[]
{
	const houses = ['Li Halan', 'Hazat', 'Decados', 'Hawkwood', 'al-Malik'];
	const ministries = ['Imperial Guard', 'Imperial Fleet', 'Stigmata Garrison', 'Imperial Eye'];
	const aliens = ['Symbiot', 'Vau'];
	const nonHouseHumans = ['The League', 'The Church', 'Rebels'];

	switch(owner) {
		case -1: return [...houses, ...houses.map(house => `${house} AI`), ...ministries, ...nonHouseHumans, ...aliens];
		case 0:
		case 1:
		case 2:
		case 3:
		case 4: return [houses[owner], `${houses[owner]} AI`];
		case 5: return ['League'];
		case 6: return ['The Church'];
		case 7: return ['Symbiot'];
		case 8: return ['Vau'];
		case 9: return ['Imperial Guard'];
		case 10: return ['Imperial Fleet'];
		case 11: return ['Stigmata Garrison'];
		case 12: return ['Imperial Eye'];
		case 13: return ['Rebels'];
		case 22: return ['The League', 'The Church'];
		case 33: return ['Imperial Fleet', 'Stigmata Garrison', 'Imperial Eye'];
		case 44: return [...ministries];
		case 55: return [...houses, ...houses.map(house => `${house} AI`)];
		case 77: return [...nonHouseHumans];
		case 88: return [...aliens];
		case 99: return [...houses, ...houses.map(house => `${house} AI`), ...nonHouseHumans];
		default:
			if (owner >= 1000 && owner <= 1004) {
				return [houses[owner - 1000]]; // Human-Controlled only
			} else if (owner == 1055) {
				return [...houses]; // Human-Controlled Houses only
			} else if (owner >= 2000 && owner <= 2004) {
				return [`${houses[owner - 2000]} AI`]; // AI-Controlled only
			} else if (owner == 2055) {
				return houses.map(house => `${house} AI`); // AI-Controlled Houses only
			} else {
				return ['Invalid ID'];
			}
	}
}

export class UnitDataRow
{
	raw: string = "";
	id: number = 0;
	graph_id: string = "";
    name: string = "";
	abbrev: string = "";
    groupId: number = 0;
    techLevel: number = 0;
    moveType: MoveTypes = "foot";
    kind: number = 0;
	move: number = 0;
    spot: number = 0;
    camo: number = 0;
    ag: number = 0;
    armor: number = 0;
    psyDef: number = 0;
    combatStats: WeaponStats = {} as WeaponStats;
    cargo: number = 0;
    canBCargo: number = 0;
    unitFunction: UnitAbility[]	= [];
    costs: Costs = {} as Costs;
    buildNeeds: BuildNeeds = {} as BuildNeeds;
    techRequirements: number[] = [];
	totalTechCost: number = 0;
	totalBuildingsTechCost: number = 0;
    misc: {
        tax: number;
        flock: number;
        range: number;
        eat: number;
        rank: number;
        roP: number;
        disband: number;
    } = {} as any;
    buildable: boolean = false;
    unitType: SpecialUnitType[]	= [];
	inputUnits: UnitDataRow[] = [];

    constructor()
	{}

	public getOwnerUIString(): string
	{
		return getOwnerUIString(this.buildNeeds.owner);
	}

	public getIndividualOwners()
	{
		return getIndividualOwners(this.buildNeeds.owner);
	}

	public isNoble(): boolean
	{
		return this.unitType.indexOf('Noble') !== -1 || this.unitFunction.indexOf('👑 Noble') !== -1;
	}

	public isOfficer(): boolean
	{
		return this.unitType.indexOf('Officer') !== -1 || this.unitFunction.indexOf('🎖️ Officer') !== -1;
	}

	public isAssassin(): boolean
	{
		return this.unitFunction.indexOf('🥷 Assassin') !== -1;
	}

	public participatesInCombat(): boolean
	{
		return this.unitFunction.indexOf('⚔️ Participates in combat') !== -1;
	}

	public usesDrugs(): boolean
	{
		return this.unitFunction.indexOf('💉 Combat Drugs') !== -1;
	}

	public fightsToTheDeath(): boolean
	{
		return this.unitFunction.indexOf('💀 Never Rout') !== -1;
	}

	public diesAtTheEndOfCombat(): boolean
	{
		return this.unitFunction.indexOf('💣 Self-Destruct') !== -1;
	}

	public isSpaceCarrier(): boolean
	{
		return this.unitType.indexOf('Space Carrier') !== -1;
	}

	public hasWeapon(name: keyof WeaponStats): boolean
	{
		return name in this.combatStats && this.combatStats[name].Dmg !== 0;
	}

	public getWeapons(): string[]
	{
		return Object.keys(this.combatStats).filter(name => this.hasWeapon(name as keyof WeaponStats));
	}
}

type UnitAbility = string;

function parseUnitAbilities(value: number): UnitAbility[]
{
	let abilities: UnitAbility[] = [];

	if ((value & 1) === 0) abilities.push('⚔️ Participates in combat');
	if ((value & 1) !== 0) abilities.push('☮︎ Non-Combat');
	if ((value & 2) !== 0) abilities.push('🥷 Assassin');
	if ((value & 4) !== 0) abilities.push('🕶 Byzantium Attack');
	if ((value & 8) !== 0) abilities.push('👑 Noble');
	if ((value & 16) !== 0) abilities.push('🎖️ Officer');
	if ((value & 32) !== 0) abilities.push('👷🏻‍♂️ Engineer');
	if ((value & 64) !== 0) abilities.push('👩🏼‍⚖️ Clergy');
	if ((value & 128) !== 0) abilities.push('🕵🏻 Inquisitor');
	if ((value & 256) !== 0) abilities.push('👾 Symbiot Nester');
	if ((value & 512) !== 0) abilities.push('👽 Vau Worker');
	if ((value & 1024) !== 0) abilities.push('⚚ Sceptor');
	if ((value & 2048) !== 0) abilities.push('🏺 Relic');
	if ((value & 4096) !== 0) abilities.push('🛬 Naval Carrier');
	if ((value & 8192) !== 0) abilities.push('🛳️ Naval Transport');
	if ((value & 16384) !== 0) abilities.push('🚀 Space Carrier');
	if ((value & 32768) !== 0) abilities.push('🛄 Freighter');
	if ((value & 65536) !== 0) abilities.push('🛅 Bulk Hauler');
	if ((value & 131072) !== 0) abilities.push('🚁 VTOL Unit');
	if ((value & 262144) !== 0) abilities.push('⛽ VTOL Refueling');
	if ((value & 524288) !== 0) abilities.push('🛢️ Infinite Fuel');
	if ((value & 1048576) !== 0) abilities.push('🪂 Survives Fuel Loss');
	if ((value & 2097152) !== 0) abilities.push('🛷 Can Be Towed');
	if ((value & 4194304) !== 0) abilities.push('🚜 Can Tow Others');
	if ((value & 8388608) !== 0) abilities.push('💉 Combat Drugs');
	if ((value & 16777216) !== 0) abilities.push('⛑ Medic');
	if ((value & 33554432) !== 0) abilities.push('❤️‍🔥 Never Rebels');
	if ((value & 67108864) !== 0) abilities.push('💀 Never Rout');
	if ((value & 134217728) !== 0) abilities.push('🧬 Plague Immune');
	if ((value & 268435456) !== 0) abilities.push('🧪 Tactical Plague');
	if ((value & 536870912) !== 0) abilities.push('☣️ Plague Bomb');
	if ((value & 1073741824) !== 0) abilities.push('💣 Self-Destruct');

	return abilities;
}

export function getAllUnitAbilities(): string[]
{
    let abilities: string[] = [];

    abilities.push('⚔️ Participates in combat');
    abilities.push('☮︎ Non-Combat');
    abilities.push('🥷 Assassin');
    abilities.push('🕶 Byzantium Attack');
    abilities.push('👑 Noble');
    abilities.push('🎖️ Officer');
    abilities.push('👷🏻‍♂️ Engineer');
    abilities.push('👩🏼‍⚖️ Clergy');
    abilities.push('🕵🏻 Inquisitor');
    abilities.push('👾 Symbiot Nester');
    abilities.push('👽 Vau Worker');
    abilities.push('⚚ Sceptor');
    abilities.push('🏺 Relic');
    abilities.push('🛬 Naval Carrier');
    abilities.push('🛳️ Naval Transport');
    abilities.push('🚀 Space Carrier');
    abilities.push('🛄 Freighter');
    abilities.push('🛅 Bulk Hauler');
    abilities.push('🚁 VTOL Unit');
    abilities.push('⛽ VTOL Refueling');
    abilities.push('🛢️ Infinite Fuel');
    abilities.push('🪂 Survives Fuel Loss');
    abilities.push('🛷 Can Be Towed');
    abilities.push('🚜 Can Tow Others');
    abilities.push('💉 Combat Drugs');
    abilities.push('⛑ Medic');
    abilities.push('❤️‍🔥 Never Rebels');
    abilities.push('💀 Never Rout');
    abilities.push('🧬 Plague Immune');
    abilities.push('🧪 Tactical Plague');
    abilities.push('☣️ Plague Bomb');
    abilities.push('💣 Self-Destruct');

    return abilities;
}

export type SpecialUnitType =
    | "Normal"
    | "Stealth Ship"
    | "Space Carrier"
    | "Submarine"
    | "Naval Carrier"
    | "Sceptor"
    | "Relic"
    | "Vau Worker"
    | "Symbiot Nester"
    | "Noble"
    | "Warlock"
    | "Inquisitor"
    | "Dervish"
    | "Spy"
    | "Officer"
    | "Clergy"
    | "Engineer"
    | "Plague Artillery"
    | "Infantry"
    | "Militia"
    | "Anti-Air Gun"
    | "Anti-Tank Gun"
    | "Artillery"
    | "Plague Bomb"
    | "Cargo Pod";

const specialUnits: { [key: number]: SpecialUnitType | undefined } = {
	11: "Stealth Ship",
	//-1: "Normal",
	16: "Space Carrier",
	23: "Submarine",
	26: "Naval Carrier",
	28: "Sceptor",
	29: "Relic",
	37: "Vau Worker",
	44: "Symbiot Nester",
	45: "Noble",
	46: "Warlock",
	47: "Inquisitor",
	48: "Dervish",
	49: "Spy",
	51: "Officer",
	52: "Clergy",
	53: "Engineer",
	71: "Plague Artillery",
	83: "Infantry",
	84: "Militia",
	85: "Anti-Air Gun",
	86: "Anti-Tank Gun",
	87: "Artillery",
	88: "Plague Bomb",
	91: "Cargo Pod"
};

function getUnitType(groupId: number, kind: number): SpecialUnitType[]
{
	const s = new Set([specialUnits[groupId], specialUnits[kind]])
	return [...s].filter(x => x !== undefined) as SpecialUnitType[];
}

export class UnitTable
{
	readonly rows: UnitDataRow[] = [];

	constructor(tableString: DataPoint, private prodTable: ResourceProduction, private techTable: TechTable, private cityTable: CityTable)
	{
		const lines = tableString.data.trim().split('\n').filter(line => !line.startsWith('//'));

		let groupId = 0;
		let techLevel = 0;
		lines.forEach(line =>
		{
			const groupMatch = line.match(/{(\d+)/);
			if (groupMatch)
			{
				groupId = parseInt(groupMatch[1]);
				techLevel = 0;
				return;
			}

			const rowMatches = line.match(/"name"\s+"([^"]+)"\s+.+"abbrev"\s+"([^"]+)"\s+.+stats"\s+"\s?([^"]+)"/);
			if (rowMatches)
			{
				const rowValues = rowMatches.map(value => value.trim());

				const raw = line;
				const id = this.rows.length;

				this.rows.push(this.parseRow(raw, id, rowValues, groupId, techLevel));

				techLevel++;
			}
		});

		this.rows.forEach(row =>
		{
			row.inputUnits = this.getInputUnits(row);

			const allUnitTech = row.techRequirements.concat(row.inputUnits.map(unit => unit.techRequirements).flat());
			const allBuildings = [cityTable.get(row.buildNeeds.bldgs)].concat(row.inputUnits.map(unit => cityTable.get(unit.buildNeeds.bldgs))).flat();
			const allBuildingTechIds = allBuildings.filter((b) => b).map(building => building!.tech).flat();

			const allDepTechs = this.techTable.getAllTechDependencies(allUnitTech).map(tech => this.techTable.get(tech)).filter(tech => tech).map(tech => tech!);
			const allBuildingTech = this.techTable.getAllTechDependencies(allBuildingTechIds).map(tech => this.techTable.get(tech)).filter(tech => tech).map(tech => tech!);
			row.totalTechCost = allDepTechs.reduce((acc, tech) => acc + tech.cost, 0);
			row.totalBuildingsTechCost = allBuildingTech.reduce((acc, tech) => acc + tech.cost, 0);
		});
	}

	public getUnitById(id: number): UnitDataRow | undefined
	{
		return this.rows[id];
	}

	public getUnitByGroupAndTechLevel(groupId: number, techLevel: number): UnitDataRow[]
	{
		return this.rows.filter(row => row.groupId === groupId && row.techLevel === techLevel);
	}

	public getInputUnits(row: UnitDataRow): UnitDataRow[]
	{
		const inputUnit = this.getUnitByGroupAndTechLevel(row.buildNeeds.unit, row.buildNeeds.tLvl);

		if (inputUnit.length === 0)
			return [];

		return [inputUnit[0], ...this.getInputUnits(inputUnit[0])];
	}

	public getTotalBasicResources(row: UnitDataRow): BasicResources
	{
		let res: BasicResources = { ...row.costs.basicResources };

		row.inputUnits.forEach(inputUnit =>
		{
			res.r100.add(inputUnit.costs.basicResources.r100);
			res.r90.add(inputUnit.costs.basicResources.r90);
			res.r80.add(inputUnit.costs.basicResources.r80);
			res.r70.add(inputUnit.costs.basicResources.r70);
			res.r60.add(inputUnit.costs.basicResources.r60);
			res.r50.add(inputUnit.costs.basicResources.r50);
			res.r40.add(inputUnit.costs.basicResources.r40);
		});

		return res;
	}

	public static playerBuildable(buildNeeds: BuildNeeds): boolean
	{
		if (buildNeeds.bldgs === -1)
			return false;
		return (buildNeeds.owner >= -1 && buildNeeds.owner <= 4) || (buildNeeds.owner >= 1000 && buildNeeds.owner <= 1004) // any player or house
			|| (buildNeeds.owner >= 9 && buildNeeds.owner <= 12) // any ministry
			|| buildNeeds.owner === 33 // any ministry but imperial guard
			|| buildNeeds.owner === 44 // any ministry
			|| buildNeeds.owner === 55 // any house
			|| buildNeeds.owner === 99 || buildNeeds.owner === 1055 // any human
		;
	}

	private parseRow(raw: string, id: number, rowValues: string[], groupId: number, techLevel: number): UnitDataRow
	{
		const statsValues = rowValues[3].split(/\s+/);

		const buildNeeds = {
			unit: parseInt(statsValues[13 + 18 + 13]),
			tLvl: parseInt(statsValues[14 + 18 + 13]),
			bldgs: parseInt(statsValues[15 + 18 + 13]),
			owner: parseInt(statsValues[16 + 18 + 13]),
			turns2Bld: parseInt(statsValues[17 + 18 + 13]),
		};

		const resources = new Resources({
			food: parseInt(statsValues[13 + 18]),
			energy: parseInt(statsValues[14 + 18]),
			metal: parseInt(statsValues[15 + 18]),
			trace: parseInt(statsValues[16 + 18]),
			exotica: parseInt(statsValues[17 + 18]),
			chemicals: parseInt(statsValues[18 + 18]),
			biochems: parseInt(statsValues[19 + 18]),
			electronics: parseInt(statsValues[20 + 18]),
			ceramsteel: parseInt(statsValues[21 + 18]),
			wetware: parseInt(statsValues[22 + 18]),
			monopols: parseInt(statsValues[23 + 18]),
			gems: parseInt(statsValues[24 + 18]),
			singularities: parseInt(statsValues[25 + 18]),
		});

		const costs: Costs = {
			CreditsPerTurn: parseInt(statsValues[11 + 18]),
			Credits: parseInt(statsValues[12 + 18]),
			resources,
			basicResources: {
				r100: this.prodTable.recalculateResources(resources, 1),
				r90: this.prodTable.recalculateResources(resources, 0.9),
				r80: this.prodTable.recalculateResources(resources, 0.8),
				r70: this.prodTable.recalculateResources(resources, 0.7),
				r60: this.prodTable.recalculateResources(resources, 0.6),
				r50: this.prodTable.recalculateResources(resources, 0.5),
				r40: this.prodTable.recalculateResources(resources, 0.4),
			},
		};

		const combatStats: WeaponStats = {
			Water: { Acc: parseInt(statsValues[8 + 0]), Dmg: parseInt(statsValues[9 + 0]) },
			Indirect: { Acc: parseInt(statsValues[8 + 2]), Dmg: parseInt(statsValues[9 + 2]) },
			Air: { Acc: parseInt(statsValues[8 + 4]), Dmg: parseInt(statsValues[9 + 4]) },
			Direct: { Acc: parseInt(statsValues[8 + 6]), Dmg: parseInt(statsValues[9 + 6]) },
			Close: { Acc: parseInt(statsValues[8 + 8]), Dmg: parseInt(statsValues[9 + 8]) },
			Psy: { Acc: parseInt(statsValues[8 + 10]), Dmg: parseInt(statsValues[9 + 10]) },
			RangedSp: { Acc: parseInt(statsValues[8 + 12]), Dmg: parseInt(statsValues[9 + 12]) },
			DirectSp: { Acc: parseInt(statsValues[8 + 14]), Dmg: parseInt(statsValues[9 + 14]) },
			CloseSp: { Acc: parseInt(statsValues[8 + 16]), Dmg: parseInt(statsValues[9 + 16]) },
		};

		let row = new UnitDataRow();

		row.id = id;
		row.graph_id = `unit_${id}`;
		row.raw = raw;
		row.groupId = groupId;
		row.techLevel = techLevel;
		row.costs = costs;
		row.buildNeeds = buildNeeds;
		row.combatStats = combatStats;
		row.kind = parseInt(statsValues[1]);
		row.name = rowValues[1];
		row.abbrev = rowValues[2];
		row.moveType = statsValues[0] as MoveTypes;
		row.move = parseInt(statsValues[2]);
		row.spot = parseInt(statsValues[3]);
		row.camo = parseInt(statsValues[4]);
		row.ag = parseInt(statsValues[5]);
		row.armor = parseInt(statsValues[6]);
		row.psyDef = parseInt(statsValues[7]);
		row.cargo = parseInt(statsValues[8 + 18]);
		row.canBCargo = parseInt(statsValues[9 + 18]);
		row.unitFunction = parseUnitAbilities(parseInt(statsValues[10 + 18]));
		row.techRequirements = statsValues.slice(18 + 18 + 13, 18 + 18 + 13 + 10).map(val => parseInt(val));
		row.misc = {
			tax: parseInt(statsValues[18 + 18 + 13 + 10]),
			flock: parseInt(statsValues[19 + 18 + 13 + 10]),
			range: parseInt(statsValues[20 + 18 + 13 + 10]),
			eat: parseInt(statsValues[21 + 18 + 13 + 10]),
			rank: parseInt(statsValues[22 + 18 + 13 + 10]),
			roP: parseInt(statsValues[23 + 18 + 13 + 10]),
			disband: parseInt(statsValues[24 + 18 + 13 + 10]),
		};
		row.buildable = UnitTable.playerBuildable(buildNeeds);
		row.unitType = getUnitType(groupId, row.kind);

		return row;
	}
};