import { UnitTable, UnitDataRow } from "../data/unit";
import { CityDataRow, CityTable } from "../data/city";
import { TechDataRow, TechTable } from "../data/tech";
import { ResourceProduction } from "../data/basicresourcecost";
import { Data, DataFiles, DataPoint } from "./resourcemanager";
import { SectTable } from "../data/sect";
import { TargetTable } from "../data/target";
import { AgilityModTable } from "../data/agility";
import { AttackDamageTable } from "../data/damage";

import { EfsIni } from "../data/ini";
import { ResourceTable } from "../data/res";
import { Tables } from ".";
import { TechState, TechTracker } from "./TechTracker";

export type NodeData =
{
	id: string;
	name: string;
	parents: string[];
	parentLinks: string[];
	children: string[];
	childrenLinks: string[];
	class: string;
	shape: string;
	selected?: boolean;
	techDependencies: string[];
	cityDependencies?: string[];
	unitDependencies?: string[];
	totalCost?: number;
	cluster?: string;
	superCluster?: string;
	unitData?: UnitDataRow;
	cityData?: CityDataRow;
	techData?: TechDataRow;
	techState?: () => TechState;
};

export type Link =
{
	source: string;
	target: string;
	id: string;
}

export class GraphBuilder
{
	private _graph: Map<string, NodeData> = new Map<string, NodeData>();
	public get graph()
	{
		return this._graph;
	}

	constructor(private tables: Tables, private tracker: TechTracker)
	{}

	public async build()
	{
		this.buildGraph();
	}

	private buildGraph()
	{
		this.tables.tech.rows.forEach((row, index: number) =>
		{
			const id = row.graph_id;

			if (row.name !== "Not Used")
			{
				const state = this.tracker.getState(row.id);
				this.graph.set(id, {
					id,
					shape: "rect",
					class: getClass(row) + " " + getTechTrackerStateClass(state),
					name: getTechName(row),
					techDependencies: row.dependencies.map(dep => this.tables.tech.get(dep)?.graph_id).filter(id => id).map(id => id!),
					parents: [],
					children: [],
					parentLinks: [],
					childrenLinks: [],
					cluster: row.cluster,
					superCluster: "tech",
					techData: row,
					techState: () => this.tracker.getState(row.id),
				});
			}
		});

		this.tables.unit.rows.forEach((row, index: number) =>
		{
			const id = row.graph_id;

			let unitIsDependency = false;
			let unitDependencies: number[] = [];
			this.tables.unit.rows.forEach((r, i: number) =>
			{
				if (i === index)
					return;
				if (row.buildNeeds.unit === r.groupId && row.buildNeeds.tLvl === r.techLevel)
					unitDependencies.push(i);
				if (r.buildNeeds.unit === row.groupId && r.buildNeeds.tLvl === row.techLevel)
					unitIsDependency = true;
			});

			if (!row.buildable && !unitIsDependency)
				return;

			this.graph.set(id, {
				id,
				shape: "ellipse",
				class: getUnitClass(row),
				parents: [],
				children: [],
				parentLinks: [],
				childrenLinks: [],
				name: getUnitName(row),
				techDependencies: row.techRequirements.map(dep => this.tables.tech.get(dep)?.graph_id).filter(id => id).map(id => id!),
				cityDependencies: [this.tables.city.get(row.buildNeeds.bldgs)?.graph_id!].filter(id => id),
				unitDependencies: unitDependencies.map(id => this.tables.unit.getUnitById(id)?.graph_id!).filter(id => id).map(id => id!),
				cluster: row.moveType,
				superCluster: "unit",
				unitData: row,
			});
		});

		this.tables.city.rows.forEach((row) =>
		{
			const id = row.graph_id;

			this.graph.set(id, {
				id,
				shape: "diamond",
				class: getCityClass(row),
				parents: [],
				children: [],
				parentLinks: [],
				childrenLinks: [],
				name: getCityName(row),
				techDependencies: [this.tables.tech.get(row.tech)?.graph_id!].filter(id => id),
				cluster: "city",
				superCluster: undefined,
				cityData: row,
			});
		});
	}
}

export function getTechTrackerStateClass(state: TechState): string
{
	let out: string = '';
	if (state.known)
		out += ' known';
	else
	{
		if (state.target)
			out += ' target';
	}

	if (state.inProgress)
		out += ' inprogress';

	if (state.proscribed)
		out += ' proscribed';

	return out;
}

export function getClass(node: TechDataRow): string
{
	let out = "technode";
	if (node.root)
		out += ' rootnode';
	if (node.hidden)
		out += ' hidden';

	return out;
}

function getTechName(row: TechDataRow)
{
	return `${row.name}\n${row.cost}`;
}

export function makeNodesAndLinks(graph: Map<string, NodeData>): [ NodeData[], Link[] ]
{
	const links: Link[] = [];

	graph.forEach(node =>
	{
		node.techDependencies.forEach(connectedNodeId =>
		{
			const id = connectedNodeId;
			if (graph.has(id))
			{
				const connectedNode = graph.get(id);
				connectedNode?.children.push(node.id);
				node.parents.push(id);
				links.push({ source: id, target: node.id, id: `${id}_${node.id}` });
				node.parentLinks.push(links.slice(-1)[0].id);
				connectedNode?.childrenLinks.push(links.slice(-1)[0].id);
			//	console.log(`${node.id} -> ${connectedNodeId}`);
			}
		});
		node.cityDependencies?.forEach(connectedNodeId =>
		{
			const id = connectedNodeId;
			if (graph.has(id))
			{
				const connectedNode = graph.get(id);
				connectedNode?.children.push(node.id);
				node.parents.push(id);
				links.push({ source: id, target: node.id, id: `${id}_${node.id}` });
				node.parentLinks.push(links.slice(-1)[0].id);
				connectedNode?.childrenLinks.push(links.slice(-1)[0].id);
			//	console.log(`${node.id} -> ${connectedNodeId}`);
			}
		});
		node.unitDependencies?.forEach(connectedNodeId =>
		{
			const id = connectedNodeId;
			if (graph.has(id))
			{
				const connectedNode = graph.get(id);
				connectedNode?.children.push(node.id);
				node.parents.push(id);
				links.push({ source: id, target: node.id, id: `${id}_${node.id}` });
				node.parentLinks.push(links.slice(-1)[0].id);
				connectedNode?.childrenLinks.push(links.slice(-1)[0].id);
			//	console.log(`${node.id} -> ${connectedNodeId}`);
			}
		});
	});

	const nodes = Array.from(graph.values());

	return [ nodes, links ];
}

function getUnitClass(node: UnitDataRow): string
{
	const housesClasses = ['lihalan', 'hazat', 'decados', 'hawkwood', 'almalik'];
	let out = "unitnode";
	switch (node.buildNeeds.owner)
	{
	 	case 0:
	 	case 1:
		case 2:
		case 3:
		case 4:
			out += ` ${housesClasses[node.buildNeeds.owner]}`;
			break;
		case 1000:
		case 1001:
		case 1002:
		case 1003:
		case 1004:
			out += ` ${housesClasses[node.buildNeeds.owner - 1000]}`;
			break;
	}
	return out;
}

function getUnitName(row: UnitDataRow): string
{
	return row.name;
}

function getCityClass(row: CityDataRow): string
{
	let out = "citynode";

	return out;
}

function getCityName(row: CityDataRow): string
{
	return row.name;
}
