import { RelicsTable } from "../data/relic";
import { TableBase, TableStateStorage, bool, common, genericString, numerical } from "./tabulator";
import { ColumnDefinition } from "tabulator-tables";

export class RelicsTableUI extends TableBase
{
	constructor(elementSelector: string, private relics: RelicsTable, stateStorage: TableStateStorage)
	{
		super(elementSelector, "Relics", stateStorage);
		this.init();
	}

	protected buildData(): any[]
	{
		return this.relics.rows.map(relic =>
		{
			return {
				id: relic.id,
				scope: relic.scope,
				bonus: relic.bonusValue,
				name: relic.name,
				description: relic.description,
				type: relic.type,
			};
		});
	}

	protected buildColumns(): ColumnDefinition[]
	{
		const out: ColumnDefinition[] =
		[
			{title: 'Id', field: 'id', frozen: true, ...common },
			{title: 'Name', field: 'name', frozen: true, ...genericString },
			{
				title: 'Scope',
				field: 'scope',
				headerFilter: "list",
				headerFilterParams: { valuesLookup:true, clearable: true },
				headerFilterPlaceholder: "All",
				...common
			},
			{title: 'Bonus', field: 'bonus', ...numerical, ...common },
			{title: 'Description', field: 'description', ...genericString },
			{title: 'Type', field: 'type', ...genericString },
		];

		return out;
	}
}
