import { AgilityModTable } from "../data/agility";
import { CityTable } from "../data/city";
import { AttackDamageTable } from "../data/damage";
import { SectNames, SectTable } from "../data/sect";
import { TargetTable } from "../data/target";
import { TechTable } from "../data/tech";
import { UnitTable } from "../data/unit";
import { Traits } from "./Traits";
import { Stack } from "./Stack";
import { Bonus } from "./Bonus";
import { EfsIni } from "../data/ini";
import { RelicsTable } from "../data/relic";

export class ModData
{
	readonly unitTypes: Record<string, string> = {};

	readonly sectTypes: Record<SectNames, string> = {
		Ort: 'Orthodox',
		SAe: 'Sanctuary Aeon (Amaltheans)',
		Esk: 'Eskatonic',
		Inc: 'Incarnates',
		Ave: 'Avestites',
		Bro: 'Brother Battle',
		Men: 'Mendicant Monks (Hesychasts)',
		Sym: 'Symbiots',
		Vau: 'Vau',
	}

	constructor(
		readonly modId: string,
		readonly ini: EfsIni,
		readonly unit: UnitTable,
		readonly tech: TechTable,
		readonly sect: SectTable,
		readonly city: CityTable,
		readonly target: TargetTable,
		readonly agility: AgilityModTable,
		readonly damage: AttackDamageTable,
		readonly relics: RelicsTable,
	)
	{
		this.unitTypes = this.unit.rows.reduce((acc, row) =>
		{
			if (row.participatesInCombat())
				acc[row.id] = row.name;
			return acc;
		}, {} as Record<string, string>);

		this.relics.getCombatRelics().forEach((relic) =>
		{
			this.unitTypes[`r_${relic.id}`] = `${relic.type} Relic`;
		});

		this.unitTypes["-1"] = "Empty";
	}

	public makeNobleBonus(stack: Stack, traits: Traits): Bonus
	{
		return new Bonus(
			'Noble',
			(status, source) =>
			{
				status.loyalty.applyBonus(source, this.ini.data.Bonuses.loyalty_noble_bonus, 'Noble');

				return status;
			},
			(stats, source) =>
			{
				if (traits.battlemaster.value)
				{
					if (2 < stats.armor.value * traits.battlemaster_armor_bonus.value / 100)
						stats.armor.applyBonusPercent(source, traits.battlemaster_armor_bonus.value, 'Noble');

					else
						stats.armor.applyBonus(source, 2, 'Noble');
				}

				stats.agility.applyBonus(source, this.ini.data.Bonuses.agility_noble_bonus, 'Noble');

				Object.values(stats.attacks).forEach((attack) =>
				{
					if (attack.strength.value === 0)
						return;

					attack.accuracy.applyBonus(source, this.ini.data.Bonuses.accuracy_noble_bonus, 'Noble');
				});

				return stats;
			}
		);
	}

	public makeOfficerBonus(stack: Stack): Bonus
	{
		return new Bonus(
			'Officer',
			(status, source) =>
			{
				if (this.ini.data.Bonuses.loyalty_bonus_cumulative || !stack.hasNoble())
					status.loyalty.applyBonus(source, this.ini.data.Bonuses.loyalty_officer_bonus, 'Officer');
				return status;
			},
			(stats, source) =>
			{
				if (this.ini.data.Bonuses.agility_bonus_cumulative || !stack.hasNoble())
					stats.agility.applyBonus(source, this.ini.data.Bonuses.agility_officer_bonus, 'Officer');

				if (this.ini.data.Bonuses.accuracy_bonus_cumulative || !stack.hasNoble())
				{
					Object.values(stats.attacks).forEach((attack) =>
					{
						if (attack.strength.value === 0)
							return;
						attack.accuracy.applyBonus(source, this.ini.data.Bonuses.accuracy_officer_bonus, 'Officer');
					});
				}

				return stats;
			}
		);
	}
}
