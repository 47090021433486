import { CombatStats } from "./CombatStats";
import { UnitStatus } from "./UnitStatus";


export class Bonus
{
	constructor(readonly source: string, private _applyToStatus: (status: UnitStatus, source: string) => UnitStatus, private _applyToStats: (stats: CombatStats, source: string) => CombatStats)
	{
	}

	public applyToStatus(status: UnitStatus): UnitStatus
	{
		return this._applyToStatus(status, this.source);
	}

	public applyToStats(stats: CombatStats): CombatStats
	{
		return this._applyToStats(stats, this.source);
	}
}
