import { DataPoint } from "../app/resourcemanager";

export type TerrainType = "ocean" | "grass" | "arid grass" | "desert" | "ice" | "tundra" | "mountain" | "hill" | "tree" | "road" | "delta" | "river";
export type MoveTypes = "foot" | "wheel" | "tread" | "air" | "naval" | "space" | "hover" | "jump" | "crawler" | "lander" | "mech" | "gate" | "underwater";

type MoveTypeTable = { [key in MoveTypes]?: { [key in TerrainType]?: number } };

export class AgilityModTable
{
	readonly moveTypesInOrder: MoveTypes[] = ['foot', 'wheel', 'tread', 'air', 'naval', 'space', 'hover', 'jump', 'crawler', 'lander', 'mech', 'gate', 'underwater'];
	readonly terrainTypes: TerrainType[] = [];
	private moveTypeTable: MoveTypeTable = {};

	constructor(tableData: DataPoint)
	{
		this.parseTableData(tableData.data);
	}

	private parseTableData(tableData: string): void
	{
		const lines = tableData.trim().split('\n').filter(line => !line.startsWith('//'));

		lines.forEach((line, rowIndex) =>
		{
			const rowMatches = line.trim().match(/\"([^"]*)\"\s*\"(.*)/);
			if (!rowMatches || rowMatches.length < 3)
				return;

			const terrain = rowMatches[1].trim().toLowerCase() as TerrainType;
			const mods = rowMatches[2].trim().split(/\s+/);

			this.terrainTypes.push(terrain);

			const agilityMods = mods.map(mod => parseInt(mod));
			this.moveTypesInOrder.forEach((moveType, moveTypeIndex) =>
			{
				if (!this.moveTypeTable[moveType])
				{
					this.moveTypeTable[moveType] = {};
				}
				this.moveTypeTable[moveType]![terrain] = agilityMods[moveTypeIndex];
			});
		});
	}

	getForMoveTypeAndTerrain(moveType: MoveTypes | undefined, terrain: TerrainType): number | undefined
	{
		if (moveType === undefined)
			return undefined;

		const mods = this.moveTypeTable[moveType];
		if (mods && mods[terrain])
		{
			return mods[terrain];
		}
		return undefined;
	}

	getAllModsForMoveType(moveType: MoveTypes): { [key in TerrainType]?: number } | undefined
	{
		return this.moveTypeTable[moveType];
	}
}