import { CityDataRow } from "../data/city";
import { ModData } from "./ModData";
import { Bonus } from "./Bonus";
import { Flag, RecordValue } from "./Value";
import { TerrainType } from "../data/agility";
import { StanceType } from "./Side";

export const TileTypeValues : Record<TerrainType, string | undefined> =
{
	ocean: "Ocean",
	grass: "Grass",
	"arid grass": "Arid Grass",
	desert: "Desert",
	ice: "Ice",
	tundra: "Tundra",
	mountain: "Mountain",
	hill: "Hill",
	tree: "Tree",
	road: undefined,
	delta: undefined,
	river: undefined,
}

export type SerializableTile =
{
	baseTileType: string;
	reliefTileType: string;
	treesTileType: string;
	riversTileType: string;
	roadsTileType: string;
	cityType: string;
}

// You already have River, Road and Delta as separate entities. You may merge river and delta (you can choose N\A, river or delta)
// Similarly, Mountains and Hills are mutually exclusive. You can also have no hill or mountain
// There can be trees or no tres

type BaseTerrainType = 'ocean' | 'grass' | 'arid grass' | 'desert' | 'ice';
type ReliefTerrainType = 'mountain' | 'hill' | 'none';
type TreesTerrainType = 'tundra' | 'tree' | 'none';
type RiversTerrainType = 'delta' | 'river' | 'none';
type RoadsTerrainType = 'road' | 'none';

export const BaseTileTypeValues : Record<BaseTerrainType, string | undefined> =
{
	ocean: "Ocean",
	grass: "Grass",
	"arid grass": "Arid Grass",
	desert: "Desert",
	ice: "Ice",
}

export const ReliefTileTypeValues : Record<ReliefTerrainType, string | undefined> =
{
	mountain: "Mountain",
	hill: "Hill",
	none: "None",
}

export const TreesTileTypeValues : Record<TreesTerrainType, string | undefined> =
{
	tundra: "Tundra",
	tree: "Tree",
	none: "None",
}

export const RiversTileTypeValues : Record<RiversTerrainType, string | undefined> =
{
	delta: "Delta",
	river: "River",
	none: "None",
}

export const RoadsTileTypeValues : Record<RoadsTerrainType, string | undefined> =
{
	road: "Road",
	none: "None",
}

export class Tile
{
	private cityTypes: Record<string, string | undefined> = { 'none': 'None' };

	readonly baseTileType = new RecordValue(BaseTileTypeValues, "grass", "Base Terrain");
	readonly reliefTileType = new RecordValue(ReliefTileTypeValues, "none", "Relief Terrain");
	readonly treesTileType = new RecordValue(TreesTileTypeValues, "none", "Trees");
	readonly riversTileType = new RecordValue(RiversTileTypeValues, "none", "Rivers");
	readonly roadsTileType = new RecordValue(RoadsTileTypeValues, "none", "Roads");
	readonly cityType = new RecordValue(this.cityTypes, "none", "City Type");

	readonly layers = [this.baseTileType, this.reliefTileType, this.treesTileType, this.riversTileType, this.roadsTileType].reverse();

	public get city(): CityDataRow | undefined
	{
		return this.context.city.getByName(this.cityType.value);
	}

	public initialize()
	{
		this.baseTileType.set('grass', 'UI');
		this.reliefTileType.set('none', 'UI');
		this.treesTileType.set('none', 'UI');
		this.riversTileType.set('none', 'UI');
		this.roadsTileType.set('none', 'UI');
		this.cityType.set('none', 'UI');
	}

	constructor(readonly context: ModData)
	{
		this.initialize();
		this.cityTypes = context.city.rows.reduce((acc, row) =>
		{
			acc[row.name] = row.name;
			return acc;
		}, this.cityTypes);
	}

	public toSerializableObject(): SerializableTile
	{
		const out: SerializableTile = {
			baseTileType: this.baseTileType.value,
			reliefTileType: this.reliefTileType.value,
			treesTileType: this.treesTileType.value,
			riversTileType: this.riversTileType.value,
			roadsTileType: this.roadsTileType.value,
			cityType: this.cityType.value,
		};
		return out;
	}

	public fromSerializableObject(obj: SerializableTile): void
	{
		if (obj === undefined)
			return;

		if (obj.baseTileType !== undefined)
			this.baseTileType.set(obj.baseTileType as any, 'UI');

		if (obj.reliefTileType !== undefined)
			this.reliefTileType.set(obj.reliefTileType as any, 'UI');

		if (obj.treesTileType !== undefined)
			this.treesTileType.set(obj.treesTileType as any, 'UI');

		if (obj.riversTileType !== undefined)
			this.riversTileType.set(obj.riversTileType as any, 'UI');

		if (obj.roadsTileType !== undefined)
			this.roadsTileType.set(obj.roadsTileType as any, 'UI');

		if (obj.cityType !== undefined)
			this.cityType.set(obj.cityType as any, 'UI');
	}

	private getTerrainBonuses(): Bonus[]
	{
		// TODO: camo bonuses
		const out: Bonus[] = [];

		for (const layer of this.layers)
		{
			if (layer.value === 'none')
				continue;

			out.push(new Bonus(
				layer.uiStringForValue,
				(status) => status,
				(stats, source) =>
				{
					if (layer.value !== 'none')
					{
						const d = this.context.agility.getForMoveTypeAndTerrain(stats.data?.moveType, layer.value);
						if (d !== undefined && d !== 0)
							stats.agility.applyBonus(source, d, 'getTerrainBonuses');
					}
					return stats;
				})
			);

			break; // only top layer counts for agility bonuses
		}

		return out;
	}

	public getAttackBonuses(stance: StanceType): Bonus[]
	{
		if (stance === 'OrbitalBombardment' || stance === 'Space' || stance === 'PTS' || stance === 'Landing')
			return [];

		const out: Bonus[] = [...this.getTerrainBonuses()];
		return out;
	}

	public getDefenseBonuses(stance: StanceType): Bonus[]
	{
		if (stance === 'OrbitalBombardment' || stance === 'Space')
			return [];

		const out: Bonus[] = [...this.getTerrainBonuses()];

		// city armor bonus
		if (this.city)
		{
			out.push(new Bonus(
				`city armor factor`,
				(status) => status,
				(stats, source) =>
				{
					stats.armor.applyBonusPercent(source, (this.city!.uaf - 1) * 100, 'getDefenseBonuses');
					return stats;
				})
			);
		}

		return out;
	}
}
