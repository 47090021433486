import { ModData } from "./ModData";
import { KnownTech, SerializableKnownTech } from "./KnownTech";
import { SerializableTraits as SerializableTraits, Traits } from "./Traits";

export class SerializablePlayer
{
	traits?: SerializableTraits;
	knownTech?: SerializableKnownTech;
}

export class Player
{
	readonly traits: Traits = new Traits();
	readonly knownTech: KnownTech = new KnownTech(this.context);

	constructor(readonly context: ModData)
	{
	}

	public initialize()
	{
		this.traits.initialize();
		this.knownTech.initialize();
	}

	public toSerializableObject(): SerializablePlayer
	{
		const out: SerializablePlayer = {
			traits: this.traits.toSerializableObject(),
			knownTech: this.knownTech.toSerializableObject(),
		};
		return out;
	}

	public fromSerializableObject(obj: SerializablePlayer): void
	{
		if (obj.traits !== undefined)
			this.traits.fromSerializableObject(obj.traits);
		if (obj.knownTech !== undefined)
			this.knownTech.fromSerializableObject(obj.knownTech);
	}
}
