import { SectBonus, SectNames, SectRow } from "../data/sect";
import { WeaponStats } from "../data/unit";
import { Bonus } from "./Bonus";
import { CombatStats } from "./CombatStats";
import { ModData } from "./ModData";
import { UnitStatus } from "./UnitStatus";
import { RecordValue, Value } from "./Value";

export class Sect
{
	readonly type = new RecordValue(this.context.sectTypes, "Ort", "Sect");

	public get sectRow(): SectRow
	{
		return this.context.sect.getSect(this.type.value)!;
	}

	constructor(readonly context: ModData)
	{
	}

	public getPlanetaryBonuses(): Bonus[]
	{
		const out: Bonus[] = [];

		// P3_Plague
		// P3_Experience
		// P3_Routing
		// P3_Loyalty

		return out;
	}

	public getUnitBonuses(): Bonus[]
	{
		const out: Bonus[] = [];

		let sectBonus: SectBonus | undefined;

		out.push(
			this.makeSectStatsBonus(this.sectRow.getBonus('I1_Spotting'), "spot"),
			this.makeSectStatsBonus(this.sectRow.getBonus('I1_Camouflage'), "camo"),
			this.makeSectStatsBonus(this.sectRow.getBonus('I1_Agility'), "agility"),
			this.makeSectStatsBonus(this.sectRow.getBonus('I1_Armor'), "armor"),
			this.makeSectStatsBonus(this.sectRow.getBonus('I1_Psy_Defense'), "psyDefense"),
		);

		// I1_Accuracy
		// I1_Attack_Strength
		out.push(this.makeSectAllAttacksBonus(this.sectRow.getBonus('I1_Accuracy'), this.sectRow.getBonus('I1_Attack_Strength')));

		// I2_Water_Acc
		// I2_Water_AS
		out.push(this.makeSectAttackBonus(this.sectRow.getBonus('I2_Water_Acc'), this.sectRow.getBonus('I2_Water_AS'), "Water"));
		// I2_Indirect_Acc
		// I2_Indirect_AS
		out.push(this.makeSectAttackBonus(this.sectRow.getBonus('I2_Indirect_Acc'), this.sectRow.getBonus('I2_Indirect_AS'), "Indirect"));
		// I2_Air_Acc
		// I2_Air_AS
		out.push(this.makeSectAttackBonus(this.sectRow.getBonus('I2_Air_Acc'), this.sectRow.getBonus('I2_Air_AS'), "Air"));
		// I2_Direct_Acc
		// I2_Direct_AS
		out.push(this.makeSectAttackBonus(this.sectRow.getBonus('I2_Direct_Acc'), this.sectRow.getBonus('I2_Direct_AS'), "Direct"));
		// I2_Close_Acc
		// I2_Close_AS
		out.push(this.makeSectAttackBonus(this.sectRow.getBonus('I2_Close_Acc'), this.sectRow.getBonus('I2_Close_AS'), "Close"));
		// I2_Psy_Acc
		// I2_Psy_AS
		out.push(this.makeSectAttackBonus(this.sectRow.getBonus('I2_Psy_Acc'), this.sectRow.getBonus('I2_Psy_AS'), "Psy"));
		// I2_Ranged_Space_Acc
		// I2_Ranged_Space_AS
		out.push(this.makeSectAttackBonus(this.sectRow.getBonus('I2_Ranged_Space_Acc'), this.sectRow.getBonus('I2_Ranged_Space_AS'), "RangedSp"));
		// I2_Direct_Space_Acc
		// I2_Direct_Space_AS
		out.push(this.makeSectAttackBonus(this.sectRow.getBonus('I2_Direct_Space_Acc'), this.sectRow.getBonus('I2_Direct_Space_AS'), "DirectSp"));
		// I2_Close_Space_Acc
		// I2_Close_Space_AS
		out.push(this.makeSectAttackBonus(this.sectRow.getBonus('I2_Close_Space_Acc'), this.sectRow.getBonus('I2_Close_Space_AS'), "CloseSp"));

		// I3_Plague
		// I3_Experience
		// I3_Routing
		// I3_Loyalty
		out.push(this.makeSectStateBonus(this.sectRow.getBonus('I3_Loyalty'), "loyalty"));

		return out;
	}

	private makeSectStateBonus(sectBonus: SectBonus | undefined, value: keyof UnitStatus)
	{
		return new Bonus(
			this.type.value,
			(status, source) =>
			{
				if (sectBonus?.value !== 0)
				{
					if (sectBonus?.operation === "*")
						(status[value] as Value).applyBonusPercent(`${source} ${sectBonus.id}`, sectBonus?.value, 'makeSectStateBonus');
					else if (sectBonus?.operation === "+")
						(status[value] as Value).applyBonus(`${source} ${sectBonus.id}`, sectBonus?.value, 'makeSectStateBonus');
				}
				return status;
			},
			(stats) => stats
		);
	}

	private makeSectStatsBonus(sectBonus: SectBonus | undefined, value: keyof CombatStats)
	{
		return new Bonus(
			this.type.value,
			(status) => status,
			(stats, source) =>
			{
				if (sectBonus?.value !== 0)
				{
					if (sectBonus?.operation === "*")
						(stats[value] as Value).applyBonusPercent(`${source} ${sectBonus.id}`, sectBonus?.value, 'makeSectStatsBonus');
					else if (sectBonus?.operation === "+")
						(stats[value] as Value).applyBonus(`${source} ${sectBonus.id}`, sectBonus?.value, 'makeSectStatsBonus');
				}
				return stats;
			}
		);
	}

	private makeSectAttackBonus(acc: SectBonus | undefined, as: SectBonus | undefined, attack: keyof WeaponStats)
	{
		return new Bonus(
			this.type.value,
			(status) => status,
			(stats, source) =>
			{
				if (stats.attacks[attack].strength.value === 0)
					return stats;

				if (acc?.value !== 0)
				{
					if (acc?.operation === "*")
						stats.attacks[attack].accuracy.applyBonusPercent(`${source} ${acc.id}`, acc?.value, 'makeSectAttackBonus');
					else if (acc?.operation === "+")
						stats.attacks[attack].accuracy.applyBonus(`${source} ${acc.id}`, acc?.value, 'makeSectAttackBonus');
				}
				if (as?.value !== 0)
				{
					if (as?.operation === "*")
						stats.attacks[attack].strength.applyBonusPercent(`${source} ${as.id}`, as?.value, 'makeSectAttackBonus');
					else if (as?.operation === "+")
						stats.attacks[attack].strength.applyBonus(`${source} ${as.id}`, as?.value, 'makeSectAttackBonus');
				}
				return stats;
			}
		);
	}

	private makeSectAllAttacksBonus(acc: SectBonus | undefined, as: SectBonus | undefined)
	{
		return new Bonus(
			this.type.value,
			(status) => status,
			(stats, source) =>
			{
				if (acc?.value !== 0)
				{
					if (acc?.operation === "*")
						Object.values(stats.attacks).forEach((attack) => { if (attack.strength.value !== 0) attack.accuracy.applyBonusPercent(`${source} ${acc.id}`, acc?.value, 'makeSectAllAttacksBonus') } );
					else if (acc?.operation === "+")
						Object.values(stats.attacks).forEach((attack) => { if (attack.strength.value !== 0) attack.accuracy.applyBonus(`${source} ${acc.id}`, acc?.value, 'makeSectAllAttacksBonus') });
				}
				if (as?.value !== 0)
				{
					if (as?.operation === "*")
						Object.values(stats.attacks).forEach((attack) => { if (attack.strength.value !== 0) attack.strength.applyBonusPercent(`${source} ${as.id}`, as?.value, 'makeSectAllAttacksBonus') });
					else if (as?.operation === "+")
						Object.values(stats.attacks).forEach((attack) => { if (attack.strength.value !== 0) attack.strength.applyBonus(`${source} ${as.id}`, as?.value, 'makeSectAllAttacksBonus') });
				}
				return stats;
			}
		);
	}
}