import { DataPoint } from "../app/resourcemanager";
import { MoveTypes } from "./agility";
import { WeaponStats } from "./unit";

export class WeaponType
{
	private targets: Set<MoveTypes>;
	public order: number = 0;
	public phases: number = 0;

	constructor(readonly name: keyof WeaponStats, targets: MoveTypes[])
	{
		this.targets = new Set(targets);
	}

	canTargetMoveType(moveType: MoveTypes): boolean
	{
		return this.targets.has(moveType);
	}

	getOrder(): number
	{
		return this.order;
	}

	getPhases(): number
	{
		return this.phases;
	}
}

/*
{
	"foot"        " 0  1  0  1  1  1  0  1  1"
	"wheel"       " 0  1  0  1  1  1  1  1  1"
	"tread"       " 0  1  0  1  1  1  1  1  1"
	"air"         " 0  0  1  0  0  1  1  1  1"
	"naval"       " 1  1  0  1  0  1  1  1  1"
	"space"       " 0  0  0  1  0  1  0  1  1"
	"hover"       " 0  1  0  1  1  1  1  1  1"
	"jump"        " 0  0  0  1  0  1  1  1  1"
	"crawler"     " 0  1  0  1  1  1  1  1  1"
	"lander"      " 0  1  0  1  0  1  1  1  1"
	"mech"        " 0  1  0  1  1  1  0  1  1"
	"gate"        " 0  1  0  1  0  1  1  1  1"
	"underwater"  " 1  0  0  0  0  1  0  1  1"
}
{
	"order"       " 6  7  8  0  1  2  3  4  5"
	"phases"      " 4  2  4  3  4  2  2  3  4"
}
*/

// 6 = Ranged Space
// 7 = Direct Space
// 8 = Close Space
// 5 = Psychic
// 2 = Air
// 1 = Indirect
// 0 = Water
// 3 = Direct
// 4 = Close
const OrderMap: {[key: number]: keyof WeaponStats} = { 6: "RangedSp", 7: "DirectSp", 8: "CloseSp", 5: "Psy", 2: "Air", 1: "Indirect", 0: "Water", 3: "Direct", 4: "Close" };

const SpaceWeapons = ['RangedSp', 'DirectSp', 'CloseSp'];
const OrbitalBombardmentWeapons = ['RangedSp'];
const PTSWeapons = ['RangedSp'];

export enum AttackType
{
	Land = 'Land',
	Space = 'Space',
	OrbitalBombardment = 'OrbitalBombardment',
	PTS = 'PTS',
	NavalRaid = 'NavalRaid',
}

export class TargetTable
{
	private weaponMap: { [key: string]: WeaponType } = {};
	private weaponsByOrder: WeaponType[] = [];
	readonly moveTypes: Set<MoveTypes> = new Set();

	constructor(tableData: DataPoint)
	{
		this.parseData(tableData.data);
	}

	private parseData(input: string)
	{
		const lines = input
			.trim()
			.split('\n')
			.map(line => line.trim())
			.filter(line => !line.startsWith('//'))
			.filter(line => !line.startsWith('{'))
			.filter(line => !line.startsWith('}'))
		;

		const typeColumns: (keyof WeaponStats)[] = ['Water', 'Indirect', 'Air', 'Direct', 'Close', 'Psy', 'RangedSp', 'DirectSp', 'CloseSp'];

		let orderLine: number[] = [];
		let phasesLine: number[] = [];
		let targeting: Record<string, MoveTypes[]> = {};

		lines.forEach(line =>
		{
			const rowMatches = line.match(/\"([^"]*)\"\s*\"(.*)\".*/);
			if (!rowMatches || rowMatches.length < 3)
				return;

			const rowHeader = rowMatches[1].trim().toLowerCase();
			const data = rowMatches[2].trim().split(/\s+/).map(Number);

			if (rowHeader === 'order')
			{
				orderLine = data;
				return;
			}

			if (rowHeader === 'phases')
			{
				phasesLine = data;
				return;
			}

			this.moveTypes.add(rowHeader.toLowerCase() as MoveTypes);

			data.forEach((value, index) =>
			{
				if (value === 1)
				{
					if (!targeting[typeColumns[index]])
						targeting[typeColumns[index]] = [];
					targeting[typeColumns[index]].push(rowHeader.toLowerCase() as MoveTypes);
				}
			});
		});

		typeColumns.forEach((name, index) =>
		{
			const targets = targeting[name];

			this.weaponMap[name] = new WeaponType(name as keyof WeaponStats, targets);
		});

		orderLine.forEach((orderKey, index) =>
		{
			const phases = phasesLine[index];
			const name = OrderMap[orderKey];

			if (!this.weaponMap[name])
				return;

			this.weaponMap[name].order = index;
			this.weaponMap[name].phases = phases;
		});

		this.weaponsByOrder = Object.values(this.weaponMap).sort((a, b) => a.getOrder() - b.getOrder());
	}

	public getWeaponType(name: string): WeaponType
	{
		return this.weaponMap[name];
	}

	public getWeaponsByOrder(attackType: AttackType | undefined): WeaponType[]
	{
		if (!attackType)
			return this.weaponsByOrder;
		switch (attackType)
		{
			case AttackType.Space:
				return this.weaponsByOrder;
			case AttackType.OrbitalBombardment:
				return this.weaponsByOrder.filter(w => OrbitalBombardmentWeapons.includes(w.name));
			case AttackType.PTS:
				return this.weaponsByOrder.filter(w => PTSWeapons.includes(w.name));
			default:
				return this.weaponsByOrder.filter(w => !SpaceWeapons.includes(w.name));
		}
	}
}