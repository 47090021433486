import { IIniObject, parse } from "js-ini";
import { DataPoint } from "../app/resourcemanager";

export interface EFSINI extends IIniObject
{
	Combat:
	{
		rank_max: number;
		rank_assassination: number;
		to_hit: number;
		hit_roll: number;
		struct_attack_divisor: number;
		struct_damage_divisor: number;
		struct_max_damage: number;
	};
	Bonuses:
	{
		loyalty_bonus_cumulative: number;
		loyalty_noble_bonus: number;
		loyalty_officer_bonus: number;
		accuracy_bonus_cumulative: number;
		accuracy_noble_bonus: number;
		accuracy_officer_bonus: number;
		agility_bonus_cumulative: number;
		agility_noble_bonus: number;
		agility_officer_bonus: number;
		agility_hidden_bonus: number;
		agility_frozen_tech: number;
		agility_jungle_tech: number;
		agility_barren_tech: number;
	};
};

export class EfsIni
{
	private _data: EFSINI;

	public get data() : EFSINI
	{
		return this._data;
	}

	constructor(data: DataPoint)
	{
		const cleaned = data.data.replace(/;.*$/gm, '');
		this._data = parse(cleaned) as EFSINI;
	}
}