import { Tabulator, PersistenceModule, InteractionModule, SortModule, EditModule, FilterModule, ClipboardModule, FrozenColumnsModule, FormatModule, ResizeColumnsModule, GroupRowsModule, DownloadModule, ExportModule, MenuModule, ColumnDefinition, ColumnComponent } from "tabulator-tables";
import { addCol, addContainer, addRow, createBasicCard, createButton, createFormCard, makeModal } from "../app/Utils";
import { Flag } from "../sim/Value";
import { createFlagUI } from "../sim_ui/Value_ui";

Tabulator.registerModule(PersistenceModule);
Tabulator.registerModule(InteractionModule);
Tabulator.registerModule(SortModule);
Tabulator.registerModule(EditModule);
Tabulator.registerModule(FilterModule);
Tabulator.registerModule(ClipboardModule);
Tabulator.registerModule(FrozenColumnsModule);
Tabulator.registerModule(FormatModule);
Tabulator.registerModule(ResizeColumnsModule);
Tabulator.registerModule(GroupRowsModule);
Tabulator.registerModule(DownloadModule);
Tabulator.registerModule(ExportModule);
Tabulator.registerModule(MenuModule);

export var minMaxFilterEditor = function (cell: any, onRendered: any, success: any, cancel: any, editorParams: any)
{
	var end: any;

	var container = document.createElement("span");

	//create and style inputs
	var start = document.createElement("input");
	start.setAttribute("type", "number");
	start.setAttribute("placeholder", "Min");
	start.style.padding = "1px";
	start.style.width = "100%";
	start.style.boxSizing = "border-box";

	start.value = cell.getValue();

	function buildValues()
	{
		success({
			start: start.value,
			end: end.value,
		});
	}

	function keypress(e: any)
	{
		if (e.keyCode == 13)
		{
			buildValues();
		}

		if (e.keyCode == 27)
		{
			cancel();
		}
	}

	end = start.cloneNode();
	end.setAttribute("placeholder", "Max");

	start.addEventListener("change", buildValues);
	start.addEventListener("blur", buildValues);
	start.addEventListener("keydown", keypress);

	end.addEventListener("change", buildValues);
	end.addEventListener("blur", buildValues);
	end.addEventListener("keydown", keypress);

	container.appendChild(start);
	container.appendChild(document.createElement("br"))
	container.appendChild(end);

	return container;
}

//custom max min filter function
export function minMaxFilterFunction(headerValue: any, rowValue: number, rowData: number, filterParams: any)
{
	//headerValue - the value of the header filter element
	//rowValue - the value of the column in this row
	//rowData - the data for the row being filtered
	//filterParams - params object passed to the headerFilterFuncParams property

	if (headerValue.start != "")
	{
		if (headerValue.end != "")
		{
			return rowValue >= headerValue.start && rowValue <= headerValue.end;
		} else
		{
			return rowValue >= headerValue.start;
		}
	} else
	{
		if (headerValue.end != "")
		{
			return rowValue <= headerValue.end;
		}
	}

	return true; //must return a boolean, true if it passes the filter.
}

export const common: any = {
	resizable: true,
	headerSort: true,
}

export const bool: any = {
	headerFilter: "tickCross",
	headerFilterParams: { clearable: true, tristate: true, indeterminateValue: "All" },
	headerFilterPlaceholder: "All",
	formatter: "tickCross",
	sorter: 'boolean',
	headerVertical: true,
}

export const numerical: any = {
	sorter: 'number',
	headerSortStartingDir: 'desc',
	headerSortTristate: true,
	headerVertical: true,
	headerFilter: minMaxFilterEditor,
	headerFilterFunc: minMaxFilterFunction,
	headerFilterLiveFilter: false,
};

export const genericString: any = {
	headerFilter: "input",
	headerFilterLiveFilter: true,
	headerFilterPlaceholder: "All",
	headerFilterFunc: (headerValue: any, rowValue: any, rowData: any, filterParams: any) => RegExp(headerValue, 'i').test(rowValue),
};

export abstract class TableStateStorage
{
	public abstract set(id: string, type: string, data: any): void;
	public abstract get(id: string, type: string): any;
}

export abstract class TableBase
{
	protected table?: Tabulator;
	protected tableBuilt: boolean = false;

	constructor(protected readonly elementSelector: string, protected readonly tableName: string, protected readonly stateStorage: TableStateStorage)
	{
	}

	protected init()
	{
		const data = this.buildData();
		const headers = this.buildColumns();

		this.table = new Tabulator(this.elementSelector, {
			layout: "fitDataFill",
			columns: headers,
			clipboard: "copy",
			height: "100%",
			maxHeight: "100%",
			placeholder: "Loading...",
			footerElement: this.buildFooter(),
			persistence: {
				sort: true,
				filter: true,
				headerFilter: true,
				columns: ["visible"],
			} as any,
			persistenceMode: "local",
			persistenceID: this.tableName,
		/*	persistenceReaderFunc: (id, type) =>
			{
				return this.stateStorage.get(id, type);
			},
			persistenceWriterFunc: (id, type, data) =>
			{
				this.stateStorage.set(id, type, data);
			},*/
		});

		this.table.on("tableBuilt", () =>
		{
			this.table!.addData(data).then(() =>
			{
				this.tableBuilt = true;
				this.table!.redraw(true);
			});
		});
	}

	public destroy()
	{
		this.table!.destroy();
	}

	public onShown()
	{
		this.table!.redraw(true);
	}

	protected get schema()
	{
		const columns = this.table?.getColumnDefinitions();
		const schema: any = {};
		const addSchema = (column: ColumnDefinition, parentPath: string) =>
		{
			if (column.download === false)
				return;

			const row: any = {
				description: parentPath + column.title,
			};

			if (column.field)
				schema[column.field] = row;

			if (column.columns)
			{
				column.columns.forEach((subColumn) =>
				{
					addSchema(subColumn, parentPath + column.title + " ");
				});
			}
		};

		columns?.forEach((column) =>
		{
			addSchema(column, "");
		});

		return schema;
	}

	protected async downloadForLLM()
	{
		this.table!.download("json", `${this.tableName}_llm.json`, { });
	}

	private buildFooter(): HTMLElement
	{
		const div = document.createElement("div");
		createButton(div, "Copy to clipboard", "bi-clipboard", "secondary", () => this.table!.copyToClipboard());
		createButton(div, "CSV", "bi-filetype-csv", "secondary", () => this.table!.download("csv", `${this.tableName}.csv`));
		createButton(div, "JSON", "bi-filetype-json", "secondary", () => this.table!.download("json", `${this.tableName}.json`));
		createButton(div, "XLSX", "bi-filetype-xls", "secondary", () => this.table!.download("xlsx", `${this.tableName}.xlsx`, { sheetName: this.tableName }));
		createButton(div, "Data For LLM", "bi-file-earmark-arrow-down", "secondary", () => this.downloadForLLM());
		createButton(div, "Reset Filters", "bi-funnel", "danger", () => this.table!.clearFilter(true));

		createButton(div, "Columns", "bi-columns", "secondary", () =>
		{
			const { div: modalDiv, title, body, footer, header, modal } = makeModal("Columns");

			const addColumnUI = (parent: HTMLElement, col: ColumnComponent) =>
			{
				const subColumns = col.getSubColumns();

				const flag = new Flag(col.isVisible(), { label: col.getDefinition().title });
				flag.set(col.isVisible(), 'init', 'col');
				flag.onChanged['col'] = (value) =>
				{
					if (value)
						col.show();
					else
						col.hide();
				};

				if (subColumns.length > 0)
				{
					const { form: groupForm, card: groupCard } = createBasicCard(parent, () => {});
					const row = addRow(addContainer(groupForm));
					row.classList.add('row-cols-1');

					createFlagUI(addCol(row), flag, undefined, () => {});

					const { form: subgroupForm, card: subgroupCard } = createBasicCard(addCol(row), () => {});
					const subrow = addRow(addContainer(subgroupForm));
					subrow.classList.add('row-cols-1');

					const flags = subColumns.map((subCol) => addColumnUI(addCol(subrow), subCol));
					flag.onChanged['subgroups'] = (value) =>
					{
						flags.forEach((flag) => flag.set(value, 'col', 'parent'));

						if (!value)
							subgroupCard.classList.add('d-none');
						else
							subgroupCard.classList.remove('d-none');
					};
				}
				else
					createFlagUI(parent, flag, undefined, () => {});

				return flag;
			};

			modalDiv.addEventListener('shown.bs.modal', () =>
			{
				const { form, card } = createBasicCard(body, (card) => {});
				const container = addContainer(form);
				const row = addRow(container);
				row.classList.add('row-cols-1');

				this.table!.getColumns(true).forEach((col) =>
				{
					addColumnUI(addCol(row), col);
				});
			});

			modal.show();
		});

		return div;
	}

	protected abstract buildData(): any[];
	protected abstract buildColumns(): ColumnDefinition[];
}