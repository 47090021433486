import { SerializableUnit, Unit } from "./Unit";
import { AttackType, WeaponType } from "../data/target";
import { StateSnapshot } from "./UnitStatus";

export abstract class CombatEventBase
{
	public round: number = 0;
	public phase: number = 0;
	readonly defenderState: StateSnapshot;
	constructor(readonly defender: Unit, readonly weapon: WeaponType)
	{
		this.defenderState = { ...defender.status.captureCombatState() };
	}
}

export class CombatEventHit extends CombatEventBase
{
	readonly attackerState: StateSnapshot;
	constructor(readonly attacker: Unit, defender: Unit, weapon: WeaponType, readonly chance: number, readonly roll: number, readonly damage: number, readonly ratio: string)
	{
		super(defender, weapon);
		this.attackerState = { ...attacker.status.captureCombatState() };
	}
}

export class CombatEventMiss extends CombatEventBase
{
	readonly attackerState: StateSnapshot;
	constructor(readonly attacker: Unit, defender: Unit, weapon: WeaponType, readonly chance: number, readonly roll: number)
	{
		super(defender, weapon);
		this.attackerState = { ...attacker.status.captureCombatState() };
	}
}

export class CombatEventDeath extends CombatEventBase
{
	constructor(defender: Unit, weapon: WeaponType)
	{
		super(defender, weapon);
	}
}

export class CombatEventRout extends CombatEventBase
{
	constructor(defender: Unit, weapon: WeaponType)
	{
		super(defender, weapon);
	}
}

export class CombatHistory
{
	readonly events: { [round: number]: { [weapon: string] : { [phase: number]: CombatEventBase[]; }; }} = {};

	public attackType: AttackType = AttackType.Land;

	public add(event: CombatEventBase, round: number, phase: number): void
	{
		if (this.events[round] === undefined)
		{
			this.events[round] = {};
		}
		if (this.events[round][event.weapon.name] === undefined)
		{
			this.events[round][event.weapon.name] = {};
		}
		if (this.events[round][event.weapon.name][phase] === undefined)
		{
			this.events[round][event.weapon.name][phase] = [];
		}
		event.round = round;
		event.phase = phase;
		this.events[round][event.weapon.name][phase].push(event);
	}

	public reset(): void
	{
		for (const round in this.events)
		{
			delete this.events[round];
		}
	}

	public toString(): string
	{
		let result: string[] = [];
		for (const r in this.events)
		{
			for (const w in this.events[r])
			{
				for (const p in this.events[r][w])
				{
					for (const e of this.events[r][w][p])
					{
						result.push(`${r} ${w} ${p} ${e.constructor.name}`);
					}
				}
			}
		}
		return result.join('\n');
	}
}
