import { CombatEventDeath, CombatEventHit, CombatEventMiss } from "../sim/CombatHistory";
import { CombatSim } from "../sim/CombatSim";
import { Unit } from "../sim/Unit";
import { SnapshotMarkers, StateSnapshot } from "../sim/UnitStatus";
import { addCol, addContainer, addRow, colorScale, format, makeAccordion, makeBadge, makeId } from "../app/Utils";
import { createBasicCard } from "../app/Utils";

function getContrastYIQ(hexcolor: string)
{
	if (hexcolor.slice(0, 1) === '#')
	{
		hexcolor = hexcolor.slice(1);
	}
	var r = parseInt(hexcolor.substr(0, 2), 16);
	var g = parseInt(hexcolor.substr(2, 2), 16);
	var b = parseInt(hexcolor.substr(4, 2), 16);
	var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
	return (yiq >= 128) ? 'black' : 'white';
}

function defenderUI2(parent: HTMLElement, unit: Unit, state: StateSnapshot, combatSim: CombatSim)
{
	const span = document.createElement('span');
	span.classList.add('badge');
	span.classList.add('clickable');
	span.classList.add('unit-health-badge');

	if (state.health <= 0)
	{
		span.style.textDecoration = 'line-through';
		span.style.backgroundColor = 'gray';
	}
	else
	{
		span.style.backgroundColor = colorScale.getColor(state.health).toRGBAString();
	}

	const span2 = document.createElement('span');
	span2.style.color = getContrastYIQ(colorScale.getColor(state.health).toHexString());
	span2.textContent = `${unit.type.uiStringForValue} (${unit.stack}${unit.stackPos})[${format(state.health)}%]`;

	parent.appendChild(span);
	span.appendChild(span2);

	span.onclick = () =>
	{
		combatSim.selectedUnit.set(unit, 'UI');
	}

	return span;
}

function attackerUI2(parent: HTMLElement, unit: Unit, state: StateSnapshot, combatSim: CombatSim)
{
	const span = document.createElement('span');
	span.classList.add('badge');
	span.classList.add('clickable');
	span.classList.add('unit-health-badge');

	if (state.health <= 0)
	{
		span.style.textDecoration = 'line-through';
		span.style.backgroundColor = 'gray';
	}
	else
		span.style.backgroundColor = colorScale.getColor(state.health).toRGBAString();

	span.textContent = `${unit.type.uiStringForValue} (${unit.stack}${unit.stackPos})[${format(state.health)}%]`;
	parent.appendChild(span);

	span.onclick = () =>
	{
		combatSim.selectedUnit.set(unit, 'UI');
	}

	return span;
}

function eventUI2(parent: HTMLElement, event: CombatEventHit | CombatEventMiss | CombatEventDeath, combatSim: CombatSim): void
{
	if (event instanceof CombatEventDeath)
	{
		defenderUI2(parent, event.defender, event.defenderState, combatSim);
		parent.appendChild(document.createTextNode(' '));
		makeBadge(parent, 'Destroyed', 'danger');
	}
	else if (event instanceof CombatEventHit || event instanceof CombatEventMiss)
	{
		attackerUI2(parent, event.attacker, event.attackerState, combatSim);
		parent.appendChild(document.createTextNode(' -> '));
		defenderUI2(parent, event.defender, event.defenderState, combatSim);
		if (event instanceof CombatEventMiss)
		{
			parent.appendChild(document.createTextNode(' '));
			const span = document.createElement('span');
			span.classList.add('badge');
			span.classList.add('bg-warning');
			span.textContent = `Miss`;
			parent.appendChild(span);
			parent.appendChild(document.createTextNode(` chance: ${format(event.chance)}`));
		}
		else if (event instanceof CombatEventHit)
		{
			parent.appendChild(document.createTextNode(' '));
			const span = document.createElement('span');
			span.classList.add('badge');
			span.classList.add('bg-success');
			span.textContent = `Hit`;
			parent.appendChild(span);
			parent.appendChild(document.createTextNode(` chance: ${format(event.chance)}`));
			//parent.appendChild(document.createTextNode(` roll: ${event.roll.toFixed(2)}`));
			parent.appendChild(document.createTextNode(' '));
			makeBadge(parent, `damage: ${format(event.damage)}%`, 'secondary');
			parent.appendChild(document.createTextNode(` ratio: ${event.ratio}`));
		}
	}
	parent.appendChild(document.createElement('br'));
}

function generateCombatSummary(parentEl: HTMLElement, combatSim: CombatSim, marker: SnapshotMarkers)
{
	const container = addContainer(parentEl);
	const row = addRow(container);
	row.classList.add('row-cols-2');

	const { form: left, card: leftCard } = createBasicCard(row, () => {});
	const { form: right, card: rightCard } = createBasicCard(row, () => {});

	leftCard.classList.add('.h-100');
	rightCard.classList.add('.h-100');

	combatSim.attacker.stack.units.filter(u => !u.isEmpty()).forEach(unit =>
	{
		attackerUI2(left, unit, unit.status.stateSnapshots[marker], combatSim);
	});

	combatSim.defender.stack.units.filter(u => !u.isEmpty()).forEach(unit =>
	{
		defenderUI2(right, unit, unit.status.stateSnapshots[marker], combatSim);
	});
}

export function generateUIForCombatHistory(parentEl: HTMLElement, combatSim: CombatSim)
{
	const combatHistory = combatSim.combatHistory;

	const generatorRounds = function*()
	{
		for (const round in combatHistory.events)
		{
			yield (roundBody: HTMLDivElement, roundHeader: HTMLElement) =>
			{
				roundHeader.textContent = `Round ${round}`;
				const generatorWeapons = function*()
				{
					for (const weapon in combatHistory.events[round])
					{
						yield (weaponBody: HTMLDivElement, weaponHeader: HTMLElement) =>
						{
							weaponHeader.textContent = weapon;
							const generatorPhases = function*()
							{
								for (const phase in combatHistory.events[round][weapon])
								{
									yield (phaseBody: HTMLDivElement, phaseHeader: HTMLElement) =>
									{
										phaseHeader.textContent = `Phase ${phase}`;
										combatHistory.events[round][weapon][phase].forEach(event => eventUI2(phaseBody, event, combatSim));
									};
								}
							};
							makeAccordion(weaponBody, true, false, generatorPhases());
						};
					}
				}
				makeAccordion(roundBody, true, false, generatorWeapons());
			};
		}
	}

	const { addItem } = makeAccordion(parentEl, true, false, undefined);
	addItem((itemBody, itemHeader) =>
	{
		itemHeader.textContent = `Sides`;
		generateCombatSummary(itemBody, combatSim, 'beforeCombat');
	});
	addItem((itemBody, itemHeader, collapse) =>
	{
		itemHeader.textContent = `Details`;
		makeAccordion(itemBody, true, false, generatorRounds());

		collapse.classList.remove('show');
		itemHeader.classList.add('collapsed');
	});
	addItem((itemBody, itemHeader) =>
	{
		itemHeader.textContent = `Summary`;
		generateCombatSummary(itemBody, combatSim, 'afterCombat');
	});
}