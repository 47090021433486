import * as d3 from 'd3';
import * as dagreD3 from 'dagre-d3';
import { NodeData, makeNodesAndLinks, Link, getClass, getTechTrackerStateClass } from './buildgraph';

function cloneObject(obj: any): any
{
	return { ...obj };
}

function cleanNode(node: NodeData): NodeData
{
	let out = cloneObject(node);
	out.parents = [];
	out.parentLinks = [];
	out.children = [];
	out.childrenLinks = [];
	return out;
}

/*
function togglePopover(graph: NodeGraph, nodeId: string, elementId: string, archives: Archives, modName: string)
{
	const node = graph.get(nodeId);
	if (node)
	{
		const popoverContent = new PopOverContent(graph, node, archives, modName);
		popover = new bootstrap.Popover(`#${elementId}`, {
			html: true,
			customClass: "custom-popover",
			title: popoverContent.title(),
			content: popoverContent.content(),
			trigger: "manual",
			placement: "right",
			boundary: "clippingParents",
			popperConfig: (defaultBsPopperConfig : any) =>
			{
				defaultBsPopperConfig.modifiers.push({
					name: 'preventOverflow',
					options: {
						altAxis: true,
						tether: false,
						mainAxis: true,
						altAxisOverflow: { flip: false },
						mainAxisOverflow: { flip: false },
					},
				});

				return defaultBsPopperConfig;
			},
		});
		popover.toggle();

		document.querySelector(`#${elementId}`)!.addEventListener('inserted.bs.popover', () =>
		{
			d3.selectAll('.popovercontent .archives').on('click', (event) =>
			{
				popover.hide();
				const modal = document.getElementById(archives.modalId);
				modal!.addEventListener('hidden.bs.modal', () =>
				{
					popover.show();
				}, { once: true });

				archives.displayChapter(parseInt(event.currentTarget?.dataset.volume!), parseInt(event.currentTarget?.dataset.chapter!));
			}, { once: true });
		});
	}
}*/
/*
function displayMainGraph(graph: NodeGraph, cache: LocalStorageCache, archives: Archives): void
{
	const [svg, g] = renderGraph('graph', graph, cache);

	svg.selectAll('g.node')
		.on("click", (event) =>
		{
			svg
				.selectAll('g.node')
				.classed("faded", false)
				.classed("selected", false)
				.classed("dependency", false)
				.classed("enables", false)
			;
			svg
				.selectAll('.edgePath')
				.classed("faded", false)
				.classed("hidden", false)
				.classed("pathhighlight", false)
			;

			let node = graph.get(event.currentTarget.id);
			if (!node)
				return;

			if (!node.selected)
			{
				node.selected = true;
				const children = graph.selectChildren(node);
				const parent = graph.selectAllParents(node);
				const relatedLinks = graph.selectAllLinks(node);

				const rebuildSelected = (nodeId: string) =>
				{
					const subGraph = graph.getSubgraph(nodeId);
					const [svg_selected, g_selected] = renderGraph('selectedgraph', subGraph);

					const node = svg_selected.select(`#${nodeId}`).classed("selected", true);

					togglePopover(subGraph, nodeId, 'selectedgraph', archives);

					svg_selected.selectAll('g.node').on("click", (e, d:any) =>
					{
						e.preventDefault();
						rebuildSelected((g_selected.node(d).node as NodeData).id)
					});
				}

				const element = document.querySelector('button[data-bs-target="#pills-selected"]')!;
				element.addEventListener('shown.bs.tab', (e) =>
				{
					rebuildSelected(node!.id);
				}, { once: true });

				svg
					.selectAll('g.node')
					.classed("faded", true)
				;
				svg
					.selectAll('.edgePath')
					.classed("faded", true)
					.classed("hidden", true)
					.classed("pathhighlight", false)
				;
				const n = svg
					.select(`g.node#${event.currentTarget.id}`)
					.classed("selected", true)
					.classed("faded", false)
				;

				togglePopover(graph, node.id, (n.node() as any).id, archives);

				if (parent.length > 0)
				{
					svg
						.selectAll(parent)
						.classed("dependency", true)
						.classed("faded", false)
					;
					svg.selectAll(relatedLinks)
						.classed("faded", false)
						.classed("hidden", false)
						.classed("pathhighlight", true)
					;
				}
				if (children.length > 0)
				{
					svg
						.selectAll(children)
						.classed("enables", true)
						.classed("faded", false)
					;
				}
			}
			else
			{
				node.selected = false;
			}
		});
	;
}
*/
export class NodeGraph
{
	private nodeMap: Map<string, NodeData> = new Map();
	public nodes: NodeData[] = [];
	private links: Link[] = [];
	public g?: dagreD3.graphlib.Graph;

	constructor()
	{
	}

	public onGraphChanged(nodeMap: Map<string, NodeData>)
	{
		this.nodeMap = nodeMap;

		[this.nodes, this.links] = makeNodesAndLinks(this.nodeMap);

		this.g = new dagreD3.graphlib.Graph({ directed: true, multigraph: true, compound: true })
			.setGraph({ rankdir: 'LR' })
			.setDefaultEdgeLabel(function () { return {}; });

		let currentHue = 0;
		const hueStep = 30;
		function generateNextDistinctColor(): string
		{
			currentHue = (currentHue + hueStep) % 360;
			return `hsla(${currentHue}, 50%, 70%, 0.3)`;
		}

		this.nodes.forEach((node) =>
		{
			const g = this.g!;
			g.setNode(node.id.toString(), { id: node.id.toString(), node, label: node.name, shape: node.shape, class: node.class });
			if (node.cluster)
			{
				if (!g.hasNode(node.cluster))
				{
					if (node.superCluster)
					{
						if (!g.hasNode(node.superCluster))
							g.setNode(node.superCluster, { label: node.superCluster, clusterLabelPos: 'bottom', style: `fill: ${generateNextDistinctColor()}; stroke:#000000;` });
					}
					g.setNode(node.cluster, { label: node.cluster, clusterLabelPos: 'bottom', style: `fill: ${generateNextDistinctColor()}; stroke: #000000;` });
					if (node.superCluster)
						g.setParent(node.cluster, node.superCluster);
				}
				g.setParent(node.id.toString(), node.cluster);
			}
		});

		this.links.forEach((link) =>
		{
			this.g!.setEdge(link.source.toString(), link.target.toString(), { curve: d3.curveLinear, arrowhead: 'vee', class: link.id, style: "" });
		});
	}

	public selectChildren(node: NodeData | undefined): string
	{
		if (!node || node.children.length === 0)
			return "";

		let out = "";
		for (let id of node.children)
			out += `g.node#${id},`;

		return out.slice(0, -1);
	}

	public selectAllParents(node: NodeData | undefined): string
	{
		if (!node || node.parents.length === 0)
			return "";

		const { parentIds } = this.getAllParents(node);

		let out = "";
		for (let id of parentIds)
			out += `g.node#${id},`;

		return out.replace(/,$/, '');
	}

	public selectAllLinks(node: NodeData | undefined): string
	{
		if (!node || node.parentLinks.length === 0 && node.childrenLinks.length === 0)
			return "";

		const { parentLinks } = this.getAllParents(node);

		let out = "";
		for (let id of parentLinks)
			out += `.${id},`;

		for (let id of node.childrenLinks)
			out += `.${id},`;

		return out.replace(/,$/, '');
	}

	public get(id: string): NodeData | undefined
	{
		return this.nodeMap.get(id);
	}

	public getAllParents(node: NodeData | undefined): { parentIds: string[]; parentLinks: string[]; }
	{
		let out: { parentIds: string[]; parentLinks: string[]; } = { parentIds: [], parentLinks: [] };

		if (!node || node.parents.length === 0)
			return out;

		out.parentIds = node.parents;
		out.parentLinks = node.parentLinks;

		for (let id of node.parents)
		{
			let parent = this.nodeMap.get(id);
			const { parentIds, parentLinks } = this.getAllParents(parent);
			out.parentIds = out.parentIds.concat(parentIds);
			out.parentLinks = out.parentLinks.concat(parentLinks);
		}

		return out;
	}

	public refreshNodes()
	{
		this.nodeMap.forEach((node) =>
		{
			if (node.techData && node.techState)
				node.class = "node " + getClass(node.techData) + " " + getTechTrackerStateClass(node.techState());
		});
	}

	public getSubgraph(id: string): NodeGraph
	{
		this.refreshNodes();

		const graph = new Map<string, NodeData>();
		const node = this.nodeMap.get(id);

		if (node)
		{
			graph.set(id, cleanNode(node));
			for (let childId of node.children)
			{
				const child = this.nodeMap.get(childId);
				if (child)
					graph.set(childId, cleanNode(child));
			}
			const parents = this.getAllParents(node);
			for (let parentId of parents.parentIds)
			{
				const parent = this.nodeMap.get(parentId);
				if (parent)
					graph.set(parentId, cleanNode(parent));
			}
		}

		const out = new NodeGraph();
		out.onGraphChanged(graph);
		return out;
	}

	public removeDuplicates(nodes: NodeData[]): NodeData[]
	{
		const seen = new Map();
		return nodes.filter(node =>
		{
			return seen.has(node.id) ? false : seen.set(node.id, true);
		});
	}
}
