import { Bonus } from "./Bonus";
import { ModData } from "./ModData";
import { Traits } from "./Traits";
import { Flag } from "./Value";

export class SerializableKnownTech
{
	barrenEnvironment?: boolean;
	jungleEnvironment?: boolean;
	frozenEnvironment?: boolean;
	symbiotPsychology?: boolean;
	vauPsychology?: boolean;
	theurgy?: boolean;
}

export class KnownTech
{
	readonly barrenEnvironment: Flag = new Flag(false, { label: 'Barren Environment' });
	readonly jungleEnvironment: Flag = new Flag(false, { label: 'Jungle Environment' });
	readonly frozenEnvironment: Flag = new Flag(false, { label: 'Frozen Environment' });
	readonly symbiotPsychology: Flag = new Flag(false, { label: 'Symbiot Psychology' });
	readonly vauPsychology: Flag = new Flag(false, { label: 'Vau Psychology' });
	readonly theurgy: Flag = new Flag(false, { label: 'Theurgy' });

	constructor(readonly context: ModData)
	{
	}

	public initialize()
	{
		this.barrenEnvironment.initialize(false, 'initialize', 'UI');
		this.jungleEnvironment.initialize(false, 'initialize', 'UI');
		this.frozenEnvironment.initialize(false, 'initialize', 'UI');
		this.symbiotPsychology.initialize(false, 'initialize', 'UI');
		this.vauPsychology.initialize(false, 'initialize', 'UI');
		this.theurgy.initialize(false, 'initialize', 'UI');
	}

	public getBonuses(opponent: Traits): Bonus[]
	{
		const out: Bonus[] = [];

		if (this.symbiotPsychology.value)
		{
			out.push(
				new Bonus(
					"Symbiot Psychology",
					(status) => status,
					(stats, source) =>
					{
						if (opponent.symbiot.value)
						{
							Object.values(stats.attacks).forEach((attack) =>
							{
								if (attack.strength.value !== 0)
									attack.strength.applyBonusPercent(source, 20, 'getBonuses')
							});
						}
						return stats;
					})
			);
		}

		if (this.vauPsychology.value)
		{
			out.push(
				new Bonus(
					"Vau Psychology",
					(status) => status,
					(stats, source) =>
					{
						if (opponent.vau.value)
						{
							Object.values(stats.attacks).forEach((attack) =>
							{
								if (attack.strength.value !== 0)
									attack.strength.applyBonusPercent(source, 20, 'getBonuses')
							});
						}
						return stats;
					})
			);
		}

		return out;
	}

	public toSerializableObject(): SerializableKnownTech
	{
		const out: SerializableKnownTech = {
			barrenEnvironment: this.barrenEnvironment.initial,
			jungleEnvironment: this.jungleEnvironment.initial,
			frozenEnvironment: this.frozenEnvironment.initial,
			symbiotPsychology: this.symbiotPsychology.initial,
			vauPsychology: this.vauPsychology.initial,
			theurgy: this.theurgy.initial,
		};
		return out;
	}

	public fromSerializableObject(obj: SerializableKnownTech | undefined): void
	{
		this.barrenEnvironment.initialize(obj?.barrenEnvironment ?? false, 'fromSerializableObject', 'UI');
		this.jungleEnvironment.initialize(obj?.jungleEnvironment ?? false, 'fromSerializableObject', 'UI');
		this.frozenEnvironment.initialize(obj?.frozenEnvironment ?? false, 'fromSerializableObject', 'UI');
		this.symbiotPsychology.initialize(obj?.symbiotPsychology ?? false, 'fromSerializableObject', 'UI');
		this.vauPsychology.initialize(obj?.vauPsychology ?? false, 'fromSerializableObject', 'UI');
		this.theurgy.initialize(obj?.theurgy ?? false, 'fromSerializableObject', 'UI');
	}
}
