import { CombatSim } from "../sim/CombatSim";
import { generateUIForLocation } from "./Location_ui";
import { generateUIForResults } from "./Result_ui";
import { generateUIForSide } from "./Side_ui";
import { generateUIForUnitStats } from "./UnitStats_ui";
import { addCol, addColX, addRow, addContainer } from "../app/Utils";

export class CombatSimUI
{
	constructor(private elementId: string, readonly combatSim: CombatSim, private onChangedCallback: () => void)
	{
		const onChanged = (source: string) =>
		{
			this.combatSim.onSomethingChangedFromUI(source);
			this.onChangedCallback();
		}

		const element = document.getElementById(this.elementId)!;

		const container = addContainer(element);

		const row0 = addRow(container);
		row0.classList.add('row-cols-2');

		const leftCol = addColX(row0, 7);
		const rightCol = addColX(row0, 5);

		generateUIForResults(rightCol, this.combatSim, onChanged);

		const row01 = addRow(leftCol);
		row0.classList.add('row-cols-3');

		generateUIForLocation(addColX(row01, 4), this.combatSim.location, onChanged);
		generateUIForUnitStats(addColX(row01, 8), this.combatSim, onChanged);

		const row02 = addRow(leftCol);
		row02.classList.add('row-cols-2');
		generateUIForSide(addCol(row02), this.combatSim.attacker, this.combatSim, onChanged);
		generateUIForSide(addCol(row02), this.combatSim.defender, this.combatSim, onChanged);
	}

	public onShown(): void
	{
		this.combatSim.applyBonuses();
	}

	public destroy(): void
	{
		var element = document.getElementById(this.elementId)!;
		element.innerHTML = '';
	}
}
