import { DataPoint } from "../app/resourcemanager";

type DamageTable = { [key: string]: number[] };

export class AttackDamageTable
{
	readonly ratioTitles: string[] = ['1-5', '1-4', '1-3', '1-2', '1-1', '2-1', '3-1', '4-1', '5-1', '6-1', '7-1', '8-1'];
	readonly damageTable: DamageTable = {};
	public maxLevel: number = 0;

	constructor(tableData: DataPoint)
	{
		this.parseTableData(tableData.data);
	}

	private parseTableData(tableData: string): void
	{
		const lines = tableData.trim().split('\n').filter(line => !line.startsWith('//'));

		let level = 0;
		lines.forEach((line, rowIndex) =>
		{
			const rowMatches = line.trim().match(/\"(.*)\"/);
			if (!rowMatches || rowMatches.length < 2)
				return;

			const row = rowMatches[1];

			const damages = row.trim().split(/\s+/).map(damage => parseInt(damage.trim()));
			this.ratioTitles.forEach((ratio, ratioIndex) =>
			{
				if (!this.damageTable[ratio])
				{
					this.damageTable[ratio] = [];
				}
				this.damageTable[ratio][level] = damages[ratioIndex];
			});
			level += 1;
			this.maxLevel = level;
		});
	}

	getDamageForRatioAndLevel(ratio: string, level: number): number | null
	{
		const damages = this.damageTable[ratio];
		if (damages[level] !== undefined)
		{
			return damages[level];
		}
		return null;
	}

	getAllDamagesForRatio(ratio: string): number[] | null
	{
		return this.damageTable[ratio] || null;
	}

	getAllDamagesForLevel(level: number): number[] | null
	{
		const damages: number[] = [];
		this.ratioTitles.forEach((ratio) =>
		{
			const damage = this.getDamageForRatioAndLevel(ratio, level);
			if (damage !== null)
			{
				damages.push(damage);
			}
		});
		return damages.length > 0 ? damages : null;
	}
}