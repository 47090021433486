import { DataPoint } from "../app/resourcemanager";
import { ResourceTable } from "./res";

export interface ResourceNames {
	food: number;
	energy: number;
	metal: number;
	trace: number;
	exotica: number;
	gems: number;
	chemicals: number;
	biochems: number;
	electronics: number;
	ceramsteel: number;
	wetware: number;
	monopols: number;
	singularities: number;
}

type ResourceNameMap = {
	[key in keyof ResourceNames]: string;
};

export class Resources implements ResourceNames
{
	public food: number = 0;
	public energy: number = 0;
	public metal: number = 0;
	public trace: number = 0;
	public exotica: number = 0;
	public gems: number = 0;
	public chemicals: number = 0;
	public biochems: number = 0;
	public electronics: number = 0;
	public ceramsteel: number = 0;
	public wetware: number = 0;
	public monopols: number = 0;
	public singularities: number = 0;

	constructor(resources?: ResourceNames) {
		if (!resources)
			return;

		Object.keys(resources).forEach((resource: string) =>
		{
			this[resource as keyof ResourceNames] = resources[resource as keyof ResourceNames];
		});
	}

	public isEmpty(): boolean
	{
		return this.food === 0
			&& this.energy === 0
			&& this.metal === 0
			&& this.trace === 0
			&& this.exotica === 0
			&& this.chemicals === 0
			&& this.biochems === 0
			&& this.electronics === 0
			&& this.ceramsteel === 0
			&& this.wetware === 0
			&& this.monopols === 0
			&& this.gems === 0
			&& this.singularities === 0
		;
	}

	public add(other: Resources, times: number = 1): void
	{
        this.food += other.food * times;
        this.energy += other.energy * times;
        this.metal += other.metal * times;
        this.trace += other.trace * times;
        this.exotica += other.exotica * times;
        this.chemicals += other.chemicals * times;
        this.biochems += other.biochems * times;
        this.electronics += other.electronics * times;
        this.ceramsteel += other.ceramsteel * times;
        this.wetware += other.wetware * times;
        this.monopols += other.monopols * times;
        this.gems += other.gems * times;
        this.singularities += other.singularities * times;
    }
};

type City = {
	city: string,
	needs: Resources,
	makes: keyof Resources,
	amount: number,
	totalBasicNeedsForOne: Resources,
};

type Cities = {
	[key in keyof Resources]?: City;
};

export class ResourceProduction
{
	public cities: Cities = {};

	/*
	{
	"city" "electronics"
	"need" "energy"      "10"
	"need" "trace"        "5"
	"make" "electronics" "10"
	}
	*/
	constructor(resourceTable: DataPoint, res: ResourceTable)
	{
		const lines = resourceTable.data.trim().split('\n').filter(line => !line.startsWith('//'));

		let city: City;
		lines.forEach(line =>
		{
			line = line.trim();
			if (line === '{')
			{
				city = { city: '', needs: new Resources(), makes: 'food', totalBasicNeedsForOne: new Resources(), amount: 0 };
				return;
			}
			if (line === '}')
			{
				this.cities[city.makes] = city;
				return;
			}

			const rowMatches = line.match(/"(city|need|make)"\s*"([^"]+)"\s*"([^"]+)"\s*/);
			if (!rowMatches)
				return;

			if (rowMatches[1] === 'city')
			{
				city.city = rowMatches[2];
				return;
			}
			if (rowMatches[1] === 'need')
			{
				city.needs[res.get(rowMatches[2].toLowerCase())!.oldKey] = parseInt(rowMatches[3]);
				return;
			}
			if (rowMatches[1] === 'make')
			{
				city.makes = res.get(rowMatches[2].toLowerCase())!.oldKey;
				city.amount = parseInt(rowMatches[3]);
				return;
			}
		});

		const getCost = (resource: keyof Resources, amount: number): Resources =>
		{
			const cost: Resources = new Resources();
			if (amount === 0)
				return cost;

			const needs = this.cities[resource]?.needs;
			if (!needs)
				return cost;

			const makesAmount = this.cities[resource]!.amount;
			let res: keyof Resources;
			for (res in needs)
			{
				const need = needs[res] as number;
				cost[res] += (need * amount / makesAmount) as any;
			}

			let added = true;
			while (added)
			{
				added = false;
				for (res in cost)
				{
					const need = cost[res] as number;
					const unrolled = getCost(res, need);
					if (unrolled.isEmpty())
						continue;

					cost.add(unrolled);
					cost[res] = 0 as any;
					added = true;
				}
			}

			return cost;
		};

		let resource: keyof Resources;
		for (resource in this.cities)
		{
			const city = this.cities[resource];
			if (!city)
				continue;
			let resNeeded: keyof Resources;
			for (resNeeded in city.needs)
			{
				if (city.needs[resNeeded] as number === 0)
					continue;
				const cost = getCost(resNeeded, 1);
				if (cost.isEmpty())
					city.totalBasicNeedsForOne[resNeeded] = (city.needs[resNeeded] as number / city.amount) as any;
				else
					city.totalBasicNeedsForOne.add(cost, city.needs[resNeeded] as number / city.amount);
			}
		}
	}

	public recalculateResources(resources: Resources, rate: number): Resources
	{
		const cost: Resources = new Resources(resources);

		let knownResource: keyof Resources;
		for (knownResource in this.cities)
		{
			const city = this.cities[knownResource];
			if (!city)
				continue;

			const amountNeeded = resources[knownResource] as number;
			if (amountNeeded === 0)
				continue;

			cost.add(city.totalBasicNeedsForOne, amountNeeded / rate);
			cost[knownResource] = 0 as any;
		}

		return cost;
	}
}