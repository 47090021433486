import { ModData } from "./ModData";
import { Bonus } from "./Bonus";
import { SerializableStack, Stack } from "./Stack";
import { Location, SerializableLocation } from "./Location";
import { Player, SerializablePlayer } from "./Player";
import { RecordValue } from "./Value";
import { CombatSim } from "./CombatSim";

export type StanceType = 'Neutral' | 'Defensive' | 'Offensive' | 'Defending' | 'OrbitalBombardment' | 'PTS' | 'Space' | 'Landing';

export const StanceTypeValues : Record<StanceType, string> =
{
	Neutral: 'Neutral',
	Defensive: 'Defensive',
	Offensive: 'Offensive',
	Defending: 'Defending',
	Space: 'Space',
	OrbitalBombardment: 'Orbital Bombardment',
	PTS: 'PTS',
	Landing: 'Landing',
}

export type SerializableSide =
{
	player: SerializablePlayer;
	stack: SerializableStack;
	planet: SerializableLocation;
	stance: StanceType;
}

export class Side
{
	readonly player: Player = new Player(this.context);
	readonly stance = new RecordValue(StanceTypeValues, "Neutral", "Stance");
	readonly stack: Stack;

	constructor(readonly combatSim: CombatSim, readonly context: ModData, stance: StanceType, readonly location: Location, private isAttacker: boolean)
	{
		this.stance.set(stance, 'constructor');
		this.stack = new Stack(this.combatSim, this.context, this.player, this.isAttacker ? 'A' : 'D');
	}

	public initialize()
	{
		this.player.initialize();
		this.stack.initialize();
		this.location.initialize();
	}

	public toSerializableObject(): SerializableSide
	{
		const out: SerializableSide = {
			player: this.player.toSerializableObject(),
			stack: this.stack.toSerializableObject(),
			planet: this.location.toSerializableObject(),
			stance: this.stance.value,
		};
		return out;
	}

	public fromSerializableObject(obj: SerializableSide): void
	{
		this.initialize();

		if (obj.player !== undefined)
			this.player.fromSerializableObject(obj.player);
		this.stack.fromSerializableObject(obj.stack);
		if (obj.planet !== undefined)
			this.location.fromSerializableObject(obj.planet);
		this.stance.set(obj.stance, 'fromSerializableObject');
	}

	public getBonuses(opponent: Player): Bonus[]
	{
		const out: Bonus[] = [];

		out.push(...this.player.traits.getBonuses());
		out.push(...this.player.knownTech.getBonuses(opponent.traits));
		out.push(...this.stack.getBonuses());
		out.push(...this.location.getBonuses(this.player.knownTech));

		if (!this.isAttacker)
		{
			out.push(...this.location.tile.getDefenseBonuses(this.stance.value));
		}
		else
		{
			out.push(...this.location.tile.getAttackBonuses(this.stance.value));
			out.push(
				new Bonus(
					`${this.stance.uiStringForValue} stance`,
					(status) => status,
					(stats, source) =>
					{
						switch (this.stance.value)
						{
							case 'Defensive':
								stats.armor.applyBonusPercent(source, 20, 'getBonuses');
								Object.values(stats.attacks).forEach((attack) => attack.strength.applyBonusPercent(source, -20, 'getBonuses'));
							case 'Offensive':
								stats.armor.applyBonusPercent(source, -20, 'getBonuses');
								Object.values(stats.attacks).forEach((attack) => attack.strength.applyBonusPercent(source, 20, 'getBonuses'));
								break;
						}
						return stats;
					})
			);
		}

		return out;
	}

	public reset(invokedBy: string, sourceFilter: (source: string) => boolean): void
	{
		this.stack.reset(invokedBy, sourceFilter);
	}
}
