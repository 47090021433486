import { Value, Flag } from "./Value";
import { ModData } from "./ModData";
import { WeaponType } from "../data/target";
import { Unit } from "./Unit";

class CombatEvent
{
	constructor(readonly weapon: WeaponType, readonly phase: number, readonly target: Unit, readonly damage: number)
	{}
}

export class CombatState
{
	constructor()
	{}

 	public events: CombatEvent[] = [];

	public addEvent(weapon: WeaponType, phase: number, target: Unit, damage: number): void
	{
		this.events.push(new CombatEvent(weapon, phase, target, damage));
	}

	public reset(invokedBy: string): void
	{
		this.events = [];
	}
}

export type SerializableUnitStatus =
{
	loyalty: number;
	health: number;
	routed: boolean;
	spotted: boolean;
	inCargo: boolean;
	inCarrier: boolean;
	plague: boolean;
	experience: number;
}

export type StateSnapshot = {
	health: number;
	routed: boolean;
	spotted: boolean;
}

export type SnapshotMarkers = 'beforeCombat' | 'afterCombat';

export class UnitStatus
{
	constructor(readonly context: ModData)
	{
		this.inCargo.onChanged[`UnitStatus`] = (value) =>
		{
			if (!value && this.inCarrier.value)
				this.inCarrier.initialize(false, 'inCargo.onChanged', 'inCargo.onChanged');
		}
		this.inCarrier.onChanged[`UnitStatus`] = (value) =>
		{
			if (value && !this.inCargo.value)
				this.inCargo.initialize(true, 'inCarrier.onChanged', 'inCarrier.onChanged');
		}
	}

	readonly loyalty = new Value({ initial: 75, uiString: 'Loyalty', modifiable: true, min: 0, max: 100 });
	readonly health: Value = new Value({ initial: 100, uiString: 'Health', modifiable: true, min: 0, max: 100 });
	readonly routed: Flag = new Flag(false, { label: 'Routed' }, true);
	readonly spotted: Flag = new Flag(true, { label: 'Spotted' }, false);
	readonly inCargo: Flag = new Flag(false, { label: 'In Cargo' }, true);
	readonly inCarrier: Flag = new Flag(false, { label: 'In Carrier' }, true);
	readonly plague: Flag = new Flag(false, { label: 'Plague' }, true);
	readonly experience: Value = new Value({ initial: 0, uiString: 'Experience', modifiable: true, min: 0, max: 2 });
	readonly combatState: CombatState = new CombatState();
	readonly stateSnapshots: Record<string, StateSnapshot> = {};

	public incomingHitCounter: number = 0; // count of attacks taken in current subphase

	public toSerializableObject(): SerializableUnitStatus
	{
		const out: SerializableUnitStatus = {
			loyalty: this.loyalty.initial,
			health: this.health.initial,
			routed: this.routed.initial,
			spotted: this.spotted.initial,
			inCargo: this.inCargo.initial,
			inCarrier: this.inCarrier.initial,
			plague: this.plague.initial,
			experience: this.experience.initial,
		};
		return out;
	}

	public captureCombatState(): StateSnapshot
	{
		return {
			health: this.health.value,
			routed: this.routed.value,
			spotted: this.spotted.value,
		}
	}

	public saveStateSnapshot(marker: SnapshotMarkers)
	{
		this.stateSnapshots[marker] = this.captureCombatState();
	}

	public fromSerializableObject(obj: SerializableUnitStatus): void
	{
		this.loyalty.initialize(obj.loyalty, 'fromSerializableObject', 'UI');
		this.health.initialize(obj.health, 'fromSerializableObject', 'UI');
		this.routed.initialize(obj.routed, 'fromSerializableObject', 'UI');
		this.spotted.initialize(obj.spotted, 'fromSerializableObject', 'UI');
		this.inCargo.initialize(obj.inCargo, 'fromSerializableObject', 'UI');
		this.inCarrier.initialize(obj.inCarrier, 'fromSerializableObject', 'UI');
		this.plague.initialize(obj.plague, 'fromSerializableObject', 'UI');
		this.experience.initialize(obj.experience, 'fromSerializableObject', 'UI');
	}

	public reset(invokedBy: string, sourceFilter: (source: string) => boolean): void
	{
		this.loyalty.reset(invokedBy, sourceFilter);
		this.health.reset(invokedBy, sourceFilter);
		this.routed.reset(invokedBy, sourceFilter);
		this.spotted.reset(invokedBy, sourceFilter);
		this.inCarrier.reset(invokedBy, sourceFilter);
		this.inCargo.reset(invokedBy, sourceFilter);
		this.plague.reset(invokedBy, sourceFilter);
		this.experience.reset(invokedBy, sourceFilter);
		this.combatState.reset(invokedBy);
		delete this.stateSnapshots['beforeCombat'];
		delete this.stateSnapshots['afterCombat'];
	}

	public isActive(): boolean
	{
		if (this.routed.value) return false;
		if (this.inCargo.value && !this.inCarrier.value) return false;
		if (this.health.value <= 0) return false;

		return true;
	}
}
