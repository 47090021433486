import ColorScales from "color-scales";
//import 'highlight.js/styles/night-owl.css';
//import hljs from 'highlight.js';
//import csv from 'highlight.js/lib/languages/csv';
//import ini from 'highlight.js/lib/languages/ini';

//hljs.registerLanguage('csv', csv);
//hljs.registerLanguage('ini', ini);

export function makeId(): string
{
	return Math.random().toString(36).substring(7);
}

export function addColMd(parentEl: HTMLElement): HTMLElement
{
	const col = document.createElement('div');
	parentEl.appendChild(col);
	col.classList.add('col-md');
	return col;
}

export function addCol(parentEl: HTMLElement): HTMLElement
{
	const col = document.createElement('div');
	parentEl.appendChild(col);
	col.classList.add('col');
	return col;
}

export function addColX(parentEl: HTMLElement, x: number): HTMLElement
{
	const col = document.createElement('div');
	parentEl.appendChild(col);
	col.classList.add(`col-${x}`);
	return col;
}

export function addColAuto(parentEl: HTMLElement): HTMLElement
{
	const col = document.createElement('div');
	parentEl.appendChild(col);
	col.classList.add(`col-md-auto`);
	return col;
}

export function addContainer(parentEl: HTMLElement): HTMLElement
{
	const container = document.createElement('div');
	parentEl.appendChild(container);
	container.classList.add('container-fluid');
	container.classList.add('g-1');
	return container;
}

export function addRow(parentEl: HTMLElement): HTMLElement
{
	const row = document.createElement('div');
	parentEl.appendChild(row);
	row.classList.add('row');
	row.classList.add('g-1');
	return row;
}

export function addLabel(parentEl: HTMLElement, text: string): HTMLElement
{
	const label = document.createElement('label');
	parentEl.appendChild(label);
	label.classList.add('form-label');
	label.classList.add('me-2');
	label.textContent = text;
	return label;
}

export function makeAccordion(parentEl: HTMLElement, open: boolean, autoClosing: boolean, items: Generator<(itemBody: HTMLDivElement, itemHeader: HTMLElement, collapse: HTMLElement) => void> | undefined)
{
	const accordion = document.createElement('div');
	parentEl.appendChild(accordion);
	accordion.classList.add('accordion');
	accordion.classList.add('accordion-flush');
	accordion.classList.add('mb-2');
	accordion.id = makeId();

	const addItem = (onAdded: (itemBody: HTMLDivElement, itemHeader: HTMLElement, collapse: HTMLElement) => void) =>
	{
		const itemId = makeId();

		const accordionItem = document.createElement('div');
		accordion.appendChild(accordionItem);
		accordionItem.classList.add('accordion-item');

		const accordionHeader = document.createElement('h2');
		accordionItem.appendChild(accordionHeader);
		accordionHeader.classList.add('accordion-header');
		accordionHeader.classList.add('border-0');

		const accordionButton = document.createElement('button');
		accordionHeader.appendChild(accordionButton);
		accordionButton.classList.add('accordion-button');
		if (!open)
			accordionButton.classList.add('collapsed');
		accordionButton.classList.add('border-0');
		accordionButton.classList.add('fw-bold');
		accordionButton.classList.add('text-start');
		accordionButton.classList.add('p-1');
		accordionButton.type = 'button';
		accordionButton.id = `${itemId}-header`;
		accordionButton.setAttribute('data-bs-toggle', 'collapse');
		accordionButton.setAttribute('data-bs-target', `#${itemId}-collapse`);
		accordionButton.setAttribute('aria-expanded', open ? 'true' : 'false');
		accordionButton.setAttribute('aria-controls', `${itemId}-collapse`);

		const accordionCollapse = document.createElement('div');
		accordionItem.appendChild(accordionCollapse);
		accordionCollapse.classList.add('accordion-collapse');
		if (open)
			accordionCollapse.classList.add('show');
		accordionCollapse.classList.add('collapse');
		accordionCollapse.classList.add('border-0');
		accordionCollapse.id = `${itemId}-collapse`;
		accordionCollapse.setAttribute('aria-labelledby', `${itemId}-header`);

		if (autoClosing)
			accordionCollapse.setAttribute('data-bs-parent', `#${accordion.id}`);

		const accordionBody = document.createElement('div');
		accordionCollapse.appendChild(accordionBody);
		accordionBody.classList.add('accordion-body');
		accordionBody.classList.add('p-1');

		onAdded(accordionBody, accordionButton, accordionCollapse);
	};

	if (items)
	{
		for (const item of items)
		{
			addItem(item);
		};
	}

	return { addItem, accordion };
}

export function makeBadge(parentEl: HTMLElement, text: string, color: string): HTMLElement
{
	const badge = document.createElement('span');
	parentEl.appendChild(badge);
	badge.classList.add('badge');
	badge.classList.add(`bg-${color}`);
	badge.classList.add('me-1');
	badge.textContent = text;
	return badge;
}

export const colorScale = new ColorScales(0, 100, ["#ff0000", "#00ff00"], 0.7);

export function makeIconButton(parentEl: HTMLElement, text: string, icon: string, color: string, onClick: (ev: MouseEvent) => void): HTMLButtonElement
{
	const button = document.createElement('button');

	button.classList.add('btn');
	button.classList.add(`btn-outline-${color}`);
	//button.classList.add('btn-sm');
	button.classList.add('p-0');
	button.classList.add('m-0');
	button.classList.add('me-1');
	button.classList.add('border-0');
	button.style.minWidth = '1.5rem';
	button.innerHTML = `<i class="bi ${icon}"></i>`;
	button.title = text;
	button.id = makeId();
	button.onclick = onClick;

	parentEl.appendChild(button);

	return button;
}

export function createButton(parentEl: HTMLElement, text: string, icon: string, color: string, onClick: (ev: MouseEvent) => void): HTMLButtonElement
{
	const button = document.createElement('button');

	button.classList.add('btn');
	button.classList.add(`btn-${color}`);
	button.classList.add('btn-sm');
	button.classList.add('m-1');
	button.style.minWidth = '1.5rem';
	button.style.minHeight = '1.5rem';
	button.title = text;
	button.innerHTML = `<i class="bi ${icon}"></i> ${text}`;

	//button.classList.add('border-0');
	button.id = makeId();
	button.onclick = onClick;

	parentEl.appendChild(button);

	return button;
}

export function format(i: number)
{
	return Number.parseFloat(i.toFixed(2)).toString();
}

export function makeModal(titleText: string)
{
	const div = document.createElement('div');
	div.classList.add('modal');
	div.classList.add('fade');
	div.id = makeId();
	div.tabIndex = -1;
	div.setAttribute('aria-hidden', 'true');
	div.setAttribute('aria-labelledby', `${div.id}-label`);
	div.setAttribute('role', 'dialog');
	div.setAttribute('data-bs-backdrop', 'static');
	div.setAttribute('data-bs-keyboard', 'false');

	const dialog = document.createElement('div');
	div.appendChild(dialog);
	dialog.classList.add('modal-dialog');
	dialog.classList.add('modal-xl');
	dialog.classList.add('modal-dialog-centered');
	dialog.setAttribute('role', 'document');

	const content = document.createElement('div');
	dialog.appendChild(content);
	content.classList.add('modal-content');

	const header = document.createElement('div');
	content.appendChild(header);
	header.classList.add('modal-header');

	const title = document.createElement('h5');
	header.appendChild(title);
	title.classList.add('modal-title');
	title.id = `${div.id}-label`;
	title.textContent = titleText;

	const close = document.createElement('button');
	header.appendChild(close);
	close.classList.add('btn-close');
	close.setAttribute('type', 'button');
	close.setAttribute('data-bs-dismiss', 'modal');
	close.setAttribute('aria-label', 'Close');

	const body = document.createElement('div');
	content.appendChild(body);
	body.classList.add('modal-body');

	const footer = document.createElement('div');
	content.appendChild(footer);
	footer.classList.add('modal-footer');

	document.body.appendChild(div);
	const modal = bootstrap.Modal.getOrCreateInstance(div);

	div.addEventListener('hidden.bs.modal', () =>
	{
		modal.dispose();
		document.body.removeChild(div);
	});

	return { div, title, body, footer, header, modal };
}

export function makeCodeBlock(parentEl: HTMLElement, text: string, language: string)
{
	const pre = document.createElement('pre');
	parentEl.appendChild(pre);
	pre.style.maxHeight = '500px';
	//pre.classList.add('theme-atom-one-dark-reasonable');

	const code = document.createElement('code');
	pre.appendChild(code);
	code.classList.add(`language-${language}`);
	code.textContent = text;
	//hljs.highlightElement(code);

	return pre;
}

export function createBasicCard(parentEl: HTMLElement, onClick: ((ev: MouseEvent) => void) | null)
{
	const card = document.createElement('div');
	card.classList.add('card');
	//card.classList.add('h-100');
	card.classList.add('p-1');
	if (onClick)
	{
		card.classList.add('clickable');
		card.onclick = onClick;
	}
	parentEl.appendChild(card);

	const cardBody = document.createElement('div');
	card.appendChild(cardBody);
	cardBody.classList.add('card-body');
	cardBody.classList.add('p-0');

	return { form: cardBody, card };
}

export function createFormCard(parentEl: HTMLElement, title: string, collapsed: boolean, onClick: ((ev: MouseEvent) => void) | null)
{
	const card = document.createElement('div');
	card.classList.add('card');
	//card.classList.add('h-100');
	card.classList.add('p-1');
	card.classList.add('m-0');
	parentEl.appendChild(card);

	const cardHeader = document.createElement('div');
	cardHeader.classList.add('card-header');
	cardHeader.textContent = title;
	if (onClick)
	{
		cardHeader.onclick = onClick;
		cardHeader.classList.add('clickable');
	}

	card.appendChild(cardHeader);

	const randomId = makeId();

	// add collapse button
	const collapseButton = makeIconButton(cardHeader, 'Collapse', 'bi-chevron-bar-down', 'secondary', () => { });
	collapseButton.classList.add('float-end');
	collapseButton.setAttribute('data-bs-toggle', 'collapse');
	collapseButton.setAttribute('data-bs-target', `#collapse-${randomId}`);
	if (!collapsed)
		collapseButton.setAttribute('aria-expanded', 'true');
	collapseButton.setAttribute('aria-controls', `collapse-${randomId}`);

	const cardBody = document.createElement('div');
	card.appendChild(cardBody);
	cardBody.classList.add('card-body');
	cardBody.classList.add('p-0');
	cardBody.classList.add('collapse');
	if (!collapsed)
		cardBody.classList.add('show');
	cardBody.id = `collapse-${randomId}`;

	// add form
	//const form = document.createElement('form');
	//cardBody.appendChild(form);
	return { form: cardBody, header: cardHeader, card };
}

export function createInput(parentEl: HTMLElement, label: string, type: string, placeholder: string, onChange: (ev: Event) => void)
{
	const formGroup = document.createElement('div');
	parentEl.appendChild(formGroup);
	formGroup.classList.add('form-group');

	const labelEl = document.createElement('label');
	formGroup.appendChild(labelEl);
	labelEl.textContent = label;

	const input = document.createElement('input');
	formGroup.appendChild(input);
	input.classList.add('form-control');
	input.type = type;
	input.placeholder = placeholder;
	input.onchange = onChange;

	return { formGroup, input };
}

export function upload(accept: string, onLoaded: (reader: FileReader) => void)
{
	const input = document.createElement('input');
	input.type = 'file';
	input.accept = accept;
	input.onchange = () =>
	{
		const files = input.files;
		if (!files)
			return;
		const file = files[0];
		const reader = new FileReader();
		reader.onload = () => onLoaded(reader);

		reader.readAsText(file);
	};
	input.click();
}

export function downloadJson(data: any, filename: string)
{
	const json = JSON.stringify(data, null, 2);
	const blob = new Blob([json], {type: "application/json"});
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = filename;
	a.click();
}
