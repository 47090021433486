import { CombatSim } from "../sim/CombatSim";
import { Stack } from "../sim/Stack";
import { createUIForRecordValue } from "./Value_ui";
import { createBasicCard, createFormCard } from "../app/Utils";
import { generateUIForUnit } from "./Unit_ui";
import { addCol, addColAuto, addContainer, addRow, createButton, makeAccordion, makeIconButton, makeModal } from "../app/Utils";
import { RecordValue } from "../sim/Value";

export function generateUIForStack(parentEl: HTMLElement, stack: Stack, combatSim: CombatSim, onChanged: (source: string)=>void)
{
	const { form, header } = createFormCard(parentEl, 'Stack', false, null);

	const copy = makeIconButton(header, 'Copy JSON', 'bi-journal', 'secondary', () =>
	{
		const json = JSON.stringify(stack.toSerializableObject(), null, 2);
		navigator.clipboard.writeText(json);
	});
	copy.classList.add('ms-2');

	const pasteJson = makeIconButton(header, 'Paste JSON', 'bi-journal-code', 'secondary', async () =>
	{
		stack.fromJson(await navigator.clipboard.readText());
	});

	const pastePlainText = makeIconButton(header, 'Paste list of short names', 'bi-journal-text', 'secondary', async () =>
	{
		stack.fromText({ text: await navigator.clipboard.readText(), hocr: null, intermediates: [] }, async (recognized: string, conflicts: RecordValue<Record<string, string>>[]) =>
		{
			return validator(recognized, conflicts, []);
		});
	});
/*
	const pasteImage = makeIconButton(header, 'Paste cropped screenshot of stack', 'bi-journal-richtext', 'secondary', async () =>
	{
		const items = await navigator.clipboard.read();
		const imageItem = items.find(i => i.types.includes('image/png'));

		if (imageItem === undefined)
			return;

		stack.fromImage(imageItem, async (recognized: string, conflicts: RecordValue<Record<string, string>>[], intermediates: HTMLCanvasElement[]) =>
		{
			return validator(recognized, conflicts, intermediates);
		});
	});*/

	const swap = makeIconButton(header, 'Swap', 'bi-arrow-left-right', 'secondary', () =>
	{
		const a = combatSim.attacker.stack.toSerializableObject();
		const d = combatSim.defender.stack.toSerializableObject();

		combatSim.attacker.stack.fromSerializableObject(d);
		combatSim.defender.stack.fromSerializableObject(a);

		onChanged('swap');
	});

	const clear = makeIconButton(header, 'Clear', 'bi-x', 'danger', () =>
	{
		stack.units.forEach(u =>
		{
			u.type.set('-1', 'UI');
			u.sect.type.set('Ort', 'UI');
			u.reset('UI', () => true);
		});
		onChanged('clear');
	});
	clear.classList.add('ms-2');

	const { form: units } = createFormCard(form, 'Units', false, null);

	const container = addContainer(units);

	const row = addRow(container);
	row.classList.add('row-cols-3');

	for (const unit of stack.units)
	{
		generateUIForUnit(row, unit, combatSim, onChanged);
	}
}

async function validator(recognized: string, conflicts: RecordValue<Record<string, string>>[], intermediates: HTMLCanvasElement[])
{
	const { div: modalDiv, footer, body, modal } = makeModal('Paste conflicts');

	return new Promise<boolean>((resolve, reject) =>
	{
		let resolved = false;
		modalDiv.addEventListener('hidden.bs.modal', () =>
		{
			if (!resolved)
				resolve(false);
		});

		modalDiv.addEventListener('shown.bs.modal', () =>
		{
			createButton(footer, 'Accept', 'bi-check', 'primary', () =>
			{
				resolved = true;
				resolve(true);
				modal.hide();
			});

			const { addItem } = makeAccordion(body, true, false, undefined);

			intermediates.forEach((canvas, i) =>
			{
				addItem((itemBody, itemHeader, collapse) =>
				{
					itemHeader.textContent = `Intermediate ${i}`;
					collapse.classList.remove('show');
					itemHeader.classList.add('collapsed');
					itemBody.appendChild(canvas);
					canvas.style.width = "100%";
					canvas.style.height = "auto";
				});
			});

			addItem((itemBody, itemHeader, collapse) =>
			{
				itemHeader.textContent = `Recognized text`;

				collapse.classList.remove('show');
				itemHeader.classList.add('collapsed');

				const { form } = createBasicCard(itemBody, () => { });
				form.innerHTML = recognized;
			});
			addItem((itemBody, itemHeader, collapse) =>
			{
				itemHeader.textContent = `Results`;
				const { form } = createBasicCard(itemBody, () => { });
				const container = addContainer(form);
				const row = addRow(container);
				row.classList.add('row-cols-5');
				for (const conflict of conflicts)
				{
					const { label } = createUIForRecordValue(addCol(row), conflict, () => { });
					if (Object.keys(conflict.values).length > 1)
					{
						label.classList.add('text-danger');
					}
				}
			});
		});

		modal.show();
	});
}
