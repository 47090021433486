import { Player } from "./Player";
import { ModData } from "./ModData";
import { Bonus } from "./Bonus";
import { SerializableUnit, Unit } from "./Unit";
import { Location, SerializableLocation } from "./Location";
import { AttackType, WeaponType } from "../data/target";
import { WeaponStats, UnitDataRow } from "../data/unit";
import { SnapshotMarkers, UnitStatus } from "./UnitStatus";
import { RecordValue } from "./Value";
import { TerrainType } from "../data/agility";
import { CombatSim } from "./CombatSim";

export type UnitsByWeaponAndRank = { [weapon: string]: { [rank: number]: Unit[] } };
export type UnitsByRank = { [rank: number]: Unit[] };

export type SerializableStack = {
	magic: string;
	units: { stack: string, stackPos: number, unit: SerializableUnit }[];
}

export type StackId = 'A' | 'D';

export const MaxUnitsInStack = 20;

export class Stack
{
	readonly version = 1;
	readonly magic: string = `stack-${this.context.modId}-${this.version}`;

	readonly maxUnits = MaxUnitsInStack;
	readonly units: Unit[] = [];
	//public relic: Relics = [];
	readonly chars: Set<string>;

	public activeUnits(): Unit[]
	{
		return this.units.filter(u => !u.isEmpty() && u.status.isActive());
	}

	constructor(readonly combatSim: CombatSim, readonly context: ModData, readonly player: Player, readonly id: StackId)
	{
		this.chars = new Set(this.context.unit.rows.reduce((prev, curr) => prev + curr.abbrev, ''));
		for (let i = 0; i < this.maxUnits; i++)
		{
			this.units.push(new Unit(this.combatSim, this.id, i, this.context, this.context.unitTypes));
		}
		this.units.forEach(u => u.initialize());
	}

	public initialize()
	{
		this.units.forEach(u => u.initialize());
	}

	public toSerializableObject(): SerializableStack
	{
		const out: SerializableStack = {
			magic: this.magic,
			units: this.units.filter(u => !u.isEmpty()).map(u =>
			{
				return { stack: u.stack, stackPos: u.stackPos, unit: u.toSerializableObject() }
			})
		};
		return out;
	}

	public fromJson(json: string)
	{
		const obj = JSON.parse(json);
		this.fromSerializableObject(obj);
	}

	// public async fromImage(item: ClipboardItem, validator: (recognized: string, conflicts: RecordValue<Record<string, string>>[], intermediates: HTMLCanvasElement[]) => Promise<boolean>)
	// {
	// 	return this.combatSim.ocr.getTextFromClipboard(item, [...this.chars.values()].join('')).then(data =>
	// 	{
	// 		this.fromText(data, validator);
	// 	});
	// }

	public fromText(data: {text: string, hocr: string|null, intermediates: HTMLCanvasElement[] }, validator: (recognized: string, conflicts: RecordValue<Record<string, string>>[], intermediates: HTMLCanvasElement[]) => Promise<boolean>): void
	{
		const conflicts: RecordValue<Record<string, string>>[] = []

		const matching: number[] = [];

		const recognized = data.text
			.split(/\s+/)
			.map(l => l.trim().toLowerCase())
			.filter(l => l.length > 1)
		;

		const recognized2: string[] = [];
		for (let i = 0; i < recognized.length - 1; i++)
		{
			const pair = recognized[i] + recognized[i + 1];
			recognized2.push(pair);
		}

		const units = this.context.unit.rows.map(r => r.abbrev.toLowerCase().replace(/\s+/g, ''));

		recognized.forEach((line, i) =>
			{
				const matchingUnits = this.context.unit.rows.filter(r => line.includes(r.abbrev.toLowerCase()));
				if (matchingUnits.length > 0)
				{
					matching.push(matchingUnits[0].id);
				}

				const index = matching.length - 1;

				if (matchingUnits.length > 0)
				{
					const values: Record<string, string> = {};

					matchingUnits.forEach(u =>
					{
						values[u.id.toString()] = u.name;
					});

					const conflict = new RecordValue(values, matchingUnits[0].id.toString(), matchingUnits[0].abbrev);
					conflicts.push(conflict);

					conflict.onChanged['fromText'] = (value) => matching[index] = parseInt(value);
				}
			}
		);

		if (matching.length === 0)
		{
		//	alert('No units found');
		//	return;
		}

		const setUnits = () =>
		{
			if (matching.length > 0)
			{
				for (const unit of this.units)
				{
					unit.type.set('-1', `fromSerializableObject-${this.id}`);
					unit.reset(`fromSerializableObject-${this.id}`, () => true);
				}

				for (let i = 0; i < matching.length && i < this.units.length; i++)
				{
					const unit = this.units[i];
					unit.type.set(matching[i].toString(), `fromSerializableObject-${this.id}`);
				}

				this.combatSim.onSomethingChangedFromUI('paste');
			}
		}

		const text = data.hocr ?? recognized.join("<br>");
		validator(text, conflicts, data.intermediates).then((result) =>
		{
			if (result)
				setUnits();
		});
	}

	public fromSerializableObject(obj: SerializableStack): boolean
	{
		if (obj.magic !== this.magic)
			throw new Error(`Invalid object type: ${obj.magic}`);

		for (const unit of this.units)
		{
			unit.type.set('-1', `fromSerializableObject-${this.id}`);
			unit.reset(`fromSerializableObject-${this.id}`, () => true);
		}
		for (const unit of obj.units)
		{
			const u = this.units.find(u => u.stackPos === unit.stackPos);
			if (u)
			{
				u.fromSerializableObject(unit.unit);
			}
		}

		return true;
	}

	public getBonuses(): Bonus[]
	{
		const out: Bonus[] = [];

		if (this.hasOfficer())
		{
			out.push(this.context.makeOfficerBonus(this));
		}
		if (this.hasNoble())
		{
			out.push(this.context.makeNobleBonus(this, this.player.traits));
		}

		out.push(...this.player.traits.getBonuses());

		this.units.forEach(u => out.push(...u.getStackBonuses()));

		return out;
	}

	public applyBonuses(bonuses: Bonus[]): void
	{
		this.units.forEach(u => u.applyBonuses(bonuses));
	}

	public hasNoble(): boolean
	{
		return this.units.some(u => u.isNoble());
	}

	public hasOfficer(): boolean
	{
		return this.units.some(u => u.isOfficer());
	}

	public reset(invokedBy: string, sourceFilter: (source: string) => boolean): void
	{
		this.units.forEach(u => u.reset(invokedBy, sourceFilter));
	}

	public getUnit(index: number): Unit | undefined
	{
		return this.units[index];
	}

	public getUnitCount(): number
	{
		return this.units.length;
	}

	public getUnitIndex(unit: Unit): number
	{
		return this.units.indexOf(unit);
	}

	public hasActiveCombatUnits(): boolean
	{
		return this.units.some(u => u.isCombatUnit() && u.status.isActive());
	}

	public saveStateSnapshot(marker: SnapshotMarkers): void
	{
		this.units.forEach(u => u.status.saveStateSnapshot(marker));
	}

	public isAirOrNavalOnly(): boolean
	{
		return this.units.filter(u => !u.isEmpty() && u.status.isActive()).every(u => u.data?.moveType === 'air' || u.data?.moveType === 'naval');
	}
}
