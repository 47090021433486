export class Archives
{
	private controller: bootstrap.Modal;
	constructor(private root: string, public modalId: string)
	{
		this.controller = new bootstrap.Modal(`#${this.modalId}`, { keyboard: true})
	}

	public displayChapter(volume: number, chapter: number): void
	{
		const modal = document.getElementById(this.modalId);
		if (!modal)
			throw new Error(`Failed to find modal with id ${this.modalId}`);

		const modalBody = modal.querySelector('.modal-body');
		if (!modalBody)
			throw new Error(`Failed to find modal body`);

		modalBody.innerHTML = `<p>Loading chapter ${volume}.${chapter}...</p>`;

		this.getChapter(volume, chapter).then((text) =>
		{
			modalBody.innerHTML = text;
		}
		).catch((err) =>
		{
			modalBody.innerHTML = `<p>Failed to load chapter ${volume}.${chapter}: ${err}</p>`;
		});

		this.controller.toggle();
	}

	public async getChapter(volume: number, chapter: number): Promise<string>
	{
		const response = await fetch(this.getChapterPath(volume, chapter));
		if (response.status !== 200)
			throw new Error(`Failed to fetch chapter ${volume}.${chapter}: ${response.statusText}`);

		return this.cleanupText(response.text());
	}

	private async cleanupText(text: Promise<string>): Promise<string>
	{
		return text.then((rawText) =>
		{
			let out = rawText.replace(/<<.*?>>\n*/g, '');
			return `<p>${out.split('\n').join('</p><p>')}</p>`;
		});
	}

	private getChapterPath(volume: number, chapter: number): string
	{
		const volumeStr = volume.toString();
		const chapterStr = chapter.toString().padStart(3, '0');

		return `${this.root}/MANOWITZ/V${volumeStr}CHP${chapterStr}.TXT`;
	}
}