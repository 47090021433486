import { TableUpdate, TechTracker } from "../app/TechTracker";
import { downloadJson } from "../app/Utils";
import { CityTable } from "../data/city";
import { TechTable } from "../data/tech";
import { UnitTable } from "../data/unit";

import { TableBase, TableStateStorage, bool, common, genericString, minMaxFilterEditor, minMaxFilterFunction, numerical } from "./tabulator";
import { Tabulator, ColumnDefinition } from "tabulator-tables";

export class UnitTableUI extends TableBase
{
	constructor(elementSelector: string, private techTracker: TechTracker, private units: UnitTable, private city: CityTable, private tech: TechTable, private showSubGraph: (id: string) => void, stateStorage: TableStateStorage)
	{
		super(elementSelector, "Units", stateStorage);
		this.init();

		this.techTracker.onChange.set("UnitTableUI", (update: TableUpdate) =>
		{
			if (!this.table)
				return;

			const unitUpdates = this.units.rows.map((row) =>
			{
				return {
					id: row.id,
					remainingCost: this.techTracker.getRemainingCostForList(row.techRequirements),
				};
			});

			this.table.updateData(unitUpdates);
			this.table.refreshFilter();
		});
	}

	protected override async downloadForLLM()
	{
		const columns = this.table?.getColumnDefinitions();
		const rows = await Promise.all(this.table!.getData().map(async (row) =>
		{
			const newRow: any = {};

			const setField = (column: ColumnDefinition, parentPath: string) =>
			{
				column.columns?.forEach((subColumn) =>
				{
					setField(subColumn, parentPath + column.title + " ");
				});
				if (!column.field || column.download === false)
					return;
				newRow[column.field] = row[column.field];
			};

			columns?.forEach((column) =>
			{
				setField(column, "");
			});

			return newRow;
		}));

		const data =
		{
			rows: rows,
			mod: this.tech.mod.name,
			schema: this.schema,
		};

		downloadJson(data, `unit.json`);
	}

	buildData()
	{
		return this.units.rows.map((row) =>
		{
			const inputUnit = this.units.getUnitByGroupAndTechLevel(row.buildNeeds.unit, row.buildNeeds.tLvl);
			const inputUnitName = inputUnit.length > 0 ? inputUnit[0].name : "None";
			let cityName = "None";
			if (row.buildNeeds.bldgs === 99)
				cityName = "Any";
			else if (row.buildNeeds.bldgs > 0)
			{
				const city = this.city.get(row.buildNeeds.bldgs);
				cityName = city ? city.name : "Unknown";
			}

			const techList = row.techRequirements
				.filter((techId) => techId !== 0)
				.map((tech) =>
				{
					const techRow = this.tech.get(tech);
					return techRow ? techRow.name : "Unknown";
				})
				.filter((tech) => tech !== "Unknown")
			;

			const totalBasicResources = this.units.getTotalBasicResources(row);

			return {
				id: row.id,
				graph_id: row.graph_id,
				opengraph: row.buildable ? `<button type="button" class="btn btn-info btn-sm">Graph</button>` : '',
				name: row.name,
				abbrev: row.abbrev,
				buildable: row.buildable,
				groupId: row.groupId,
				techLevel: row.techLevel,
				moveType: row.moveType,
				kind: row.kind,
				move: row.move,
				spot: row.spot,
				camo: row.camo,
				agility: row.ag,
				armor: row.armor,
				psyDef: row.psyDef,
				Water_Acc: row.combatStats.Water.Acc,
				Water_Str: row.combatStats.Water.Dmg,
				Indirect_Acc: row.combatStats.Indirect.Acc,
				Indirect_Str: row.combatStats.Indirect.Dmg,
				Air_Acc: row.combatStats.Air.Acc,
				Air_Str: row.combatStats.Air.Dmg,
				Direct_Acc: row.combatStats.Direct.Acc,
				Direct_Str: row.combatStats.Direct.Dmg,
				Close_Acc: row.combatStats.Close.Acc,
				Close_Str: row.combatStats.Close.Dmg,
				Psy_Acc: row.combatStats.Psy.Acc,
				Psy_Str: row.combatStats.Psy.Dmg,
				RangedSp_Acc: row.combatStats.RangedSp.Acc,
				RangedSp_Str: row.combatStats.RangedSp.Dmg,
				DirectSp_Acc: row.combatStats.DirectSp.Acc,
				DirectSp_Str: row.combatStats.DirectSp.Dmg,
				CloseSp_Acc: row.combatStats.CloseSp.Acc,
				CloseSp_Str: row.combatStats.CloseSp.Dmg,
				cargo: row.cargo,
				canBCargo: row.canBCargo === 0 ? false : true,
				unitFunction: row.unitFunction.join(", "),
				costs_CreditsPerTurn: row.costs.CreditsPerTurn,
				costs_Credits: row.costs.Credits,
				costs_resources_food: row.costs.resources.food,
				costs_resources_energy: row.costs.resources.energy,
				costs_resources_metal: row.costs.resources.metal,
				costs_resources_trace: row.costs.resources.trace,
				costs_resources_exotica: row.costs.resources.exotica,
				costs_resources_gems: row.costs.resources.gems,
				costs_resources_chemicals: row.costs.resources.chemicals,
				costs_resources_biochems: row.costs.resources.biochems,
				costs_resources_electronics: row.costs.resources.electronics,
				costs_resources_ceramsteel: row.costs.resources.ceramsteel,
				costs_resources_wetware: row.costs.resources.wetware,
				costs_resources_monopols: row.costs.resources.monopols,
				costs_resources_singularities: row.costs.resources.singularities,

				costs_basicResources_food: totalBasicResources.r100.food.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_food_100: totalBasicResources.r100.food.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_food_90: totalBasicResources.r90.food.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_food_80: totalBasicResources.r80.food.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_food_70: totalBasicResources.r70.food.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_food_60: totalBasicResources.r60.food.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_food_50: totalBasicResources.r50.food.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_food_40: totalBasicResources.r40.food.toFixed(2).replace(/(\.0+|0+)$/, ''),

				costs_basicResources_energy: totalBasicResources.r100.energy.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_energy_100: totalBasicResources.r100.energy.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_energy_90: totalBasicResources.r90.energy.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_energy_80: totalBasicResources.r80.energy.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_energy_70: totalBasicResources.r70.energy.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_energy_60: totalBasicResources.r60.energy.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_energy_50: totalBasicResources.r50.energy.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_energy_40: totalBasicResources.r40.energy.toFixed(2).replace(/(\.0+|0+)$/, ''),

				costs_basicResources_metal: totalBasicResources.r100.metal.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_metal_100: totalBasicResources.r100.metal.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_metal_90: totalBasicResources.r90.metal.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_metal_80: totalBasicResources.r80.metal.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_metal_70: totalBasicResources.r70.metal.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_metal_60: totalBasicResources.r60.metal.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_metal_50: totalBasicResources.r50.metal.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_metal_40: totalBasicResources.r40.metal.toFixed(2).replace(/(\.0+|0+)$/, ''),

				costs_basicResources_trace: totalBasicResources.r100.trace.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_trace_100: totalBasicResources.r100.trace.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_trace_90: totalBasicResources.r90.trace.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_trace_80: totalBasicResources.r80.trace.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_trace_70: totalBasicResources.r70.trace.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_trace_60: totalBasicResources.r60.trace.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_trace_50: totalBasicResources.r50.trace.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_trace_40: totalBasicResources.r40.trace.toFixed(2).replace(/(\.0+|0+)$/, ''),

				costs_basicResources_exotica: totalBasicResources.r100.exotica.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_exotica_100: totalBasicResources.r100.exotica.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_exotica_90: totalBasicResources.r90.exotica.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_exotica_80: totalBasicResources.r80.exotica.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_exotica_70: totalBasicResources.r70.exotica.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_exotica_60: totalBasicResources.r60.exotica.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_exotica_50: totalBasicResources.r50.exotica.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_exotica_40: totalBasicResources.r40.exotica.toFixed(2).replace(/(\.0+|0+)$/, ''),

				costs_basicResources_gems: totalBasicResources.r100.gems.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_gems_100: totalBasicResources.r100.gems.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_gems_90: totalBasicResources.r90.gems.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_gems_80: totalBasicResources.r80.gems.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_gems_70: totalBasicResources.r70.gems.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_gems_60: totalBasicResources.r60.gems.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_gems_50: totalBasicResources.r50.gems.toFixed(2).replace(/(\.0+|0+)$/, ''),
				costs_basicResources_gems_40: totalBasicResources.r40.gems.toFixed(2).replace(/(\.0+|0+)$/, ''),

				total_tech_cost: row.totalTechCost,
				total_building_tech_cost: row.totalBuildingsTechCost,
				buildNeeds_unit: inputUnitName,
				buildNeeds_tLvl: row.buildNeeds.tLvl,
				buildNeeds_bldgs: cityName,
				buildNeeds_owner: row.getOwnerUIString(),
				buildNeeds_owner_list: row.getIndividualOwners().join(", "),
				buildNeeds_turns2Bld: row.buildNeeds.turns2Bld,
				techRequirements: techList.join(", "),
				misc_tax: row.misc.tax === 0 ? false : true,
				misc_flock: row.misc.flock === 0 ? false : true,
				misc_range: row.misc.range,
				misc_eat: row.misc.eat === 0 ? false : true,
				misc_rank: row.misc.rank,
				misc_roP: row.misc.roP,
				misc_disband: row.misc.disband === 0 ? false : true,
				unitType: row.unitType.join(", "),
				raw: row.raw,
				remainingCost: this.techTracker.getRemainingCostForList(row.techRequirements),
			};
		});
	}

	buildColumns(): ColumnDefinition[]
	{
		return [
			{ title: 'Id', field: 'id', sorter: 'number', frozen: true, ...common },
			{ title: 'Name', field: 'name', sorter: 'string', frozen: true, ...common, ...genericString },
			{
				title: 'Graph',
				download: false,
				field: 'opengraph',
				formatter: "html",
				headerSort: true,
				headerFilter: false,
				frozen: true,
				cellClick: (e, cell) =>
				{
					if (cell.getRow().getData().buildable)
						this.showSubGraph(cell.getRow().getData().graph_id);
				}
			},
			{ title: 'Abbrev', field: 'abbrev', sorter: 'string', ...common, ...genericString },
			{
				title: 'Buildable',
				field: 'buildable',
				...common,
				...bool
			},
			{ title: 'Rank', field: 'misc_rank', ...numerical, ...common },
			{ title: 'Index', download: true, field: 'groupId', ...numerical, ...common, visible: false },
			{ title: 'Tech Level', download: true, field: 'techLevel', ...numerical, ...common, visible: false },
			{
				title: 'Move Type',
				field: 'moveType',
				sorter: 'string',
				headerVertical: true,
				headerFilter: "list",
				headerFilterParams: { valuesLookup:true, clearable: true },
				headerFilterPlaceholder: "All",
				...common,
			},
			{ title: 'Kind', download: true, field: 'kind', ...numerical, ...common, visible: false },
			{ title: 'Move Points', field: 'move', ...numerical, ...common },
			{ title: 'Spot', field: 'spot', ...numerical, ...common },
			{ title: 'Camo', field: 'camo', ...numerical, ...common },
			{ title: ' ', download: false, field: 'x', width: 10 },
			{
				title: "Combat Stats",
				formatter:"responsiveCollapse",
				columns: [
					{ title: 'Agility', field: 'agility', ...numerical, ...common },
					{ title: 'Armor', field: 'armor', ...numerical, ...common },
					{ title: 'Psy Def', field: 'psyDef', ...numerical, ...common },
					{
						title: 'Water',
						columns: [
							{ title: 'Acc', field: 'Water_Acc', ...numerical, ...common },
							{ title: 'Str', field: 'Water_Str', ...numerical, ...common },
						],
					},
					{
						title: 'Indirect',
						columns: [
							{ title: 'Acc', field: 'Indirect_Acc', ...numerical, ...common },
							{ title: 'Str', field: 'Indirect_Str', ...numerical, ...common },
						],
					},
					{
						title: 'Air',
						columns: [
							{ title: 'Acc', field: 'Air_Acc', ...numerical, ...common },
							{ title: 'Str', field: 'Air_Str', ...numerical, ...common },
						],
					},
					{
						title: 'Direct',
						columns: [
							{ title: 'Acc', field: 'Direct_Acc', ...numerical, ...common },
							{ title: 'Str', field: 'Direct_Str', ...numerical, ...common },
						],
					},
					{
						title: 'Close',
						columns: [
							{ title: 'Acc', field: 'Close_Acc', ...numerical, ...common },
							{ title: 'Str', field: 'Close_Str', ...numerical, ...common },
						],
					},
					{
						title: 'Psy',
						columns: [
							{ title: 'Acc', field: 'Psy_Acc', ...numerical, ...common },
							{ title: 'Str', field: 'Psy_Str', ...numerical, ...common },
						],
					},
					{
						title: 'RangedSpace',
						columns: [
							{ title: 'Acc', field: 'RangedSp_Acc', ...numerical, ...common },
							{ title: 'Str', field: 'RangedSp_Str', ...numerical, ...common },
						],
					},
					{
						title: 'DirectSpace',
						columns: [
							{ title: 'Acc', field: 'DirectSp_Acc', ...numerical, ...common },
							{ title: 'Str', field: 'DirectSp_Str', ...numerical, ...common },
						],
					},
					{
						title: 'CloseSpace',
						columns: [
							{ title: 'Acc', field: 'CloseSp_Acc', ...numerical, ...common },
							{ title: 'Str', field: 'CloseSp_Str', ...numerical, ...common },
						],
					},
				],
			},
			{ title: ' ', download: false, field: 'x', width: 10 },
			{ title: 'Cargo', field: 'cargo', ...numerical, ...common },
			{
				title: 'Portable',
				field: 'canBCargo',
				...bool,
				...common
			},
			{
				title: 'Unit Function',
				field: 'unitFunction',
				sorter: 'string',
				...common,
				...genericString,
			},
			{ title: ' ', download: false, field: 'x', maxWidth: 10 },
			{
				title: 'Costs',
				columns: [
					{ title: 'Credits Per Turn', field: 'costs_CreditsPerTurn', ...numerical, ...common },
					{ title: 'Credits', field: 'costs_Credits', ...numerical, ...common },
					{ title: 'Total Tech Cost', field: 'total_tech_cost', ...numerical, ...common },
					{
						title: 'Remaining Tech Cost',
						field: 'remainingCost',
						...common,
						...numerical,
					},
					{ title: 'Total Building Tech Cost', field: 'total_building_tech_cost', ...numerical, ...common },
					{
						title: 'Resources',
						columns: [
							{ title: "Food", field: "costs_resources_food", ...numerical, ...common },
							{ title: "Energy", field: "costs_resources_energy", ...numerical, ...common },
							{ title: "Metal", field: "costs_resources_metal", ...numerical, ...common },
							{ title: "Trace", field: "costs_resources_trace", ...numerical, ...common },
							{ title: "Exotica", field: "costs_resources_exotica", ...numerical, ...common },
							{ title: "Gems", field: "costs_resources_gems", ...numerical, ...common },
							{ title: "Chemicals", field: "costs_resources_chemicals", ...numerical, ...common },
							{ title: "Biochems", field: "costs_resources_biochems", ...numerical, ...common },
							{ title: "Electronics", field: "costs_resources_electronics", ...numerical, ...common },
							{ title: "Ceramsteel", field: "costs_resources_ceramsteel", ...numerical, ...common },
							{ title: "Wetware", field: "costs_resources_wetware", ...numerical, ...common },
							{ title: "Monopols", field: "costs_resources_monopols", ...numerical, ...common },
							{ title: "Singularities", field: "costs_resources_singularities", ...numerical, ...common },
						],
					},
					{
						title: 'Total Basic Resources',
						headerMenu: [
							{
								label: '100% loyalty', action: (e, columnGroup) =>
								{
									columnGroup.getSubColumns().forEach((column) =>
									{
										if (column.getField() === 'costs_basicResources_food')
										column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_food_100));
										else if (column.getField() === 'costs_basicResources_energy')
										column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_energy_100));
										else if (column.getField() === 'costs_basicResources_metal')
										column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_metal_100));
										else if (column.getField() === 'costs_basicResources_trace')
										column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_trace_100));
										else if (column.getField() === 'costs_basicResources_exotica')
										column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_exotica_100));
										else if (column.getField() === 'costs_basicResources_gems')
										column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_gems_100));
									});
						//			columnGroup.getElement().innerText = 'Total Basic Resources (100% loyalty)';
								}
							},
							{
								label: '90% loyalty', action: (e, columnGroup) =>
								{
									columnGroup.getSubColumns().forEach((column) =>
									{
										if (column.getField() === 'costs_basicResources_food')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_food_90));
										else if (column.getField() === 'costs_basicResources_energy')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_energy_90));
										else if (column.getField() === 'costs_basicResources_metal')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_metal_90));
										else if (column.getField() === 'costs_basicResources_trace')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_trace_90));
										else if (column.getField() === 'costs_basicResources_exotica')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_exotica_90));
										else if (column.getField() === 'costs_basicResources_gems')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_gems_90));
									});
							//		columnGroup.getElement().innerText = 'Total Basic Resources (90% loyalty)';
								}
							},
							{
								label: '80% loyalty', action: (e, columnGroup) =>
								{
									columnGroup.getSubColumns().forEach((column) =>
									{
										if (column.getField() === 'costs_basicResources_food')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_food_80));
										else if (column.getField() === 'costs_basicResources_energy')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_energy_80));
										else if (column.getField() === 'costs_basicResources_metal')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_metal_80));
										else if (column.getField() === 'costs_basicResources_trace')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_trace_80));
										else if (column.getField() === 'costs_basicResources_exotica')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_exotica_80));
										else if (column.getField() === 'costs_basicResources_gems')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_gems_80));
									});
								//	columnGroup.getElement().innerText = 'Total Basic Resources (80% loyalty)';
								}
							},
							{
								label: '70% loyalty', action: (e, columnGroup) =>
								{
									columnGroup.getSubColumns().forEach((column) =>
									{
										if (column.getField() === 'costs_basicResources_food')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_food_70));
										else if (column.getField() === 'costs_basicResources_energy')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_energy_70));
										else if (column.getField() === 'costs_basicResources_metal')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_metal_70));
										else if (column.getField() === 'costs_basicResources_trace')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_trace_70));
										else if (column.getField() === 'costs_basicResources_exotica')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_exotica_70));
										else if (column.getField() === 'costs_basicResources_gems')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_gems_70));
									});
							//		columnGroup.getElement().innerText = 'Total Basic Resources (70% loyalty)';
								}
							},
							{
								label: '60% loyalty', action: (e, columnGroup) =>
								{
									columnGroup.getSubColumns().forEach((column) =>
									{
										if (column.getField() === 'costs_basicResources_food')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_food_60));
										else if (column.getField() === 'costs_basicResources_energy')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_energy_60));
										else if (column.getField() === 'costs_basicResources_metal')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_metal_60));
										else if (column.getField() === 'costs_basicResources_trace')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_trace_60));
										else if (column.getField() === 'costs_basicResources_exotica')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_exotica_60));
										else if (column.getField() === 'costs_basicResources_gems')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_gems_60));
									});
							//		columnGroup.getElement().innerText = 'Total Basic Resources (60% loyalty)';
								}
							},
							{
								label: '50% loyalty', action: (e, columnGroup) =>
								{
									columnGroup.getSubColumns().forEach((column) =>
									{
										if (column.getField() === 'costs_basicResources_food')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_food_50));
										else if (column.getField() === 'costs_basicResources_energy')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_energy_50));
										else if (column.getField() === 'costs_basicResources_metal')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_metal_50));
										else if (column.getField() === 'costs_basicResources_trace')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_trace_50));
										else if (column.getField() === 'costs_basicResources_exotica')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_exotica_50));
										else if (column.getField() === 'costs_basicResources_gems')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_gems_50));
									});
							//		columnGroup.getElement().innerText = 'Total Basic Resources (50% loyalty)';
								}
							},
							{
								label: '40% loyalty', action: (e, columnGroup) =>
								{
									columnGroup.getSubColumns().forEach((column) =>
									{
										if (column.getField() === 'costs_basicResources_food')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_food_40));
										else if (column.getField() === 'costs_basicResources_energy')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_energy_40));
										else if (column.getField() === 'costs_basicResources_metal')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_metal_40));
										else if (column.getField() === 'costs_basicResources_trace')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_trace_40));
										else if (column.getField() === 'costs_basicResources_exotica')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_exotica_40));
										else if (column.getField() === 'costs_basicResources_gems')
											column.getCells().forEach((cell) =>	cell.setValue(cell.getRow().getData().costs_basicResources_gems_40));
									});
								//	columnGroup.getElement().innerText = 'Total Basic Resources (40% loyalty)';
								}
							},
						],
						columns: [
							{ title: "Food", field: "costs_basicResources_food", ...numerical, ...common },
							{ title: "Energy", field: "costs_basicResources_energy", ...numerical, ...common },
							{ title: "Metal", field: "costs_basicResources_metal", ...numerical, ...common },
							{ title: "Trace", field: "costs_basicResources_trace", ...numerical, ...common },
							{ title: "Exotica", field: "costs_basicResources_exotica", ...numerical, ...common },
							{ title: "Gems", field: "costs_basicResources_gems", ...numerical, ...common },
						],
					},
					{ title: 'Turns to Build', field: 'buildNeeds_turns2Bld', ...numerical, ...common },
				],
			},
			{ title: ' ', download: false, field: 'x', width: 10 },
			{
				title: 'Input Unit',
				field: 'buildNeeds_unit',
				sorter: 'string',
				headerVertical: true,
				headerFilter: "list",
				headerFilterParams: { valuesLookup:true, clearable: true },
				headerFilterPlaceholder: "All",
				...common,
			},
			{ title: 'Input Tech Level', download: true, field: 'buildNeeds_tLvl', ...numerical, ...common, visible: false },
			{
				title: 'Building',
				field: 'buildNeeds_bldgs',
				sorter: 'string',
				headerVertical: true,
				headerFilter: "list",
				headerFilterParams: { valuesLookup:true, clearable: true },
				headerFilterPlaceholder: "All",
				...common,
			},
			{
				title: 'Owner',
				columns: [
					{ title: 'Combined', download: true, field: 'buildNeeds_owner', sorter: 'string', ...common },
					{
						title: 'All',
						field: 'buildNeeds_owner_list',
						...common,
						...genericString,
					},
				],
			},
			{ title: 'Tech Requirements', field: 'techRequirements', sorter: 'string', ...common, ...genericString, },
			{ title: 'Tax', field: 'misc_tax', ...common, ...bool, },
			{ title: 'Flock', field: 'misc_flock', ...common, ...bool, },
			{ title: 'Range', field: 'misc_range', ...numerical, ...common },
			{
				title: 'Eat',
				field: 'misc_eat',
				...common,
				...bool,
			},
			{ title: 'ROP', field: 'misc_roP', ...numerical, ...common, visible: false },
			{ title: 'Disband', field: 'misc_disband', ...common, ...bool, },
			{ title: 'Unit Type', field: 'unitType', sorter: 'string', ...common },
			{ title: 'Raw Data', download: true, field: 'raw', sorter: 'string', ...common },
		];
	}
}
