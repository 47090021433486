import { Location } from "../sim/Location";
import { createFlagUI, createUIForRecordValue } from "./Value_ui";
import { createBasicCard, createFormCard } from "../app/Utils";
import { addCol, addRow } from "../app/Utils";

export function generateUIForLocation(parentEl: HTMLElement, location: Location, onChanged: (source: string)=>void)
{
	const { form, card } = createFormCard(parentEl, 'Location', false, null);

	card.classList.add('h-100');

	let row = addRow(form);
	row.classList.add('row-cols-1');

	createUIForRecordValue(addCol(row), location.planetType, onChanged);
	createUIForRecordValue(addCol(row), location.sect.type, onChanged);

	const { form: tiles } = createBasicCard(addCol(row), null);
	tiles.classList.add('container');
	let rowT = addRow(tiles);
	rowT.classList.add('row-cols-1');

	createUIForRecordValue(addCol(rowT), location.tile.baseTileType, onChanged);
	createUIForRecordValue(addCol(rowT), location.tile.reliefTileType, onChanged);
	createUIForRecordValue(addCol(rowT), location.tile.treesTileType, onChanged);
	createUIForRecordValue(addCol(rowT), location.tile.riversTileType, onChanged);
	createUIForRecordValue(addCol(rowT), location.tile.roadsTileType, onChanged);
	createUIForRecordValue(addCol(rowT), location.tile.cityType, onChanged);
}