// Columns:
//   water - can be placed on an ocean hex
//   land - can be placed on a land hex
//   road - 1 = builds road, 2 = builds road except on ocean
//   barren - can be placed on a barren hex
//   neutral - default owner is neutral
//   build - can be built by a player
//   area - resource collection area
//   Crd/Trn - city upkeep
//   Credits - cost to build city
//   Turns2Bld - construction phase # of turns (city health increases more slowly during the construction phase)
//   BuiltUnits - can the structure build units
//   Tech - tech required to build city
//   Value - asset value of city (used to compute player's worth)
// New columns:
//   Rate - the healing rate bonus / this structure on the planet
//   Limit - the healing rate maximum this structure can add to the planetary healing rate
//   Bonus - the healing rate bonus for units in this structure of the specific type
//   Agility - the agility of the structure
//   Armor - the armor of the structure
//   Unit Armor Factor (UAF) - the armor factor to apply to units defending in the structure
//   SW - Sect Weight, how much does this structure add to the sect value for that structure on the planet

import { DataPoint } from "../app/resourcemanager";

// water land road barren neutral build area Crd/Trn Credits Turns2Bld BuildUnits Tech Value Rate Limit Bonus Agility Armor UAF   SW
// 0     1    2     3       4        5    6     7      8        9         10        11   12   13   14    15      16   17    18    19

export type CityDataRow = {
	id: number;
	graph_id: string;
	raw: string;
	name: string;
	tech: number;
	water: boolean;
	land: boolean;
	road: boolean;
	barren: boolean;
	neutral: boolean;
	build: boolean;
	area: number;
	crd_trn: number;
	credits: number;
	turns_to_build: number;
	build_units: boolean;
	value: number;
	rate: number;
	limit: number;
	bonus: number;
	agility: number;
	armor: number;
	uaf: number;
	sw: number;
};

export class CityTable
{
	rows: CityDataRow[] = [];

	constructor(tableString: DataPoint)
	{
		const lines = tableString.data.trim().split('\n').filter(line => !line.startsWith('//'));

		lines.forEach(line =>
		{
			const row = this.parseRow(this.rows.length, line);
			if (row)
				this.rows.push(row);
		});
	}

	private parseRow(id: number, rowLine: string): CityDataRow | undefined
	{
		const rowMatches = rowLine.match(/"name"\s*"([^"]+)"\s*"stats"\s*"\s*([^"]+)\s*"/);
		if (!rowMatches)
			return undefined;

		const rowValues = rowMatches.map(value => value.trim());

		const statsValues = rowValues[2].split(/\s+/);

		return {
			id,
			graph_id: `city_${id}`,
			raw: rowLine,
			name: rowValues[1],
			tech: Number(statsValues[11]),
			water: statsValues[0] === '1',
			land: statsValues[1] === '1',
			road: statsValues[2] === '1',
			barren: statsValues[3] === '1',
			neutral: statsValues[4] === '1',
			build: statsValues[5] === '1',
			area: Number(statsValues[6]),
			crd_trn: Number(statsValues[7]),
			credits: Number(statsValues[8]),
			turns_to_build: Number(statsValues[9]),
			build_units: statsValues[10] === '1',
			value: Number(statsValues[12]),
			rate: Number(statsValues[13]),
			limit: Number(statsValues[14]),
			bonus: Number(statsValues[15]),
			agility: Number(statsValues[16]),
			armor: Number(statsValues[17]),
			uaf: Number(statsValues[18]),
			sw: Number(statsValues[19]),
		};
	}

	public get(id: number): CityDataRow | undefined
	{
		return this.rows[id];
	}

	public getByName(name: string): CityDataRow | undefined
	{
		return this.rows.find(row => row.name === name);
	}
};