import { TableStateStorage } from "../tables/tabulator";

export class PageState implements TableStateStorage
{
	constructor()
	{
		window.onpopstate = (e) => this.onPopState(e);

		const tab = this.getParam('tab');
		if (tab)
		{
			const tabElement = document.querySelector(`${tab}`);
			if (tabElement)
				(new bootstrap.Tab(tabElement)).show();
		}

		const mod = this.getParam('mod');
		if (mod)
		{
			const modSelect = document.getElementById('mods') as HTMLSelectElement;
			for (let i = 0; i < modSelect.options.length; i++)
			{
				if (modSelect.options[i].value === mod)
				{
					modSelect.selectedIndex = i;
					break;
				}
			}
		}

		document.querySelectorAll('.nav-link').forEach((e) => e.addEventListener('shown.bs.tab', (e) => this.onTabChanged(`#${(e.target as Element).id}`)));
		document.getElementById('mods')?.addEventListener('change', (e) => this.onModChanged((e.target as HTMLSelectElement).value));
	}

	private onPopState(e: PopStateEvent)
	{
		window.location.reload();
	}

	public setParam(key: string, value: string)
	{
		const params = new URLSearchParams(window.location.search);
		params.set(key, value);

		window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);
	}

	public getParam(key: string)
	{
		const params = new URLSearchParams(window.location.search);
		return params.get(key);
	}

	public onTabChanged(tab: string)
	{
		this.setParam('tab', tab);
	}

	public onModChanged(mod: string)
	{
		this.setParam('mod', mod);
	}

	public set(id: string, type: string, data: any): void
	{
		this.setParam(`${id}-${type}`, btoa(JSON.stringify(data)));
		localStorage.setItem(`${id}-${type}`, JSON.stringify(data));
	}

	public get(id: string, type: string): any
	{
		const b64 = this.getParam(`${id}-${type}`);
		if (b64 === null)
		{
			// get from local storage
			const ls = localStorage.getItem(`${id}-${type}`);
			if (ls)
				return JSON.parse(ls);
		}
		else
			return JSON.parse(atob(b64));
		return false;
	}
}
