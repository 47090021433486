type ContentGenerator<T> = () => {str: string, hydrated: T};
type ContentHydrator<T> = (content: string) => T;

const uuid = "3";

export class LocalStorageCache
{
	private localStorageKey: string;
	private uuidKey = 'uuid';
	private currentUUID: string;

	constructor(localStorageKey: string)
	{
		this.localStorageKey = localStorageKey;
		this.uuidKey = `${localStorageKey}-${this.uuidKey}`;
		this.currentUUID = uuid;

		const storedUUID = this.getFromLocalStorage(this.uuidKey);
		if (storedUUID !== this.currentUUID)
		{
			// Clear local storage when UUID changes
			for (let i = localStorage.length - 1; i >= 0; i--)
			{
				const key = localStorage.key(i);
				if (key?.startsWith(this.localStorageKey))
				{
					localStorage.removeItem(key);
				}
			}
		}
	}

	public get<T>(key: string, generateContent: ContentGenerator<T>, hydrateContent: ContentHydrator<T>): T
	{
		const completeLocalStorageKey = `${this.localStorageKey}-${key}`;
		const storedUUID = this.getFromLocalStorage(this.uuidKey);
		const storedContent = this.getFromLocalStorage(completeLocalStorageKey);

		// If the UUIDs match and content is present in local storage, retrieve from cache.
		if (this.currentUUID === storedUUID && storedContent)
		{
			return hydrateContent(storedContent);
		}

		// If the UUIDs don't match or content is not present, generate new content.
		const {str, hydrated} = generateContent();
		this.updateCache(completeLocalStorageKey, str);

		return hydrated;
	}

	private updateCache(key: string, newContent: string)
	{
		// Save the new content and UUID to the local storage.
		if (this.saveToLocalStorage(key, newContent))
			this.saveToLocalStorage(this.uuidKey, this.currentUUID);
	}

	private getFromLocalStorage(key: string): string | null
	{
		try
		{
			return localStorage.getItem(key);
		} catch (error)
		{
			console.error(`Error getting item from local storage: ${error}`);
			return null;
		}
	}

	private saveToLocalStorage(key: string, value: string)
	{
		try
		{
			localStorage.setItem(key, value);
		} catch (error)
		{
			console.error(`Error saving item to local storage: ${error}`);
			return false;
		}
		return true;
	}
}
