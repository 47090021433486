import { Bonus } from "./Bonus";
import { Flag, ReadonlyValue, Value } from "./Value";

export class SerializableTraits
{
	battlemaster: boolean = false;
	inept_military: boolean = false;
}

export class Traits
{
	public battlemaster: Flag = new Flag(false, { label: "Battlemaster" });
	readonly battlemaster_armor_bonus: ReadonlyValue = new ReadonlyValue(20, "Battlemaster Armor Bonus");

	public inept_military: Flag = new Flag(false, { label: "Inept Military Training" });
	readonly inept_military_accuracy_bonus: ReadonlyValue = new ReadonlyValue(-1, "Inept Military Accuracy Bonus");

	public symbiot: Flag = new Flag(false, { label: "Symbiot" });
	public vau: Flag = new Flag(false, { label: "Vau" });

	constructor()
	{
		this.symbiot.onChanged['vau'] = () => { if (this.symbiot.value) this.vau.set(false, 'UI', 'symbiot'); };
		this.vau.onChanged['symbiot'] = () => { if (this.vau.value) this.symbiot.set(false, 'UI', 'vau'); };
	}

	public initialize()
	{
		this.battlemaster.initialize(false, 'initialize', 'UI');
		this.inept_military.initialize(false, 'initialize', 'UI');
		this.symbiot.initialize(false, 'initialize', 'UI');
		this.vau.initialize(false, 'initialize', 'UI');
	}

	public getBonuses(): Bonus[]
	{
		const out: Bonus[] = [];

		if (this.inept_military.value)
		{
			out.push(
				new Bonus(
					"Inept Military Training",
					(status) => status,
					(stats, source) =>
					{
						Object.values(stats.attacks).forEach((attack) =>
						{
							if (attack.strength.value !== 0)
								attack.accuracy.applyBonus(source, this.inept_military_accuracy_bonus.value, 'getBonuses')
						});
						return stats;
					})
			);
		}

		return out;
	}

	public toSerializableObject(): SerializableTraits
	{
		const out: SerializableTraits = {
			battlemaster: this.battlemaster.initial,
			inept_military: this.inept_military.initial,
		};
		return out;
	}

	public fromSerializableObject(obj: SerializableTraits | undefined): void
	{
		this.battlemaster.initialize(obj?.battlemaster ?? false, 'fromSerializableObject', 'UI');
		this.inept_military.initialize(obj?.inept_military ?? false, 'fromSerializableObject', 'UI');
	}
}
